@if $manufactum {
  .root label a:hover,
  .root label a:active,
  .banner label a:hover,
  .banner label a:active {
    color: inherit;
  }
}

.root {
  margin-bottom: var(--space-m);
  padding-right: var(--space-m);
}

.contentRow.root {
  @media (--up_to_L) {
    margin: var(--space-l) var(--space-s);
  }

  @media (--L_and_up) {
    margin: var(--space-xxxl) calc(16.66667% + 1rem);
  }

  background-color: var(--grey-35);
  padding: 1.5rem 2rem 1rem 2rem;
}

.footer .flexBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.kurator .flexBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.footer .emailField {
  @media (--up_to_XL) {
    width: 100%;
  }

  @media (--XL_and_up) {
    width: 65%;
  }
}

.kurator .emailField {
  @media (--up_to_XL) {
    width: 100%;
  }

  @media (--XL_and_up) {
    width: 65%;
  }
}

.compact.button {
  margin-top: 3rem;
}

.footer .button {
  @media (--up_to_XL) {
    margin-top: var(--space-s);
  }

  @media (--XL_and_up) {
    width: 33%;
  }

  margin-bottom: var(--space-s);
}

.banner .button {
  margin-bottom: var(--space-s);
}

.kurator .button {
  @media (--up_to_XL) {
    margin-top: var(--space-s);
  }

  @media (--XL_and_up) {
    width: 33%;
  }

  margin-bottom: var(--space-s);
}

@if $manufactum {
  .footer small,
  .banner small {
    color: var(--color-copy-normal-inverted);
  }

  .kurator small {
    color: var(--black-100);
  }
}

.datenschutzRow.footer {
  @media (--XL_and_up) {
    order: 1;
  }
}

.awarenessRow {
  display: none;
}

@if $manufactum {
  .collapseExpand {
    color: var(--color-copy-light);
  }

  .footer .collapseExpand,
  .banner .collapseExpand {
    color: var(--color-copy-light-inverted);
  }

  .kurator .collapseExpand,
  .contentRow .collapseExpand {
    color: var(--black-100);
  }
}

@if $magazin {
  .footer .collapseExpand,
  .banner .collapseExpand {
    color: var(--color-copy-normal);
  }

  .kurator .collapseExpand,
  .contentRow .collapseExpand {
    color: var(--black-100);
  }
}

.page {
  /*! keep */
}

.footer {
  /*! keep */
}

.kurator {
  color: var(--black-100);
}

.collExpLabel {
  /*! keep */
}

.page .collExpLabel {
  max-height: calc(4 * var(--line-height-copy-normal));
}

.footer .collExpLabel,
.banner .collExpLabel,
.contentRow .collExpLabel {
  @media (--up_to_XL) {
    max-height: calc(2 * var(--line-height-copy-normal));
  }

  @media (--XL_and_up) {
    max-height: var(--line-height-copy-normal);
  }
}

.kurator .collExpLabel {
  @media (--up_to_XL) {
    max-height: calc(2 * var(--line-height-copy-normal));
  }

  @media (--XL_and_up) {
    max-height: var(--line-height-copy-normal);
  }
}

.contentRow .labelText {
  color: var(--black-100);
}
