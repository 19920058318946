.root {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  @media (--up_to_L) {
    flex-basis: 50%;
    max-width: 50%; /* IE 11 fix due to padding issue */
  }

  @media (--L_to_XXL) {
    flex-basis: 33%;
    max-width: 33%; /* IE 11 fix due to padding issue */
  }

  @media (--XXL_and_up) {
    flex-basis: 25%;
    max-width: 25%; /* IE 11 fix due to padding issue */
  }

  padding: 0 var(--grid-gutter);
}

.container.fullWidth {
  @media (--L_to_XL) {
    flex-basis: 33%;
  }

  @media (--XL_and_up) {
    flex-basis: 25%;
  }
}
