.root {
  @if $manufactum {
    margin: 0 var(--grid-outer-space);
  }

  @if $magazin {
    margin: 0 auto;
  }

  /* background-color: var(--white-100); */
  display: flex;
  justify-content: space-between;
  list-style: none;
  max-width: var(--app-width);
  padding: var(--space-l) calc(var(--grid-gutter) - 0.5rem) 5rem
    calc(var(--grid-gutter) - 0.5rem);
}

.icon {
  display: inline-block;
  fill: currentColor;
  height: 2.8rem;
  line-height: 2.8rem;
  margin: 0 0.5rem;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  width: 2.8rem;
}

.iconDone {
  background-color: var(--white-100);
  color: var(--main-100);
}

.iconFirst,
.iconLast {
  color: var(--main-100);
}

.iconLast.iconDisabled {
  color: silver;
}

.iconNumber {
  background-color: var(--main-100);
  border-radius: 50%;
  color: var(--white-100);
  fill: currentColor;
  font-family: var(--font-family-headline);
}

:global(.mf-fonts-critical) .iconNumber {
  font-family: var(--font-family-headline-critical);
}

:global(.mf-fonts-not-loaded) .iconNumber {
  font-family: var(--font-family-headline-not-loaded);
}

.iconNumber.iconDisabled {
  background-color: silver;
}

.li {
  flex-grow: 1;
  position: relative;
  text-align: right;
}

.li::before {
  border-top: thin solid var(--main-100);
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 1.8rem;
  width: 100%;
}

.li:first-child {
  margin-left: 0;
}

.li:last-child {
  margin-right: 0;
}

.liDisabled::before {
  border-top: thin solid silver;
}

.liFirst {
  flex-grow: 0;
}

.liWrap {
  background-color: var(--white-100);
  display: inline-block;
  position: relative;
  text-decoration: none;
}

.text {
  @media (--up_to_L) {
    left: -999rem;
    position: absolute;
  }

  @media (--L_and_up) {
    left: auto;
    margin-left: 0.2rem;
    margin-right: 0.5rem;
    position: relative;
    vertical-align: middle;
  }

  color: var(--main-100);
  display: inline-block;
  font-weight: normal;
}

.textActive {
  @media (--up_to_L) {
    left: -8.75em;
    text-align: center;
    top: 4.5rem;
    width: 20em;
  }

  @media (--L_and_up) {
    left: auto;
    text-align: left;
    top: auto;
    width: auto;
  }

  white-space: nowrap;
}

.textDisabled {
  color: silver;
}

.a11yHelper {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
}
