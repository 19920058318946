.root {
  @media (--up_to_L) {
    margin: 0 var(--space-s) var(--space-m);
  }

  @media (--L_and_up) {
    margin: 0 calc(16.66667% + 1rem) var(--space-m);
  }
}

@media (--L_and_up) {
  .root {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  .summary {
    margin-right: 1em;
  }
}

.addresses {
  background-color: var(--grey-50);
  min-width: 16em;
  padding: var(--space-m) var(--space-l);
}

.logo {
  margin-bottom: var(--space-l);
  width: 20rem;
}
