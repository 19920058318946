.root {
  @if $manufactum {
    margin: var(--grid-outer-space);
  }

  @if $magazin {
    @media (--up_to_XXXL) {
      margin: var(--space-l) var(--grid-outer-space);
    }

    @media (--XXXL_and_up) {
      margin: var(--space-l) auto;
    }
  }
}
