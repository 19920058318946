.container {
  @media (--up_to_XL) {
    height: 350px;
  }

  @media (--XL_to_XXL) {
    height: 500px;
  }

  @media (--XXL_and_up) {
    height: 700px;
  }

  text-align: center;
  width: 100%;
}

.container.clothing {
  @media (--up_to_M) {
    height: 60vh;
    max-height: 700px;
    padding: 0 var(--space-s);
  }
}

.pdp-gallery-main .image {
  @media (--up_to_XL) {
    height: 350px;
  }

  @media (--XL_to_XXL) {
    height: 500px;
  }

  @media (--XXL_and_up) {
    height: 700px;
  }
}

.pdp-gallery-main .image.clothing {
  @media (--up_to_M) {
    height: 60vh;
    max-height: 700px;
  }
}

.pdp-gallery-main {
  order: 1;
  position: relative;
}

.pdp-gallery-thumbnails {
  order: 2;
}

:global(.pdp-gallery-placeholder) {
  @media (--up_to_XL) {
    height: 350px;
  }

  @media (--XL_to_XXL) {
    height: 500px;
  }

  @media (--up_to_XXL) {
    align-items: center;
    display: flex;
  }

  position: absolute;
  text-align: center;
  width: 100%;
}

:global(.pdp-gallery-placeholder).clothing {
  @media (--up_to_M) {
    height: 60vh;
    max-height: 700px;
  }
}

:global(.pdp-gallery-placeholder) img {
  margin: 0 auto;
}

.previewImage {
  max-width: 100%;
}

.previewImage.clothing {
  @media (--up_to_M) {
    height: auto;
  }
}

.previewImage.thumbnails.single {
  @media (--up_to_XL) {
    display: none;
  }

  @media (--XL_to_XXL) and (orientation: portrait) {
    max-height: 325px;
  }

  @media (--XXL_to_XXXL) and (orientation: landscape) {
    max-height: 700px;
  }
}

.previewImage.thumbnails {
  @media (--up_to_XL) {
    max-height: 225px;
  }

  @media (--XL_to_XXL) {
    max-height: 325px;
  }

  @media (--XXL_and_up) {
    margin-left: 10.74rem;
    max-height: 700px;
  }
}

.previewImage.thumbnails.clothing {
  @media (--up_to_M) {
    max-height: 100%;
  }
}

.previewImage.withoutThumbnails {
  @media (--up_to_XL) {
    max-height: 350px;
  }

  @media (--XL_to_XXL) {
    max-height: 500px;
  }

  @media (--XXL_and_up) {
    margin-left: 10.74rem;
    max-height: 700px;
  }
}

.previewImage.withoutThumbnails.clothing {
  @media (--up_to_M) {
    max-height: 100%;
  }
}

:global(.Sirv.mainimage.true) {
  @media (--up_to_XL) {
    height: 350px;
  }

  @media (--XL_to_XXL) {
    height: 500px;
  }

  @media (--XXL_and_up) {
    height: 700px;
  }
}

:global(.Sirv.mainimage.clothing.true) {
  @media (--up_to_M) {
    height: 60vh;
    max-height: 700px;
  }
}

/* A/B Testing */

:global(.Sirv.mainimage.false) {
  @media (--up_to_XL) {
    height: 350px;
  }

  @media (--XL_to_XXL) {
    height: 450px;
  }

  @media (--XXL_and_up) {
    height: 700px;
  }
}

:global(.Sirv.mainimage.clothing.false) {
  @media (--up_to_M) {
    height: 60vh;
    max-height: 700px;
  }
}

:global(.Sirv.mainimage.false.single)
  :global(.smv-selectors-box.smv-thumbnails.smv-h) {
  display: none;
}

:global(.Sirv.mainimage.true) :global(.smv-selectors-box.smv-thumbnails) {
  @media (--up_to_XXL) {
    display: none;
  }
}

:global(.Sirv.mainimage.false.single)
  :global(.Sirv.mainimage.false.single)
  :global(.pdp-gallery-placeholder.single.single) {
  @media (--up_to_XL) {
    margin-top: 6rem;
  }

  @media (---M_to_L) {
    margin-top: 6rem;
  }

  @media (--L_to_XL) {
    margin-top: 0;
  }

  @media (--XL_and_up) {
    margin-top: 0;
  }
}

:global(.Sirv.mainimage.false)
  :global(.smv.smv-selectors-left)
  :global(.smv-slides-box.smv-h),
:global(.Sirv.mainimage.true)
  :global(.smv.smv-selectors-left)
  :global(.smv-slides-box.smv-h) {
  @media (--up_to_XXL) {
    position: static !important;
  }
}

:global(.Sirv.mainimage.true)
  :global(.smv.smv-mobile)
  :global(.smv-slides-box.smv-h) {
  @media (--up_to_XXL) {
    position: static !important;
  }
}

:global(.Sirv.mainimage.false.single)
  :global(.smv.smv-mobile)
  :global(.smv-slides-box.smv-h) {
  position: static !important;
}

:global(.Sirv.mainimage.false)
  :global(.smv.smv-mobile)
  :global(.smv-slides-box.smv-h) {
  position: relative !important;
}
