.root {
  @media (--up_to_L) {
    padding: 0.3rem 0;
  }

  @media (--L_and_up) {
    padding: 1rem 0;
  }

  @if $magazin {
    margin: 0 auto;
    max-width: var(--app-width);
  }

  background-color: var(--grey-50);
  display: block;
}

a.root.root.root:focus-visible {
  border-radius: 0;
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-3 * var(--focus-offset));
}

.list {
  @media (--up_to_L) {
    display: block;
    padding: 0 0 0 1.5rem;
    text-align: left;
  }

  @media (--L_and_up) {
    display: flex;
    padding: 0;
    text-align: center;
  }

  justify-content: space-between;
  list-style: none;
  margin: 0;
}

.list::before,
.list::after {
  content: "";
  display: block;
}

.element {
  color: var(--main-100);
  white-space: nowrap;
}

.element:hover {
  color: var(--main-75);
}

.element:hover .text {
  text-decoration: underline;
}

.spacer {
  @media (--up_to_L) {
    display: none;
  }

  flex-basis: 100%;
}

@media (--up_to_L) {
  .element {
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
  }
}

.text {
  display: inline-block;
  font-size: var(--font-size-copy-normal);
  line-height: 2.8rem;
  white-space: nowrap;
}

.iconContainer {
  display: inline-block;
  height: 2.8rem;
  margin-right: 1.5rem;
  vertical-align: top;
}
