.root {
  position: relative;
}

.link {
  color: var(--color-navigation-toplevel);
  cursor: pointer;
  display: block;
  font-size: var(--font-size-navigation-toplevel);
  font-weight: var(--font-weight-navigation-toplevel);
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.root:hover,
.active,
.open,
.compact .open + .arrow {
  color: var(--color-active-navigation-toplevel);
}

/* COMPACT */

.compact .linkWrapper {
  display: flex;
  position: relative;
}

.compact .link {
  border-bottom: 1px solid var(--color-divider-prio2);
  flex-grow: 1;
  line-height: 1.3em;
  padding: var(--space-s) var(--space-xl) var(--space-s) var(--space-m);
  position: relative;
}

.compact .link.open {
  border-bottom: 1px solid var(--color-divider-prio1);
}

.compact .child {
  margin: 0;
}

.compact .arrow {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--color-navigation-toplevel);
  display: block;
  margin: 0;
  padding: var(--space-m) var(--space-s);
  position: absolute;
  right: 0;
  top: 0;
}

.compact .arrow.down {
  animation: rotateToDown 0.3s;
  backface-visibility: hidden;
}

@keyframes rotateToDown {
  0% {
    transform: rotate(-90deg);
  }

  10% {
    transform: rotate(-90deg);
  }

  80% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.compact .link:focus-visible,
.compact .arrow:focus-visible {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * calc(0.1rem + var(--focus-width)));
}

/* FLYOUT */

.flyout .linkWrapper {
  align-items: center;
  display: flex;
}

.flyout .link {
  border: 1px solid transparent;
  border-bottom: none;
  line-height: 1em;
  padding: 1.7rem 1.3rem 1.8rem;
  touch-action: none;
}

.flyout .arrow {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  display: flex;
  height: 24px;
  margin-left: -22px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: -12px;
  width: 24px;
  z-index: 1;
}

.flyout .arrow.visible,
.flyout .arrow:focus-visible {
  opacity: 1;
}

.flyout .arrow:focus-visible {
  border-radius: 0.5rem;
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}

.flyout .arrow::after {
  border-bottom: 1px solid var(--black-100);
  border-right: 1px solid var(--black-100);
  content: "";
  height: 1.1rem;
  margin-bottom: 0.7rem;
  padding-left: 1rem;
  transform: rotate(45deg);
  width: 1.1rem;
}

.flyout .arrow.open::after {
  margin-top: 1.4rem;
  transform: rotate(225deg);
}

.flyout .child {
  border: 1px solid var(--grey-50);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
  display: block;
  position: absolute;
  top: 100%;

  &:empty {
    display: none;
  }
}

.flyout .left,
.flyout .midleft,
.flyout .midright {
  left: 1.3rem;
}

.flyout .right {
  right: 1.3rem;
}

.flyout .link::before {
  background-color: var(--main-100);
  bottom: 0;
  content: "";
  display: block;
  height: 0.3rem;
  left: 1.3rem;
  opacity: 0;
  position: absolute;
  right: 1.3rem;
  transform: scaleX(0.5) translateZ(0);
  transition:
    transform 0.25s,
    opacity 0.2s;
  transition-timing-function: ease-in-out;
}

.flyout .link:hover::before {
  opacity: 1;
  transform: scaleX(1);
}

.flyout .link.active::before {
  opacity: 1;
  transform: scaleX(1);
}
