.content {
  padding-left: 2rem;
}

.text {
  flex-grow: 1;
  line-height: 2;
  margin-right: 1rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon {
  text-align: right;
}
