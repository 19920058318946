.element {
  white-space: nowrap;
}

.element:hover {
  background-color: var(--grey-50);
}

.element:active {
  background-color: var(--grey-150);
}

.flagIconContainer {
  display: inline-block;
  height: 2.2rem;
  margin-right: 1.5rem;
  vertical-align: bottom;
  width: 2.6rem;
}

.countryLink {
  @if $manufactum {
    color: var(--color-copy-light);
  }
  @if $magazin {
    color: var(--color-copy);
  }
  cursor: pointer;
  display: block;
  font-size: var(--font-size-copy-light);
  font-weight: var(--font-weight-copy-light);
  line-height: var(--line-height-copy-light);
  padding: 1rem 3rem 1rem 1rem;
  text-decoration: none;
  user-select: none;
  width: 100%;
}

.element:active .countryLink {
  color: var(--white-100);
}

.element .countryLink:focus-visible {
  border-color: transparent;
  border-radius: 0;
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-3 * var(--focus-offset));
}
