.inlineItem {
  border-right: 1px solid var(--grey-75);
  display: inline-block;
  margin: 0 var(--space-s) 0 0;
  max-width: 100%;
  padding: 0 var(--space-s) 0 0;
  position: relative;
}

.inlineItem:last-child {
  border-right: none;
}
