.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.error {
  @if $manufactum {
    margin: var(--space-l) 0;
  }

  @if $magazin {
    margin: var(--space-l) auto;
    max-width: var(--app-width);
  }
}

.main {
  @if $magazin {
    margin: 0 auto;
    max-width: var(--app-width);
    width: 100%;
  }

  @media (--up_to_M) {
    min-height: calc(100vh - 40rem);
  }

  @media (--M_and_up) {
    min-height: calc(100vh - 50rem);
  }

  display: block;
  flex-grow: 1;
  position: relative;
}
