.root {
  align-items: stretch;
  width: 100%;
}

.root.stacked {
  display: flex;
  flex-direction: column;
}

:global(.mf-ie11) .root {
  display: block;
}

.image {
  display: block;
  margin-bottom: var(--space-l);
  padding: 0 var(--grid-gutter);
  width: 100%;
}

.root.side-by-side .image {
  @media (--M_and_up) {
    float: left;
    margin-right: 1rem;
    width: 50%;
  }
}

.root.side-by-side .paragraph:first-child {
  @media (--M_and_up) {
    margin-top: -0.7rem;
  }
}

.heading.heading:focus-within {
  border-color: transparent;
  border-radius: var(--focus-border-radius);
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
  text-decoration: none;
}

.heading.heading:focus-within a,
.heading.heading:hover:focus-within a {
  outline: 0;
}

.text {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 var(--grid-gutter);
}

.root.side-by-side .text {
  @media (--M_and_up) {
    margin-bottom: var(--space-l);
  }
}

.title {
  display: flex;
  flex-direction: column;
  padding: 0 var(--grid-gutter);
  position: relative;
  text-align: center;
}

.hyphens {
  hyphens: auto;
}

.clamp > * {
  @media (--S_to_L) {
    max-height: calc(var(--line-height-copy-normal) * 2);
    position: relative;
  }

  /* find solution for focus */
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (--S_to_L) {
  .clamp > *::after {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 60%
    );
    bottom: 0;
    content: "";
    height: var(--line-height-copy-normal);
    position: absolute;
    right: 0;
    text-align: right;
    top: var(--line-height-copy-normal);
    width: 40px;
  }
}

.button {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--focus-width) var(--grid-gutter);
}

.button.single {
  margin: 0 0 4rem 0;
}

.button.tripleTeaser,
.button.quadTeaser {
  @media (--up_to_M) {
    display: none;
  }
  @media (--M_and_up) {
    display: flex;
  }
}

.stacked .button {
  flex: 1;
}
