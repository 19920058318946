.root {
  @media (--up_to_M) {
    justify-content: space-between;
  }

  @media (--L_and_up) {
    justify-content: space-between;
  }

  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.root.fullWidth {
  @media (--M_to_L) {
    justify-content: flex-end;
  }

  /* TODO: SELL-2636 Remove outer spacing */
  margin: var(--space-m) 0;
}

.root.recommendedOnly {
  justify-content: flex-end;
}

/* Secondary */

.alternative {
  @media (--up_to_M) {
    width: 100%;
  }
}

.fullWidth .alternative {
  @media (--M_to_L) {
    padding-left: var(--space-xs);
    width: var(--grid-8-12);
  }

  @media (--L_to_XL) {
    order: 1;
    padding-right: var(--space-xs);
    width: var(--grid-4-12);
  }

  @media (--XL_and_up) {
    order: 1;
    padding-right: var(--space-xs);
    width: var(--grid-3-12);
  }
}

.narrow .alternative {
  @media (--M_and_up) {
    order: 1;
    padding-right: var(--grid-gutter);
    width: var(--grid-6-12);
  }
}

/* Primary */

.recommended {
  @media (--up_to_M) {
    margin-bottom: var(--space-l);
    width: 100%;
  }

  text-align: right;
}

.fullWidth .recommended {
  @media (--M_to_L) {
    margin-bottom: var(--space-l);
    padding-left: var(--space-xs);
    width: var(--grid-8-12);
  }

  @media (--L_to_XL) {
    order: 2;
    padding-left: var(--space-xs);
    width: var(--grid-6-12);
  }

  @media (--XL_to_XXL) {
    order: 2;
    padding-left: var(--space-xs);
    width: var(--grid-5-12);
  }

  @media (--XXL_and_up) {
    order: 2;
    width: var(--grid-4-12);
  }
}

.narrow .recommended {
  @media (--M_and_up) {
    order: 2;
    padding-left: var(--grid-gutter);
    width: var(--grid-6-12);
  }
}
