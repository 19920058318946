.column {
  @media (--up_to_M) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }

  @media (--M_and_up) {
    flex-basis: 50%;
    max-width: 50%;
    width: 50%;
  }

  margin-bottom: var(--space-xxxxl);
  padding: 0 var(--grid-gutter);
}
