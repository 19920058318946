.root {
  padding: 0 var(--grid-gutter);
}

.element {
  background-color: var(--grey-35);
  border-radius: 3px;
  display: inline-block;
  margin-bottom: var(--space-xl);
  max-width: 100%;
  opacity: 0.5;
  width: var(--app-width);
}

.element::after {
  content: "";
  display: block;
  min-height: 200px;
  padding-top: 25%;
}
