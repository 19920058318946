.root {
  @media (--up_to_XL) {
    padding: 0.5rem;
  }

  @media (--XL_and_up) {
    padding: 1rem;
  }

  background-color: white;
  color: var(--color-copy-small);
  display: block;
  font-size: var(--font-size-copy-small);
  line-height: var(--line-height-copy-small);
  list-style: none;
  margin: 0;
  width: 100%;
}

.footnote {
  display: flex;
  margin: 0 auto var(--space-m);
  max-width: 155rem;
}

.footnote span {
  display: flex;
}

.stars {
  text-align: right;
}
