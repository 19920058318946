.root {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.li {
  align-items: baseline;
  display: flex;
  justify-content: flex-start;
  margin: var(--space-s) 0 var(--space-s) 0;
}

.col {
  display: inline-block;
  min-width: 2.5rem;
}

.inline .li {
  display: inline-block;
  margin: -0.5rem var(--space-s) 0 0;
  padding: 0;
}

.inline .li:last-child {
  margin-right: 0;
}

.root label {
  display: inline-block;
  line-height: 1.5;
  white-space: normal;
}

.root input[type="radio"]:checked,
.root input[type="radio"]:not(:checked) {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.root input[type="radio"]:checked + label,
.root input[type="radio"]:not(:checked) + label {
  cursor: pointer;
  display: inline-block;
  line-height: 2rem;
  padding-left: 26px;
  position: relative;
}

.root input[type="radio"]:checked + label::before,
.root input[type="radio"]:not(:checked) + label::before {
  background: #ffffff;
  border: 1px solid var(--black-100);
  border-radius: 100%;
  content: "";
  height: 15px;
  left: 0;
  margin: 0.4rem 0 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.root input[type="radio"]:checked:focus-visible + label::before,
.root input[type="radio"]:not(:checked):focus-visible + label::before {
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(0.2rem + var(--focus-offset));
}

.root input[type="radio"]:checked + label::after,
.root input[type="radio"]:not(:checked) + label::after {
  background: var(--black-100);
  border-radius: 100%;
  content: "";
  height: 7px;
  left: 0;
  margin: 0.8rem 0 0 0.4rem;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 7px;
}

.root input[type="radio"]:not(:checked) + label::after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.root input[type="radio"]:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
