.root {
  display: block;
}

.large {
  height: 5.4rem;
  width: 5.4rem;
}

.small {
  height: 4.6rem;
  width: 4.6rem;
}

.medium {
  height: 5rem;
  width: 5rem;
}

.x-small {
  height: 4rem;
  width: 4rem;
}
