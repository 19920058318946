.text {
  margin-right: 1rem;
}

.button {
  background: none;
  border-radius: 0;
  display: flex;
  justify-content: center;
  padding: 0.3rem;
}

.button:focus-visible.button:focus-visible {
  background-color: var(--focus-background);
  border: none;
  box-shadow: none;
  color: var(--focus-color);
  outline: var(--focus-width) solid var(--focus-color);
}
