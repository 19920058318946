.root {
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  height: 4.2rem;
  margin: 0;
  padding: 0;
  width: 4.2rem;
}

.screenreaderOnly {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.large {
  background-size: 3.6rem;
}

.medium {
  background-size: 2.2rem;
}

.small {
  background-size: 1.8rem;
}

.tiny {
  background-size: 1.1rem;
}

.bold.value-1 {
  background-image: url("./img/bold/smiley_bold_1.svg");
}
.bold.value-1:after {
  content: url("./img/bold/smiley_bold_1_hover.svg");
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;
}
.bold.value-1:hover,
input:checked + .bold.value-1 {
  background-image: url("./img/bold/smiley_bold_1_hover.svg");
}

.bold.value-2 {
  background-image: url("./img/bold/smiley_bold_2.svg");
}
.bold.value-2:after {
  content: url("./img/bold/smiley_bold_2_hover.svg");
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;
}
.bold.value-2:hover,
input:checked + .bold.value-2 {
  background-image: url("./img/bold/smiley_bold_2_hover.svg");
}

.bold.value-3 {
  background-image: url("./img/bold/smiley_bold_3.svg");
}
.bold.value-3:after {
  content: url("./img/bold/smiley_bold_3_hover.svg");
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;
}
.bold.value-3:hover,
input:checked + .bold.value-3 {
  background-image: url("./img/bold/smiley_bold_3_hover.svg");
}

.bold.value-4 {
  background-image: url("./img/bold/smiley_bold_4.svg");
}
.bold.value-4:after {
  content: url("./img/bold/smiley_bold_4_hover.svg");
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;
}
.bold.value-4:hover,
input:checked + .bold.value-4 {
  background-image: url("./img/bold/smiley_bold_4_hover.svg");
}

.bold.value-5 {
  background-image: url("./img/bold/smiley_bold_5.svg");
}
.bold.value-5:after {
  content: url("./img/bold/smiley_bold_5_hover.svg");
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;
}
.bold.value-5:hover,
input:checked + .bold.value-5 {
  background-image: url("./img/bold/smiley_bold_5_hover.svg");
}

.light.value-1 {
  background-image: url("./img/light/smiley_light_1.svg");
}
.light.value-1:after {
  content: url("./img/light/smiley_light_1_hover.svg");
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;
}
.light.value-1:hover,
input:checked + .light.value-1 {
  background-image: url("./img/light/smiley_light_1_hover.svg");
}

.light.value-2 {
  background-image: url("./img/light/smiley_light_2.svg");
}
.light.value-2:after {
  content: url("./img/light/smiley_light_2_hover.svg");
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;
}
.light.value-2:hover,
input:checked + .light.value-2 {
  background-image: url("./img/light/smiley_light_2_hover.svg");
}

.light.value-3 {
  background-image: url("./img/light/smiley_light_3.svg");
}
.light.value-3:after {
  content: url("./img/light/smiley_light_3_hover.svg");
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;
}
.light.value-3:hover,
input:checked + .light.value-3 {
  background-image: url("./img/light/smiley_light_3_hover.svg");
}

.light.value-4 {
  background-image: url("./img/light/smiley_light_4.svg");
}
.light.value-4:after {
  content: url("./img/light/smiley_light_4_hover.svg");
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;
}
.light.value-4:hover,
input:checked + .light.value-4 {
  background-image: url("./img/light/smiley_light_4_hover.svg");
}

.light.value-5 {
  background-image: url("./img/light/smiley_light_5.svg");
}
.light.value-5:after {
  content: url("./img/light/smiley_light_5_hover.svg");
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;
}
.light.value-5:hover,
input:checked + .light.value-5 {
  background-image: url("./img/light/smiley_light_5_hover.svg");
}
