.root {
  display: flex;
  position: relative;
  width: 100%;
}

.root svg {
  max-height: 20rem;
  max-width: 20rem;
}
