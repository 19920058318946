.spacing {
  @media (--up_to_M) {
    margin-bottom: var(--space-xxl);
  }

  @media (--M_and_up) {
    margin-bottom: var(--space-xxxxl);
    margin-left: var(--grid-1-12);
    margin-right: var(--grid-1-12);
  }
}
