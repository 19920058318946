.root {
  margin-bottom: 2.5rem;
}

.label {
  display: block;
  margin-bottom: 1rem;
}

/* Stelar Text Overwrite */
.label.label {
  @if $manufactum {
    color: var(--color-copy-normal-inverted);
  }
}

.number {
  color: inherit;
  display: block;
  font-size: inherit;
  text-decoration: none;
}

/* Stelar Headline Overwrite */
.numberText.numberText.numberText {
  @if $manufactum {
    color: var(--color-headline-prio2-inverted);
  }

  @if $magazin {
    color: var(--color-copy-em);
    font-size: var(--font-size-copy-em);
    font-weight: var(--font-weight-copy-em);
    line-height: var(--line-height-copy-em);
  }
}

/* Stelar Text Overwrite */
.text.text.text {
  @if $manufactum {
    color: var(--color-copy-normal-inverted);
  }
}
