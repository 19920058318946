.name {
  display: block;
}

.name-level-2 {
  font-size: 1.5rem;
  font-weight: var(--font-weight-copy-semibold);
}

a.name {
  text-decoration: none;

  &:hover {
    color: var(--main-75);
  }

  &:active {
    color: var(--main-75);
  }
}

/* COMPACT */

.compact .linkWrapper {
  display: flex;
  position: relative;
}

.compact .linkWrapper > .name {
  border-bottom: 1px solid var(--color-divider-prio2);
  color: var(--black-100);
  flex-grow: 1;
  line-height: 1.47;
  padding: var(--space-s) var(--space-xl) var(--space-s) var(--focus-width);
  position: relative;
}

.compact.open > .linkWrapper > .name,
.compact.open > .linkWrapper > .arrow {
  @if $manufactum {
    color: var(--main-75);
  }

  @if $magazin {
    color: var(--grey-200);
    font-weight: bold;
  }
}

.compact.open.hasChildren > .linkWrapper > .name {
  border-bottom: 1px solid var(--color-divider-prio1);
}

.compact.hasChildren:not(.open):last-child > .linkWrapper > .name {
  border-bottom: none;
}

.compact .arrow {
  background-color: transparent;
  border: 1px solid transparent;
  display: block;
  margin: 0;
  padding: var(--space-m) var(--space-s);
  position: absolute;
  right: 0;
  top: 0;
}

.compact .arrow.down {
  animation: rotateToDown 0.3s;
  backface-visibility: hidden;
}

@keyframes rotateToDown {
  0% {
    transform: rotate(-90deg);
  }

  10% {
    transform: rotate(-90deg);
  }

  80% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.compact .linkWrapper > .arrow:focus-visible,
.compact .linkWrapper > .name:focus-visible {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}

/* FLYOUT */

@if $manufactum {
  .flyout.root {
    padding: 0 var(--grid-gutter);
  }
}

.flyout.normal {
  flex: 1;
}

.flyout.wide {
  flex: 2;
}

.flyout.wide .child {
  column-gap: var(--space-m);
  columns: 2;
}

.flyout .arrow {
  display: none;
}

.flyout .name {
  @if $manufactum {
    border-bottom: 1px solid var(--color-divider-prio1);
    color: var(--grey-200);
    margin-bottom: var(--space-xs);
    padding: var(--space-m) 0 var(--space-s);
  }

  @if $magazin {
    color: var(--grey-150);
    height: 4.9rem;
    padding-bottom: var(--space-s);
  }

  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@if $magazin {
  .flyout .name::before {
    content: "";
    display: inline-block;
    height: 3.9rem;
    margin-right: 1.5rem;
    vertical-align: middle;
    width: 3.9rem;
  }

  .image {
    display: inline-block;
    left: 2.8rem;
    margin-right: var(--space-m);
    position: absolute;
    width: 3.9rem;
  }

  .image,
  .flyout .name span {
    vertical-align: middle;
  }
}

.flyout a.name {
  &:active,
  &:hover {
    @if $manufactum {
      text-decoration: underline;
    }

    @if $magazin {
      color: var(--main-100);
    }
  }
}

.flyout.active {
  color: var(--main-75);
}

.flyout.active span {
  text-decoration: underline;
}
