.header {
  align-items: flex-start;
  background: var(--grey-35);
  border-top: thin solid silver;
  flex-wrap: wrap;
  height: 5rem;
  justify-content: space-between;
  padding: var(--space-s) 3.5rem 0 var(--space-s);
  position: relative;
  text-align: center;
}

.title {
  @if $magazin {
    text-transform: uppercase;
  }

  color: var(--color-headline-prio2);
  display: inline-block;
  font-family: var(--font-family-headline);
  font-size: var(--font-size-headline-prio2);
  line-height: var(--line-height-headline-prio2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.buttonX {
  position: absolute;
  right: var(--space-s);
  top: var(--space-s);
}
