.root {
  @media (--up_to_M) {
    height: 3.5rem;
    width: 3.5rem;
  }

  @media (--M_and_up) {
    height: 5.6rem;
    width: 5.6rem;
  }
}
