@media (--XXL_and_up) {
  .root {
    display: none;
  }
}

@media (--up_to_XXL) {
  .root {
    background-color: grey;
    bottom: 0;
    left: 0;
    opacity: 0.4;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
  }
}
