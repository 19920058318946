.root {
  margin-bottom: var(--space-xxxl);
}

.products {
  padding: 0 calc(var(--grid-gutter) - var(--focus-width) - var(--focus-offset));
  padding-bottom: calc(var(--space-xl) - 0.5rem);
}

.cta {
  padding-bottom: var(--space-xl);
  text-align: center;
}
