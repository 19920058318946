.iconLink {
  display: inline-block;
  transform: translateY(-2px);
}

.iconLink .iconContainer {
  display: inline-block;
  margin-right: 1.5rem;
  vertical-align: sub;
}

.text + .iconContainer {
  margin: 0 0 0 1.5rem;
}
