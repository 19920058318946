.root {
  animation: slideUp 0.5s;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 2500;
}

.root.hiddenByUser {
  animation: slideDown 0.35s;
  padding: 0;
  visibility: hidden;
}

@keyframes slideDown {
  0% {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  100% {
    opacity: 0.5;
    transform: translateY(200px);
    visibility: visible;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(200px);
  }

  100% {
    transform: translateY(0);
  }
}
