.root {
  max-width: 10em;
  width: auto;
}

.ch1 {
  clip-rule: evenodd;
  fill: #e52521;
  fill-rule: evenodd;
}

.ch2 {
  clip-rule: evenodd;
  fill: #ffffff;
  fill-rule: evenodd;
}
