.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
}

.inputs {
  border: 0.2rem solid transparent;
  border-radius: 4px;
  display: inline-flex;
  padding: var(--space-xs);
}

.inputs:focus-within {
  border: 2px solid var(--grey-60);
}

.label {
  display: block;
}

.input:focus-within + label {
  border-radius: var(--focus-border-radius);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-offset));
}

.screenreaderOnly,
.input,
.submit:not(:focus) {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.submit:focus {
  bottom: -3.8rem;
  display: block;
  margin-top: var(--space-s);
  position: absolute;
}
