/* Stelar Headline Overwrite Prio1 and Prio3 (Mag) */
.headline.headline {
  @if $manufactum {
    color: var(--color-headline-prio1-inverted);
    font-size: var(--font-size-headline-prio1);
    line-height: var(--line-height-headline-prio1);
    margin-bottom: var(--margin-headline-prio1);
  }

  @if $magazin {
    color: var(--color-headline-prio3);
    font-size: var(--font-size-headline-prio3);
    line-height: var(--line-height-headline-prio3);
    margin-bottom: 3rem;
    padding-top: 0.75rem;
    text-transform: uppercase;
  }

  @media (--XL_and_up) {
    @if $magazin {
      border-top: 1px solid var(--color-divider-prio2);
    }
  }

  font-family: var(--font-family-headline);
  margin-top: 0;
}

:global(.mf-fonts-not-loaded) .headline {
  font-family: var(--font-family-headline-not-loaded);
}

:global(.mf-fonts-critical) .headline {
  font-family: var(--font-family-headline-critical);
}

@if $magazin {
  :global(.mf-fonts-not-loaded) .headline,
  :global(.mf-fonts-critical) .headline {
    font-weight: var(--font-weight-copy-bold);
    transform: scaleX(0.85);
    transform-origin: left;
  }
}
