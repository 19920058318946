.root {
  display: block;
}

.small {
  height: 4rem;
  width: 4rem;
}

.large {
  height: 5rem;
  width: 5rem;
}

.x-large {
  height: 6rem;
  width: 6rem;
}

.xx-large {
  height: 7rem;
  width: 7rem;
}
