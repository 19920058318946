.root ins {
  text-decoration: none;
}

.strike {
  position: relative;
  white-space: nowrap;
}

.strike:after {
  border-top: 1px solid var(--color-copy-light);
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
}
