.root {
  display: block;
}

.large {
  height: 5rem;
  width: 5rem;
}

.small {
  height: 4rem;
  width: 4rem;
}

.teaser {
  height: 12rem;
  width: 12rem;
}
