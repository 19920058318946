@media (--XXL_and_up) {
  .root {
    display: none;
  }
}

@media (--up_to_XXL) {
  .root {
    backface-visibility: hidden;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(calc(var(--offcanvas-width-s-and-up) * -1 - 1px));
    transition-duration: 200ms;
    transition-property: transform;
    transition-timing-function: ease-out;
    width: var(--offcanvas-width-s-and-up);
    z-index: 100;
  }

  @media (--up_to_S) {
    .root {
      transform: translateX(calc(var(--offcanvas-width-up-to-s) * -1));
      width: var(--offcanvas-width-up-to-s);
    }
  }

  .active {
    box-shadow: 5px 0 8px 0 var(--grey-150);
    transform: translateX(0);
    transition-timing-function: ease-in;
  }

  .header {
    background-color: var(--main-100);
    display: flex;
    flex-direction: row;
  }

  .headerText {
    align-self: center;
    flex: 1;
    padding: var(--space-s) 0 var(--space-s) var(--space-m);
  }

  .headerClose {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
  }

  .headerCloseButton {
    color: var(--white-100);
    height: 2.8rem;
    width: 2.8rem;
  }

  .content {
    background-color: var(--white-100);
    flex: 3;
    overflow: hidden;
    position: relative;
  }
}

.countryFlagSize {
  height: 17px;
  margin: auto 0.5rem;
  vertical-align: sub;
}

.countryFlagButton {
  background-color: transparent;
  border: none;
  color: var(--white-100);
  display: flex;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.2rem;
}

.countrySelectionContainer {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
  height: 14rem;
  position: absolute;
  right: 2rem;
  top: 3rem;
  width: 25rem;
  z-index: 99;
}

.countrySelectionContainer.withTopBanner {
  top: 6rem;
}

.radioButtonContainer {
  margin-left: 3rem;
}

.title {
  @if $magazin {
    text-transform: uppercase;
  }

  color: var(--color-headline-prio2);
  display: inline-block;
  font-family: var(--font-family-headline);
  font-size: var(--font-size-headline-prio2);
  line-height: var(--line-height-headline-prio2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.buttonX {
  position: absolute;
  right: var(--space-s);
  top: var(--space-s);
}

.optionLinkContainer {
  margin: 1rem 0;
}

.optionLink {
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
}

.arrowIcon {
  margin: auto 0;
  padding-left: 1rem;
}
