.selectorWrapper {
  height: 3rem;
  position: relative;
}

.selector {
  border-color: var(--grey-75);
  cursor: pointer;
  display: block;
  left: -1rem;
  padding: 1px;
  position: absolute;
  top: -1rem;
}

.selector:hover,
.selectorOpen {
  padding: 0;
  z-index: 999;
}

.selector:hover {
  border: 1px solid var(--grey-75);
  box-shadow: 0.2rem 0.2rem 0.3rem 0 rgba(0, 0, 0, 0.1);
}

.selectorOpen,
.selectorOpen.selector:hover {
  border: 1px solid var(--white-100);
  box-shadow: 0.2rem 0.2rem 0.3rem 0 rgba(0, 0, 0, 0.2);
}

.selectorOptions {
  background-color: var(--white-100);
  display: none;
  list-style: none;
  margin: 0;
  overflow-y: hidden;
  padding: 0;
}

.selectorOpen .selectorOptions {
  display: block;
  overflow-y: visible;
}
