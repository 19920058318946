.button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.button.icon-right {
  flex-direction: row-reverse;
}

.button:focus-visible {
  outline: 0;
}

.button.small {
  @media (--XL_and_up) {
    display: block;
    pointer-events: none;
  }

  padding-bottom: 0;
}

.button.disabled,
.button.open.disabled {
  background-color: var(--grey-50);
  color: var(--grey-100);
}

.button.light.disabled,
.button.light.open.disabled {
  background-color: var(--white-100);
}

.icon {
  margin-right: var(--space-l);
  margin-top: var(--space-xs);
}

.icon-right .icon {
  min-width: 5rem;
}

.icon-right.light .icon {
  min-width: 2rem;
}

.icon-right .title {
  flex: 1;
}
