.feedbackContainer {
  border: 0;
  margin: var(--space-xl) auto 0;
  max-width: 800px;
  padding: 0;
}

.ratingContainer {
  display: flex;
  justify-content: center;
}

.hintContainer {
  display: flex;
  margin-top: var(--space-xs);
}

.hintText {
  max-width: calc(100% - 130px);
}

.counter {
  flex-shrink: 0;
  margin-left: auto;
}

.headline {
  margin-bottom: var(--space-s);
}
