.root {
  display: flex;
}

a.root {
  text-decoration: none;
}

.colImage {
  margin-right: var(--space-s);
  width: calc(100% * 4 / 12);
}
