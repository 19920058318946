.root {
  list-style: none;
  margin: 0;
  max-height: calc(100vh - 40rem);
  overflow: auto;
  padding: var(--space-s);
}

.root > li {
  border-bottom: thin solid silver;
  margin: 0;
  padding: var(--space-s) 0;
}

.root > li:last-child {
  border-bottom: 0 none;
}
