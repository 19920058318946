.root {
  @media (--up_to_XXXL) {
    box-shadow: 0 0 6px var(--grey-100);
  }
}

.top {
  @media (--up_to_L) {
    display: none;
  }
}

.main {
  @media (--up_to_L) {
    flex-wrap: wrap;
    padding: 0 0.5rem;
  }

  @media (--L_and_up) {
    padding: 0 var(--grid-outer-space);
  }

  @media (--L_to_XL) {
    @if $magazin {
      flex-wrap: nowrap;
    }

    @if $manufactum {
      flex-wrap: wrap;
    }
  }

  @media (--XL_to_XXL) {
    flex-wrap: wrap;
    margin-bottom: var(--space-m);
  }

  @media (--XXL_to_XXXL) {
    flex-wrap: wrap;
  }

  @media (--XXXL_and_up) {
    box-shadow: 0 7px 6px -7px var(--grey-100);
    flex-wrap: wrap;
  }

  @if $magazin {
    margin: 0 auto;
    max-width: var(--app-width);
  }

  display: flex;
}

.navigationIcon {
  @media (--L_to_XXL) {
    flex-basis: var(--grid-1-12);
  }

  @media (--XXL_and_up) {
    display: none;
  }

  @media (--up_to_XXL) {
    display: flex;
    position: relative;
    text-align: left;
  }

  margin: 0;
}

.navigationIcon a {
  @media (--up_to_L) {
    height: 5.5rem;
  }

  @media (--L_to_XXL) {
    height: 7rem;
  }

  align-items: center;
  background: none;
  border: none;
  color: var(--grey-150);
  cursor: pointer;
  display: flex;
  padding: 0 var(--grid-gutter);
}

.navigationIcon a:hover,
.navigationIcon a:active {
  color: var(--grey-200);
}

.logoContainer {
  @media (--up_to_M) {
    @if $manufactum {
      flex-basis: var(--grid-6-12);
    }

    @if $magazin {
      flex-basis: 20%;
    }

    flex: 1;
    min-width: 0;
    padding: 0 var(--space-xs);
  }

  @media (--M_to_L) {
    flex: 1;
    flex-basis: var(--grid-4-12);
    padding: 0 var(--space-xs);
  }

  @media (--L_to_XL) {
    @if $magazin {
      padding: 0 calc(1rem + var(--grid-gutter));
    }

    flex-basis: var(--grid-3-12);
  }

  @media (--XL_to_XXL) {
    @if $magazin {
      padding: 0 var(--grid-gutter) 1.6rem var(--grid-gutter);
    }

    @if $manufactum {
      padding: 0 var(--grid-gutter);
    }

    flex-basis: var(--grid-3-12);
  }

  @media (--XXL_and_up) {
    @if $magazin {
      height: 7.2rem;
      margin-top: 1rem;
      padding: 0 calc(1rem + var(--grid-gutter));
    }

    flex-basis: var(--grid-3-12);
    padding: 0 var(--grid-gutter);
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logoInner {
  @if $manufactum {
    @media (--up_to_M) {
      max-width: 16.5rem;
    }

    @media (--M_to_L) {
      max-width: 16.5rem;
    }

    @media (--L_to_XL) {
      max-width: 20rem;
      padding-bottom: 0.8rem;
    }

    @media (--XL_to_XXL) {
      max-width: 20rem;
      padding-bottom: 1rem;
    }

    @media (--XXL_and_up) {
      max-width: 24rem;
      padding-bottom: 2.5rem;
    }
  }

  @if $magazin {
    @media (--up_to_M) {
      max-width: 16.5rem;
    }

    @media (--M_to_L) {
      margin-left: 1.5rem;
      max-width: 16.5rem;
    }

    @media (--L_to_XL) {
      max-width: 20rem;
    }

    @media (--XL_to_XXL) {
      max-width: 20rem;
    }

    @media (--XXL_and_up) {
      margin: 0 auto;
      max-width: 24rem;
    }
  }

  margin-bottom: -2rem;
  margin-top: -2rem;
  width: 100%;
}

.logoInnerCompact {
  @if $manufactum {
    @media (--up_to_M) {
      max-width: 4rem;
    }

    @media (--M_to_L) {
      max-width: 16.5rem;
    }

    @media (--L_to_XL) {
      max-width: 20rem;
      padding-bottom: 0.8rem;
    }

    @media (--XL_to_XXL) {
      max-width: 20rem;
      padding-bottom: 1rem;
    }

    @media (--XXL_and_up) {
      max-width: 24rem;
      padding-bottom: 2.5rem;
    }
  }

  @if $magazin {
    @media (--up_to_M) {
      max-width: 3.6rem;
    }

    @media (--M_to_L) {
      margin-left: 1.5rem;
      max-width: 16.5rem;
    }

    @media (--L_to_XL) {
      max-width: 20rem;
    }

    @media (--XL_to_XXL) {
      max-width: 20rem;
    }

    @media (--XXL_and_up) {
      margin: 0 auto;
      max-width: 24rem;
    }
  }

  width: 100%;
}

.logoLink {
  display: block;
  padding: 2rem 0;
}

.searchContainer {
  @media (--up_to_M) {
    @if $magazin {
      padding: 0.6rem calc(var(--space-xs) + var(--grid-outer-space)) 0.6rem;
    }

    @if $manufactum {
      padding: 0.6rem var(--space-m) 0.6rem;
    }

    background-color: var(--grey-35);
    display: flex;
    flex-basis: calc(100% + 2 * var(--grid-outer-space));
    margin: 0 calc(-1 * var(--grid-outer-space));
  }

  @media (--M_to_L) {
    background-color: var(--grey-35);
    display: flex;
    flex-basis: calc(100% + 2 * var(--grid-outer-space));
    margin: 0 calc(-1 * var(--grid-outer-space));
    padding: 0.6rem calc(var(--space-xs) + var(--grid-gutter)) 0.6rem;
  }

  @media (--L_and_up) {
    display: block;
    flex: 1;
    padding: 1.2rem var(--grid-gutter);
  }

  @media (--L_to_XL) {
    @if $manufactum {
      padding-left: 4%;
    }

    @if $magazin {
      flex-basis: var(--grid-4-12);
    }
  }

  @media (--XL_and_up) {
    @if $magazin {
      margin-bottom: 1.6rem;
      padding-right: calc(var(--grid-1-12));
    }
  }

  @media (--XXL_and_up) {
    @if $manufactum {
      margin-bottom: 1.5rem;
    }

    @if $magazin {
      margin: 1rem 0;
    }
  }

  min-width: 0;
}

.searchContainer.desktop {
  @media (--up_to_L) {
    display: none;
  }
}

.searchContainer.mobile {
  @media (--L_and_up) {
    display: none;
  }
}

.searchBox {
  flex: 1;
  min-width: 0;
}

.myAccount,
.miniNotepad,
.miniCart {
  @if $manufactum {
    @media (--XXL_and_up) {
      margin-top: 1.25rem;
    }
  }

  @media (--up_to_L) {
    padding: 0 !important;
  }

  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 var(--grid-gutter);
  text-overflow: ellipsis;
  white-space: nowrap;

  & > * {
    @media (--XL_and_up) {
      @if $magazin {
        height: 6rem;
        margin-top: 1.2rem;
      }
    }

    @media (--XXL_and_up) {
      @if $magazin {
        height: 8rem;
        margin-top: 2.2rem;
      }
    }

    width: 100%;
  }
}

.miniNotepad {
  @media (--up_to_L) {
    flex-basis: 4.5rem;
    justify-content: flex-end;
  }

  @media (--L_and_up) {
    flex-basis: var(--grid-1-12);
  }

  @media (--XL_to_XXL) {
    @if $magazin {
      padding-bottom: 1.6rem;
    }
  }
}

.myAccount {
  @if $magazin {
    @media (--XL_to_XXL) {
      padding-bottom: 1.6rem;
    }
  }

  @media (--L_and_up) {
    flex-basis: var(--grid-1-12);
  }
}

.miniCart {
  @media (--up_to_L) {
    flex-basis: 4.5rem;
    justify-content: flex-end;
  }

  @media (--L_and_up) {
    flex-basis: var(--grid-1-12);
  }

  @media (--XL_to_XXL) {
    @if $magazin {
      padding-bottom: 1.6rem;
    }
  }
}

.navigation {
  @media (--up_to_XXL) {
    display: none;
  }

  @media (--XXL_to_XXXL) {
    border-top: 1px solid var(--grey-75);
    display: block;
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(var(--grid-12-12) + 2rem);
  }

  @media (--XXXL_and_up) {
    border-top: 1px solid var(--grey-75);
    display: block;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    width: calc(var(--grid-12-12) + 1rem);
  }

  padding: 0 var(--grid-outer-space);
}

:global(.mf-info-grid) .root::before {
  background: linear-gradient(
    to right,
    #dddddd 0,
    #dddddd 10px,
    #eeeeee 10px,
    #eeeeee calc(100% / 12 * 1 - 10px),
    #cccccc calc(100% / 12 * 1 - 10px),
    #cccccc calc(100% / 12 * 1),
    #dddddd calc(100% / 12 * 1),
    #dddddd calc(100% / 12 * 1 + 10px),
    #eeeeee calc(100% / 12 * 1 + 10px),
    #eeeeee calc(100% / 12 * 2 - 10px),
    #cccccc calc(100% / 12 * 2 - 10px),
    #cccccc calc(100% / 12 * 2),
    #dddddd calc(100% / 12 * 2),
    #dddddd calc(100% / 12 * 2 + 10px),
    #eeeeee calc(100% / 12 * 2 + 10px),
    #eeeeee calc(100% / 12 * 3 - 10px),
    #cccccc calc(100% / 12 * 3 - 10px),
    #cccccc calc(100% / 12 * 3),
    #dddddd calc(100% / 12 * 3),
    #dddddd calc(100% / 12 * 3 + 10px),
    #eeeeee calc(100% / 12 * 3 + 10px),
    #eeeeee calc(100% / 12 * 4 - 10px),
    #cccccc calc(100% / 12 * 4 - 10px),
    #cccccc calc(100% / 12 * 4),
    #dddddd calc(100% / 12 * 4),
    #dddddd calc(100% / 12 * 4 + 10px),
    #eeeeee calc(100% / 12 * 4 + 10px),
    #eeeeee calc(100% / 12 * 5 - 10px),
    #cccccc calc(100% / 12 * 5 - 10px),
    #cccccc calc(100% / 12 * 5),
    #dddddd calc(100% / 12 * 5),
    #dddddd calc(100% / 12 * 5 + 10px),
    #eeeeee calc(100% / 12 * 5 + 10px),
    #eeeeee calc(100% / 12 * 6 - 10px),
    #cccccc calc(100% / 12 * 6 - 10px),
    #cccccc calc(100% / 12 * 6),
    #dddddd calc(100% / 12 * 6),
    #dddddd calc(100% / 12 * 6 + 10px),
    #eeeeee calc(100% / 12 * 6 + 10px),
    #eeeeee calc(100% / 12 * 7 - 10px),
    #cccccc calc(100% / 12 * 7 - 10px),
    #cccccc calc(100% / 12 * 7),
    #dddddd calc(100% / 12 * 7),
    #dddddd calc(100% / 12 * 7 + 10px),
    #eeeeee calc(100% / 12 * 7 + 10px),
    #eeeeee calc(100% / 12 * 8 - 10px),
    #cccccc calc(100% / 12 * 8 - 10px),
    #cccccc calc(100% / 12 * 8),
    #dddddd calc(100% / 12 * 8),
    #dddddd calc(100% / 12 * 8 + 10px),
    #eeeeee calc(100% / 12 * 8 + 10px),
    #eeeeee calc(100% / 12 * 9 - 10px),
    #cccccc calc(100% / 12 * 9 - 10px),
    #cccccc calc(100% / 12 * 9),
    #dddddd calc(100% / 12 * 9),
    #dddddd calc(100% / 12 * 9 + 10px),
    #eeeeee calc(100% / 12 * 9 + 10px),
    #eeeeee calc(100% / 12 * 10 - 10px),
    #cccccc calc(100% / 12 * 10 - 10px),
    #cccccc calc(100% / 12 * 10),
    #dddddd calc(100% / 12 * 10),
    #dddddd calc(100% / 12 * 10 + 10px),
    #eeeeee calc(100% / 12 * 10 + 10px),
    #eeeeee calc(100% / 12 * 11 - 10px),
    #cccccc calc(100% / 12 * 11 - 10px),
    #cccccc calc(100% / 12 * 11),
    #dddddd calc(100% / 12 * 11),
    #dddddd calc(100% / 12 * 11 + 10px),
    #eeeeee calc(100% / 12 * 11 + 10px),
    #eeeeee calc(100% - 10px),
    #cccccc calc(100% - 10px),
    #cccccc calc(100% - 10px)
  );
  content: "";
  display: block;
  height: 100%;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(var(--app-width) - (2 * var(--grid-outer-space)));
  opacity: 0.4;
  pointer-events: none;
  position: absolute;
  right: 0;
  width: calc(100% - 2rem);
  z-index: 1000;
}
