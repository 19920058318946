.column {
  @media (--M_to_XL) {
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }

  @media (--XL_and_up) {
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
    width: calc(100% / 3);
  }

  margin-bottom: calc(var(--space-xxxxl) - 0.7rem);
  width: 100%;
}

.image {
  margin-bottom: var(--space-m);
}

.imageSection,
.textSection {
  @media (--M_to_XL) {
    width: 50%;
  }

  padding: 0 var(--grid-gutter);
}

.heading {
  @media (--up_to_M) {
    margin-top: var(--space-xl);
  }

  @media (--XL_and_up) {
    margin-top: var(--space-xl);
  }
}
