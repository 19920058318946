/* repetition to increase specificity against copy text definition of `.AppRoot_root *` */
.root.root {
  @if $magazin {
    text-transform: uppercase;
  }

  margin-top: 0;
}

.root.root a {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: none;
}

@if $magazin {
  :global(.mf-fonts-not-loaded) .root,
  :global(.mf-fonts-critical) .root {
    font-weight: var(--font-weight-copy-bold);
    transform: scaleX(0.85);
    transform-origin: left;
  }
}

.block {
  display: block;
}

.inline {
  display: inline-block;
}

.prio0.prio0 {
  color: var(--color-headline-prio0);
  font-family: var(--font-family-headline);
  font-size: var(--font-size-headline-prio0);
  line-height: var(--line-height-headline-prio0);
}

.prio0.block {
  margin-bottom: var(--margin-headline-prio0);
}

.prio0.inverted {
  color: var(--color-headline-prio0-inverted);
}

.prio0.highContrast {
  color: var(--color-headline-prio0-highcontrast);
}

.prio1.prio1 {
  color: var(--color-headline-prio1);
  font-family: var(--font-family-headline);
  font-size: var(--font-size-headline-prio1);
  line-height: var(--line-height-headline-prio1);
}

.prio1.block {
  margin-bottom: var(--margin-headline-prio1);
}

.prio1.inverted {
  color: var(--color-headline-prio1-inverted);
}

.prio1.highContrast {
  color: var(--color-headline-prio1-highcontrast);
}

.prio2.prio2 {
  color: var(--color-headline-prio2);
  font-family: var(--font-family-headline);
  font-size: var(--font-size-headline-prio2);
  line-height: var(--line-height-headline-prio2);
}

.prio2.highContrast {
  color: var(--color-headline-prio2-highcontrast);
}

.prio2.block {
  @if $magazin {
    border-bottom: 1px solid var(--grey-50);
    padding-bottom: var(--space-s);
  }

  margin-bottom: var(--margin-headline-prio2);
}

.prio2.inverted {
  color: var(--color-headline-prio2-inverted);
}

:global(.mf-fonts-not-loaded) .prio0,
:global(.mf-fonts-not-loaded) .prio1,
:global(.mf-fonts-not-loaded) .prio2 {
  font-family: var(--font-family-headline-not-loaded);
}

:global(.mf-fonts-not-loaded) .prio3,
:global(.mf-fonts-not-loaded) .prio4 {
  @if $manufactum {
    font-family: var(--font-family-copy-not-loaded);
  }

  @if $magazin {
    font-family: var(--font-family-headline-not-loaded);
  }
}

:global(.mf-fonts-critical) .prio0,
:global(.mf-fonts-critical) .prio1,
:global(.mf-fonts-critical) .prio2 {
  font-family: var(--font-family-headline-critical);
}

:global(.mf-fonts-critical) .prio3,
:global(.mf-fonts-critical) .prio4 {
  @if $manufactum {
    font-family: var(--font-family-copy-critical);
  }

  @if $magazin {
    font-family: var(--font-family-headline-critical);
  }
}

/* stylelint-disable-next-line */
.prio3.prio3 {
  @if $manufactum {
    font-weight: var(--font-weight-copy-semibold);
  }

  @if $magazin {
    font-weight: var(--font-weight-copy-bold);
  }

  color: var(--color-headline-prio3);
  font-size: var(--font-size-headline-prio3);
  line-height: var(--line-height-headline-prio3);
}

.prio3.block {
  margin-bottom: var(--margin-headline-prio3);
}

.prio3.highContrast {
  color: var(--color-headline-prio3-highcontrast);
}

/* stylelint-disable-next-line */
.prio4.prio4 {
  @if $manufactum {
    font-weight: var(--font-weight-copy-semibold);
  }

  @if $magazin {
    font-weight: var(--font-weight-copy-bold);
  }

  color: var(--color-headline-prio4);
  font-size: var(--font-size-headline-prio4);
  line-height: var(--line-height-headline-prio4);
}

.prio4.block {
  margin-bottom: var(--margin-headline-prio4);
}

.prio4.highContrast {
  color: var(--color-headline-prio4-highcontrast);
}

.skeleton {
  background-color: var(--grey-100);
  color: transparent;
  display: inline-block;
  font-family: inherit;
  max-width: 100%;
  white-space: nowrap;
}

.prio0 .skeleton {
  height: var(--font-size-headline-prio0);
}

.prio1 .skeleton {
  height: var(--font-size-headline-prio1);
}

.prio2 .skeleton {
  height: var(--font-size-headline-prio2);
}

.prio3 .skeleton {
  height: var(--font-size-headline-prio3);
}

.prio4 .skeleton {
  height: var(--font-size-headline-prio4);
}
