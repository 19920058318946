.root {
  display: flex;
  margin-bottom: var(--space-l);
  padding: 0.8rem var(--space-m);
}

.visuallyHidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.iconContainer {
  margin-right: var(--space-m);
}

.messageText {
  font-size: var(--font-size-copy-normal);
  line-height: var(--line-height-copy-normal);
}

.title,
.text,
.link {
  display: inline-block;
}

.title {
  font-weight: var(--font-weight-copy-semibold);
  margin-right: var(--space-xs);
  vertical-align: top;
}

.text {
  margin-right: var(--space-s);
  vertical-align: top;
}

.link {
  color: inherit;
}

.root.attention {
  background-color: var(--attention-50);
  color: var(--attention-100);
}

.root.error {
  background-color: var(--error-50);
  color: var(--error-100);
}

.root.success {
  background-color: var(--success-50);
  color: var(--success-100);
}

.root.hint {
  background-color: var(--hint-50);
  color: var(--hint-100);
}

.root.plain {
  background-color: transparent;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
