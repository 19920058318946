.root {
  color: var(--color-copy-normal);
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-normal);
  margin: 0;
  min-width: 200px;
  overflow: hidden;
}

.bullets {
  @if $manufactum {
    padding: 0 0 0 1.6rem;
  }

  @if $magazin {
    padding: 0 0 0 1.8rem;
  }

  list-style: none;
}

.bullets li::before {
  @if $manufactum {
    font-size: 2.3rem;
    left: -1.6rem;
    top: -0.2rem;
  }

  @if $magazin {
    font-size: 1.5rem;
    left: -1.8rem;
    top: 0;
  }

  content: "•";
  display: block;
  position: absolute;
  width: 1.6rem;
}

.ordered {
  @if $manufactum {
    padding: 0 0 0 3.1rem;
  }

  @if $magazin {
    padding: 0 0 0 2.4rem;
  }

  counter-reset: item;
  list-style: none;
}

.ordered li {
  counter-increment: item;
}

.ordered li::before {
  @if $manufactum {
    left: -3.1rem;
  }

  @if $magazin {
    left: -2.4rem;
  }

  content: counter(item) ".";
  display: block;
  margin-right: 1.3rem;
  position: absolute;
  text-align: center;
  width: 1.2rem;
}
