.content {
  padding: var(--space-s) 0 var(--space-xl) 0;
}

.buttons > :nth-child(2) {
  margin-top: var(--space-s);
}

.blockBenefits {
  margin-bottom: var(--space-l);
  padding: var(--space-m);
}

.blockRequirements {
  background-color: var(--grey-35);
  margin-bottom: var(--space-l);
  padding: var(--space-m);
}

.headlineOverride {
  text-decoration: underline;
}

.center {
  text-align: center;
}
