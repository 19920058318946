.root {
  display: block; /* eliminiert Unterlängen, die bei display: flex/align-items: center stören. */
}

.root path,
.root polygon,
.root rect {
  fill: currentColor; /* entspricht color: inherit */
}

.large {
  height: 2.8rem;
  width: 2.8rem;
}

.medium {
  height: 2.2rem;
  width: 2.2rem;
}

.small {
  height: 1.8rem;
  width: 1.8rem;
}

.tiny {
  height: 1.1rem;
  width: 1.1rem;
}
