.iconElement {
  display: flex;
  height: 2.2rem;
  margin-bottom: 1.3rem;
}

.iconContainer {
  display: inline-block;
  margin-right: 1.5rem;
  vertical-align: middle;
  white-space: nowrap;
}

/* Stelar Text Overwrite */
.text.text.text {
  @if $manufactum {
    color: var(--color-copy-normal-inverted);
    display: inline-block;
    min-width: 12rem;
  }

  @if $magazin {
    display: inline-block;
    min-width: 10rem;
  }
}
