.text {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_to_L) {
    width: calc(100% / 3);
  }

  @media (--L_to_XL) {
    width: calc(100% / 3);
  }

  @media (--XL_and_up) {
    width: calc(100% / 3);
  }

  margin: -0.7rem 0 calc(var(--space-xxl) - 0.7rem);
  padding: 0 var(--grid-gutter);
  vertical-align: top;
}
