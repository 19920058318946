.root {
  /* button inside AppRoot */
  @media (--up_to_XXXL) {
    right: 0;
  }

  /* button inside AppRoot because it doesn't fit in whitespace */
  @media (min-width: 1500px) and (max-width: 1849px) {
    right: calc((100vw - var(--app-width)) / 2);
  }

  /* button in whitespace right of AppRoot */
  @media (min-width: 1850px) {
    left: calc(var(--app-width) + (100vw - var(--app-width)) / 2);
    right: 0;
  }

  @media (--up_to_L) {
    bottom: 38px;
  }

  @media (--L_and_up) {
    bottom: 58px;
  }

  padding: 0 calc(var(--grid-outer-space) + var(--grid-gutter));
  position: fixed;
  transition: opacity 0.25s ease-in 0.1s;
  z-index: 96;
}

:global([data-advisor-hidden="false"]) ~ .root {
  @media (--up_to_M) {
    bottom: 38px;
    right: 90px;
  }

  @media (--M_and_up) {
    bottom: 58px;
    left: initial;
    right: 110px;
  }
}

.show {
  opacity: 1;
  pointer-events: auto;
}

.hide {
  opacity: 0;
  pointer-events: none;
}
