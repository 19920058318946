.selection {
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  margin: var(--space-xl) 0 0;
  padding: 0;
}

.selection li {
  list-style-type: none;
}

.selection li a,
.selection li button {
  align-items: center;
  background-color: var(--grey-35);
  border: 1px solid var(--grey-50);
  color: var(--color-copy);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-copy);
  font-weight: var(--font-weight-copy);
  line-height: var(--line-height-copy);
  padding: var(--space-m);
  text-decoration: none;
  user-select: none;
  width: 100%;
}

.selection li a:hover,
.selection li button:hover {
  background-color: var(--grey-50);
  border: 1px solid var(--grey-75);
}

.selection li a:focus-visible,
.selection li button:focus-visible {
  border-color: var(--white-100);
  border-radius: var(--focus-border-radius);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
}

.flagContainer {
  margin-right: var(--space-m);
  vertical-align: bottom;
  width: 4.6rem;
}

.additional {
  margin-top: var(--space-xl);
}
