:global(.revokeDataStorage) {
  @if $manufactum {
    background-color: #005232 !important;
    border: 0;
    border-radius: 2.4rem;
    font-weight: var(--font-weight-copy-strong);
  }

  @if $magazin {
    background-color: #333333 !important;
    border: 1px solid #ffffff;
    border-radius: 0.5rem;
    font-weight: var(--font-weight-copy-em);
    text-transform: uppercase;
  }

  @media (max-width: 277px) {
    line-height: unset !important;
    width: calc(100% * 1);
  }

  @media (min-width: 278px) {
    width: calc(100% * 1);
  }

  @media (min-width: 490px) {
    width: calc(100% * 0.6666666667);
  }

  @media (min-width: 982px) {
    width: calc(100% * 0.5);
  }

  @media (min-width: 1250px) {
    width: calc(100% * 0.3333333);
  }

  color: #ffffff !important;
  cursor: pointer;
  display: inline-block;
  font-size: var(--font-size-copy-normal);
  line-height: 3.6rem;
  max-width: 100%;
  min-height: 3.8rem;
  overflow: hidden;
  padding: 0 var(--space-xxl);
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: normal;
}

:global(.revokeDataStorage):hover {
  background-color: var(--main-75) !important;
}

.root,
.root p {
  color: var(--color-copy-normal);
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-normal);
  line-height: var(--line-height-copy-normal);
}

.root [data-sell-fragment] {
  line-height: initial;
}

.root p {
  margin: 0 0 var(--space-l);
}

.root a {
  @if $magazin {
    line-height: var(--line-height-copy-em);
  }

  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
}

.root a:hover {
  color: var(--main-100);
  text-decoration: underline;
}

/* TODO: Kickout wenn Fragmente nicht mehr im Content liegen */
.root [data-sell-fragment] a[data-test-sell-product-link]:hover {
  text-decoration: initial;
}

.root [data-sell-fragment] a[data-test-sell-product-link]:focus {
  text-decoration: underline;
}

.root a:active {
  color: var(--main-75);
}

.root strong,
.root b {
  @if $manufactum {
    font-weight: var(--font-weight-copy-semibold);
  }

  @if $magazin {
    font-weight: var(--font-weight-copy-bold);
  }
}

@if $magazin {
  .root h2,
  .root h3,
  .root h4 {
    text-transform: uppercase;
  }

  :global(.mf-fonts-not-loaded) h2,
  :global(.mf-fonts-critical) h2,
  :global(.mf-fonts-not-loaded) h3,
  :global(.mf-fonts-critical) h3,
  :global(.mf-fonts-not-loaded) h4,
  :global(.mf-fonts-critical) h4 {
    font-weight: var(--font-weight-copy-bold);
    transform: scaleX(0.85);
    transform-origin: left;
  }
}

.root h2 {
  @if $magazin {
    border-bottom: 1px solid var(--grey-50);
    padding-bottom: var(--space-s);
  }

  color: var(--color-headline-prio2);
  display: block !important;
  font-family: var(--font-family-headline);
  font-size: var(--font-size-headline-prio2);
  line-height: var(--line-height-headline-prio2);
  margin-bottom: var(--margin-headline-prio2);
}

:global(.mf-fonts-not-loaded) .root h2 {
  font-family: var(--font-family-headline-not-loaded);
}

@if $magazin {
  :global(.mf-fonts-not-loaded) .root h3,
  :global(.mf-fonts-not-loaded) .root h4 {
    font-family: var(--font-family-headline-not-loaded);
  }
}

:global(.mf-fonts-critical) .root h2 {
  font-family: var(--font-family-headline-critical);
}

@if $magazin {
  :global(.mf-fonts-critical) .root h3,
  :global(.mf-fonts-critical) .root h4 {
    font-family: var(--font-family-headline-critical);
  }
}

.root h3 {
  @if $manufactum {
    font-weight: var(--font-weight-copy-semibold);
  }

  @if $magazin {
    font-weight: var(--font-weight-copy-bold);
  }

  color: var(--color-headline-prio3);
  display: block !important;
  font-size: var(--font-size-headline-prio3);
  line-height: var(--line-height-headline-prio3);
  margin-bottom: var(--margin-headline-prio3);
}

.root h4 {
  @if $manufactum {
    font-weight: var(--font-weight-copy-semibold);
  }

  @if $magazin {
    font-weight: var(--font-weight-copy-bold);
  }

  color: var(--color-headline-prio4);
  display: block !important;
  font-size: var(--font-size-headline-prio4);
  line-height: var(--line-height-headline-prio4);
  margin-bottom: var(--margin-headline-prio4);
}

.root ul,
.root ol {
  color: var(--color-copy-normal);
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-normal);
  list-style: none;
  margin: 0 0 var(--space-l);
  min-width: 200px;
  padding: 0;
}

.root li {
  line-height: var(--line-height-copy-normal);
  position: relative;
}

.root ul li {
  @if $manufactum {
    padding: 0 0 0 1.6rem;
  }

  @if $magazin {
    padding: 0 0 0 1.8rem;
  }

  overflow: hidden;
}

.root ul li::before {
  @if $manufactum {
    font-size: 2.3rem;
    top: -0.2rem;
  }

  @if $magazin {
    font-size: 1.5rem;
    top: 0;
  }

  content: "•";
  display: block;
  left: 0;
  position: absolute;
  width: 1.6rem;
}

.root ol {
  counter-reset: item;
}

.root ol li {
  @if $manufactum {
    padding: 0 0 0 3.1rem;
  }

  @if $magazin {
    padding: 0 0 0 2.4rem;
  }

  counter-increment: item;
  overflow: hidden;
}

.root ol li::before {
  content: counter(item) ".";
  display: block;
  left: 0;
  margin-right: 1.3rem;
  overflow-wrap: normal;
  position: absolute;
  text-align: center;
  width: 1.2rem;
}

/* Exceptional Content Classes */

.root :global(.om) {
  background-image: url("./magazin-m.svg");
  background-repeat: no-repeat;
  display: inline-block;
  height: 1.28571429em;
  text-indent: -999em;
  vertical-align: text-bottom;
  width: 1.28571429em;
}

@if $manufactum {
  .root :global(.homepage-exception) {
    padding: 0 var(--space-xl);
    text-align: center;
  }
}

@if $magazin {
  .root {
    @media (--XXL_and_up) {
      height: 100%;
    }
  }

  .root :global(.homepage-exception) {
    @media (--up_to_L) {
      padding: 0 var(--grid-1-12);
    }

    @media (--L_to_XXL) {
      padding: 0 var(--grid-2-12);
    }

    @media (--XXL_and_up) {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 0 var(--grid-2-12);
    }
  }
}

@if $manufactum {
  .root :global(.homepage-exception .internalhref) {
    display: block;
    margin: var(--space-l) 0 0;
    text-align: center;
  }
}

@if $magazin {
  .root :global(.homepage-exception .internalhref) {
    @media (--up_to_XXL) {
      margin: var(--space-xxl) 0 var(--space-xl);
    }

    @media (--XXL_and_up) {
      margin: var(--space-xxl) 0 0;
    }

    display: block;
    text-align: right;
  }
}

.root :global(.homepage-exception .internalhref:first-child) {
  @if $manufactum {
    margin: var(--space-m) 0 0;
  }

  @if $magazin {
    margin: var(--space-xxl) 0 0;
  }
}

/* stylelint-disable-next-line */
.root :global(.homepage-exception a),
.root a[role="button"] {
  @if $manufactum {
    border-radius: 2.4rem;
    font-weight: var(--font-weight-copy-strong);
  }

  @if $magazin {
    border-radius: 0.5rem;
    font-weight: var(--font-weight-copy-em);
    text-transform: uppercase;
  }

  background-color: var(--white-100);
  border: 1px solid var(--grey-75);
  color: var(--darkGrey-105);
  cursor: pointer;
  display: inline-block;
  font-size: var(--font-size-copy-normal);
  height: 3.8rem;
  line-height: 3.6rem;
  max-width: 100%;
  overflow: hidden;
  padding: 0 var(--space-xxl);
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: normal;
}

.root :global(.homepage-exception a),
.root a[role="button"]:focus-visible {
  @if $manufactum {
    border-radius: 2.4rem;
  }

  @if $magazin {
    border-radius: 0.5rem;
  }
}

@if $magazin {
  :global(.mf-fonts-not-loaded) .root :global(.homepage-exception a),
  :global(.mf-fonts-not-loaded) .root a[role="button"],
  :global(.mf-fonts-critical) .root :global(.homepage-exception a),
  :global(.mf-fonts-critical) .root a[role="button"] {
    font-weight: var(--font-weight-copy-bold);
  }
}

@if $magazin {
  .root :global(.homepage-exception a) {
    @media (--up_to_M) {
      width: 100%;
    }

    @media (--M_to_L) {
      width: calc(60% - var(--grid-gutter));
    }

    @media (--L_to_XL) {
      width: 100%;
    }

    @media (--XL_to_XXL) {
      width: calc(75% - var(--grid-gutter));
    }

    @media (--XXL_and_up) {
      width: calc(50% - var(--grid-gutter));
    }
  }
}

/* stylelint-disable */
.root a[role="button"]:hover,
.root :global(.homepage-exception a:active),
.root :global(.homepage-exception a:hover),
.root a[role="button"]:active {
  background-color: var(--grey-75);
}
/* stylelint-enable */
