.root {
  margin-bottom: calc(var(--space-xxxl) + var(--space-l));
  margin-top: -0.7rem;
}

.root::after {
  clear: both;
  content: "";
  display: block;
}

.image {
  @media (--M_and_up) {
    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);
    width: 50%;
  }

  margin-bottom: var(--space-s);
  padding-top: 0.7rem;
}

.image.left {
  @media (--M_and_up) {
    float: left;
    margin-right: var(--grid-gutter);
  }
}

.image.right {
  @media (--M_and_up) {
    float: right;
    margin-left: var(--grid-gutter);
  }
}

.text {
  @media (--M_and_up) {
    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);
  }

  margin-bottom: calc(var(--space-s));
}
