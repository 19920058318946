.icon {
  margin-right: 1rem;
}

.nextIcon {
  text-align: right;
}

.container {
  align-items: center;
  display: flex;
}

.flexGrow {
  flex-grow: 1;
}
