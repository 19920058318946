/* square wrap-element to force the browser to reserve space before the image is loaded */
.wrap {
  color: transparent;
  display: block;
  margin-bottom: var(--space-s);
  overflow: hidden;
  position: relative;
  width: 100%;
}

:global(.mf-ie11) .wrap {
  border-radius: 50px;
  color: transparent;
  display: block;
  margin-bottom: var(--space-s);
  overflow: hidden;
  padding-top: 70px;
  position: relative;
  width: 100%;
}

.audio,
.audioSkeleton {
  border-radius: 50px;
  bottom: 0;
  display: block;
  left: 0;
  max-width: 100%;
  outline: none;
  right: 0;
  top: 0;
  width: 100%;
}

.audio:hover,
.audio:focus {
  outline: none;
}

:global(.mf-ie11) .audio {
  bottom: 0;
  display: block;
  height: 85px;
  left: 0;
  margin-top: -13px;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.audioSkeleton {
  background: radial-gradient(ellipse at center, #eeeeee 35%, #ffffff 37%);
}

.video,
.videoPreview,
.videoSkeleton {
  bottom: 0;
  display: block;
  left: 0;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.videoSkeleton {
  background: radial-gradient(ellipse at center, #eeeeee 35%, #ffffff 37%);
}

.videoPreview {
  filter: blur(8px);
}

.video {
  color: transparent !important; /* hide alt text */
}
