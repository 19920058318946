.root {
  @media (--up_to_M) {
    display: flex;
  }
  @media (--M_and_up) {
    display: none;
  }

  align-items: center;
  background-color: var(--grey-50);
  height: 7.5rem;
  justify-content: space-between;
}

.leftContainer {
  align-items: center;
  display: flex;
}

.hide {
  display: none;
}

.appIcon {
  margin-right: var(--space-m);
  width: 5rem;
}

.iconButton {
  align-items: center;
  background: none;
  border: none;
  color: var(--grey-200);
  cursor: pointer;
  display: flex;
  height: 4.4rem;
  justify-content: center;
  margin-right: var(--space-xs);
  width: 4.4rem;
}

.texts {
  display: flex;
  flex-direction: column;
}

.texts .title {
  @media (--up_to_S) {
    word-break: break-all;
  }
  color: var(--black-100);
  font-size: var(--font-size-copy-em);
  font-weight: var(--font-weight-copy-em);
}

.texts .usp {
  @media (max-width: 360px) {
    display: none;
  }
  @media (min-width: 361px) {
    display: block;
  }

  color: var(--black-100);
  font-size: var(--font-size-copy-small);
  font-weight: var(--font-weight-copy-small);
}

.cta {
  margin: 0 var(--space-s) 0 var(--space-xs);
  width: auto;
}
