.column {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_and_up) {
    flex-basis: 0;
    flex-grow: 1;
    width: calc(100% / 3);
  }

  margin-bottom: var(--space-xxxl);
  padding: 0 var(--grid-gutter);
}

.image {
  margin-bottom: calc(var(--space-m) - 0.6rem);
}

.image:last-child {
  margin-bottom: var(--space-xxl);
}

.caption {
  padding-bottom: calc(var(--space-s) - 0.7rem);
}
