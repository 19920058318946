.background {
  @if $manufactum {
    background-color: var(--main-100);
  }

  @if $magazin {
    background-color: var(--grey-35);
    border-top: 3px solid var(--main-100);
  }

  padding-top: 2.5rem;
}

@if $manufactum {
  .background a:focus-visible,
  .background button:focus-visible {
    border-color: transparent;
    border-radius: var(--focus-border-radius);
    color: var(--focus-text-color-inverted);
    outline: var(--focus-width) solid var(--focus-color-inverted);
    outline-offset: var(--focus-offset);
    text-decoration: none;
  }

  .background input:focus-visible,
  .background textarea:focus-visible,
  .background select:focus-visible {
    border-color: var(--focus-color);
    border-radius: var(--focus-border-radius);
    outline: var(--focus-width) solid var(--focus-color-inverted);
    outline-offset: calc(0.1rem + var(--focus-offset));
  }
}

.sections {
  @if $magazin {
    margin: 0 auto;
    max-width: var(--app-width);
  }

  @media (--up_to_L) {
    display: flex;
  }

  @media (--L_to_XL) {
    column-count: 2;
    column-gap: 1rem;
    display: block;
    position: relative;
  }

  @media (--XL_and_up) {
    display: flex;
  }

  flex-wrap: wrap;
  padding: 0 var(--grid-outer-space) 0 var(--grid-outer-space);
}

:global(.mf-script) .sections .tablet {
  @media (--up_to_L) {
    display: none;
  }
  @media (--XL_and_up) {
    display: none;
  }
}

:global(.mf-noscript) .newsletter {
  @media (--up_to_L) {
    order: 1;
  }
  @media (--XL_and_up) {
    order: 2;
  }
}

:global(.mf-noscript) .country {
  @media (--up_to_L) {
    order: 2;
  }
  @media (--XL_and_up) {
    order: 1;
  }
}

:global(.mf-noscript) .contact {
  @media (--up_to_L) {
    order: 4;
  }
  @media (--XL_and_up) {
    order: 3;
  }
}

:global(.mf-noscript) .service {
  @media (--up_to_L) {
    order: 3;
  }
  @media (--XL_and_up) {
    order: 4;
  }
}

:global(.mf-noscript) .payment {
  @media (--up_to_L) {
    order: 5;
  }
  @media (--XL_and_up) {
    order: 5;
  }
}

:global(.mf-noscript) .company {
  @media (--up_to_L) {
    order: 6;
  }
  @media (--XL_and_up) {
    order: 6;
  }
}

.newsletter {
  @media (--up_to_L) {
    @if $magazin {
      padding: 0 var(--grid-gutter) 2rem var(--grid-gutter);
    }

    flex-basis: var(--grid-12-12);
  }

  @media (--L_to_XL) {
    @if $magazin {
      padding: 0 var(--grid-gutter) 2rem var(--grid-gutter);
    }

    break-inside: avoid;
    padding: 0 var(--grid-gutter) 2rem var(--grid-gutter);
    width: 100%;
  }

  @media (--XL_and_up) {
    @if $magazin {
      padding: 0 calc(var(--grid-3-12) + var(--grid-gutter)) 2rem
        var(--grid-gutter);
    }

    flex-basis: var(--grid-9-12);
    max-width: var(--grid-9-12);
  }

  @if $manufactum {
    padding: 0 var(--grid-gutter) 2rem var(--grid-gutter);
  }
}

.country {
  @media (--up_to_L) {
    @if $magazin {
      display: none;
    }

    flex-basis: var(--grid-12-12);
  }

  @media (--L_to_XL) {
    @if $magazin {
      display: none;
    }

    break-inside: avoid;
    width: 100%;
  }

  @media (--XL_and_up) {
    flex-basis: var(--grid-3-12);
    max-width: var(--grid-3-12);
  }

  padding: 0 var(--grid-gutter) 2rem var(--grid-gutter);
}

.contact {
  @media (--up_to_L) {
    flex-basis: var(--grid-12-12);
  }

  @media (--L_to_XL) {
    break-inside: avoid;
    width: 100%;
  }

  @media (--XL_and_up) {
    flex-basis: var(--grid-3-12);
    max-width: var(--grid-3-12);
  }

  padding: 0 var(--grid-gutter) 2rem var(--grid-gutter);
}

.service {
  @media (--up_to_L) {
    flex-basis: var(--grid-12-12);
  }

  @media (--L_to_XL) {
    break-inside: avoid;
    width: 100%;
  }

  @media (--XL_and_up) {
    flex-basis: var(--grid-3-12);
    max-width: var(--grid-3-12);
  }

  padding: 0 var(--grid-gutter) 2rem var(--grid-gutter);
}

.payment {
  @media (--up_to_L) {
    flex-basis: var(--grid-12-12);
  }

  @media (--L_to_XL) {
    break-inside: avoid;
    width: 100%;
  }

  @media (--XL_and_up) {
    flex-basis: var(--grid-3-12);
    max-width: var(--grid-3-12);
  }

  padding: 0 var(--grid-gutter) 2rem var(--grid-gutter);
}

.company {
  @media (--up_to_L) {
    flex-basis: var(--grid-12-12);
  }

  @media (--L_to_XL) {
    break-inside: avoid;
    width: 100%;
  }

  @media (--XL_and_up) {
    flex-basis: var(--grid-3-12);
    max-width: var(--grid-3-12);
  }

  padding: 0 var(--grid-gutter) 2rem var(--grid-gutter);
}

:global .footer {
  -webkit-transition: margin-bottom 0.3s linear;
  -moz-transition: margin-bottom 0.3s linear;
  -o-transition: margin-bottom 0.3s linear;
  transition: margin-bottom 0.3s linear;
}

:global .cookieBannerMargin {
  margin-bottom: 420px;
}
