.root.center {
  text-align: center;
}

.root.left {
  text-align: left;
}

.root.right {
  text-align: right;
}

.price {
  white-space: nowrap;
}

.price.normal {
  color: var(--black-100);
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-bold);
  line-height: var(--line-height-copy-normal);
}

.price.large {
  color: var(--main-100);
  font-family: var(--font-family-headline);
  font-size: 2.7rem;
  line-height: 3.6rem;
}

.price.skeleton {
  background-color: var(--grey-75);
  color: transparent !important;
  display: inline-block;
  height: auto;
  line-height: 1em;
  overflow: hidden;
}

:global(.mf-fonts-not-loaded) .large .price {
  font-family: var(--font-family-headline-not-loaded);
}

.oldpriceContainer {
  font-size: 1.5em;
}

.oldPriceText {
  font-size: 0.6em;
}

s {
  position: relative;
  text-decoration: none;
}

s::before {
  background: var(--grey-150); /* this is the color of the line */
  border-radius: 0.1em;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 50%; /* tweak this to adjust the vertical position if it's off a bit due to your font family */
  transform: rotate(0);
  white-space: nowrap;
  width: 100%;
}

/* creepy Streichpreis alignment/spacing */
.normal ins.price,
.normal del.oldprice {
  padding: 0 0.75rem;
}

.normal.left ins.price,
.normal.left del.oldprice,
.normal.right ins.price,
.normal.right del.oldprice {
  padding: 0;
}

.normal.left del.oldprice {
  padding: 0 var(--space-l) 0 0;
}

.normal.right ins.price {
  padding: 0 0 0 var(--space-l);
}

/* stylelint-disable */
.large ins.price,
.large del.oldprice {
  padding: 0 var(--space-s);
}
/* stylelint-enable */

.large.left ins.price,
.large.left del.oldprice,
.large.right ins.price,
.large.right del.oldprice {
  padding: 0;
}

.large.left del.oldprice {
  padding: 0 var(--space-xl) 0 0;
}

.large.right ins.price {
  padding: 0 0 0 var(--space-xl);
}

.additionalinfo,
.unit,
.vatAndShippingNote {
  display: block;
}
