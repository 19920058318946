@media (--XXL_and_up) {
  .root {
    display: block;
  }
}

@media (--up_to_XXL) {
  .root {
    backface-visibility: hidden;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(calc(var(--offcanvas-width-s-and-up) * -1 - 1px));
    transition-duration: 200ms;
    transition-property: transform;
    transition-timing-function: ease-out;
    width: var(--offcanvas-width-s-and-up);
    z-index: 100;
  }

  .active {
    transform: translateX(0);
    transition-timing-function: ease-in;
  }
}

@media (--up_to_S) {
  .root {
    transform: translateX(calc(var(--offcanvas-width-up-to-s) * -1));
    width: var(--offcanvas-width-up-to-s);
  }
}
