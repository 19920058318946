.element {
  display: block;
  line-height: 2rem;
  margin-bottom: 0.8rem;
}

/* Stelar Text Overwrite */
.text.text.text {
  display: block;

  &,
  & a,
  & a:link,
  & a:visited {
    @if $manufactum {
      color: var(--color-copy-normal-inverted);
    }
  }
}
