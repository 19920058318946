.root {
  @if $manufactum {
    padding: 0 0 0 3.3rem;
  }

  @if $magazin {
    padding: 0 0 0 2.8rem;
  }

  color: var(--color-copy-normal);
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-normal);
  list-style: none;
  margin: 0;
  min-width: 200px;
  overflow: hidden;
}

.root li::before {
  @if $manufactum {
    background-image: url("./icon_greencheck.svg");
    height: 1.8rem;
    left: -3.3rem;
    width: 1.8rem;
  }

  @if $magazin {
    background-image: url("./icon_check_magazin.svg");
    height: 1.4rem;
    left: -2.8rem;
    top: 0.4rem;
    width: 1.4rem;
  }

  @media (--up_to_XL) {
    @if $manufactum {
      top: 0.2rem;
    }
  }

  @media (--XL_to_XXXL) {
    @if $manufactum {
      top: calc(0.2rem + (0.6 - 0.2) * (100vw - 100rem) / (150 - 100));
    }
  }

  @media (--XXXL_and_up) {
    @if $manufactum {
      top: 0.6rem;
    }
  }

  background-position: center center;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  margin-right: 1.5rem;
  position: absolute;
  text-align: center;
}

:global(.mf-ie11) .root li::before {
  @media (--up_to_XL) {
    top: 0.2rem;
  }

  @media (--XL_and_up) {
    @if $manufactum {
      top: 0.6rem;
    }
  }
}

.item {
  position: relative;
}

.item,
.item span {
  @media (--up_to_XL) {
    line-height: var(--line-height-copy-normal);
  }

  @media (--XL_to_XXXL) {
    @if $manufactum {
      line-height: calc(
        var(--line-height-copy-normal) + (3 - 2.2) * (100vw - 100rem) /
          (150 - 100)
      );
    }
  }

  @media (--XXXL_and_up) {
    @if $manufactum {
      line-height: 3rem;
    }
  }
}

.item span {
  display: block;
}

:global(.mf-ie11) .item,
:global(.mf-ie11) .item span {
  @media (--up_to_XL) {
    line-height: var(--line-height-copy-normal);
  }

  @media (--XL_and_up) {
    @if $manufactum {
      line-height: 3rem;
    }
  }
}
