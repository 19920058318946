.spacing {
  margin-top: -0.7rem;
  padding: var(--grid-gutter);
  position: relative;
}

.spacing.singleElement {
  @media (--up_to_XL) {
    margin-bottom: calc(var(--space-m) - 0.7rem);
  }

  @media (--XL_and_up) {
    margin-bottom: calc(var(--space-l) - 0.7rem);
  }
}

.spacing.multipleElements {
  @media (--up_to_XL) {
    margin-bottom: var(--space-xxxl);
  }

  @media (--XL_and_up) {
    margin-bottom: calc(var(--space-xxxxl) - 0.7rem);
  }
}
