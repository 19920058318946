@media (--XL_and_up) {
  .overlayVisible {
    @if $manufactum {
      border-top: 3px solid white;
    }

    border-bottom: 3px solid var(--main-100);
  }

  .root {
    position: relative;
  }
}

.a11yHelper {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}
