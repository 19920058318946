.root {
  @if $manufactum {
    background-color: var(--main-100);
  }

  @if $magazin {
    background-color: var(--grey-35);
    border-top: 3px solid var(--main-100);
  }
}

@if $manufactum {
  .root li a:link,
  .root li a:visited {
    color: var(--color-copy-normal-inverted);
  }

  .root li a:hover {
    color: var(--grey-50);
    text-decoration: underline;
  }

  .root li a:active {
    color: var(--secondary-50);
  }

  .root address small {
    color: var(--color-copy-normal-inverted);
  }
}

.root a:focus-visible,
.root button:focus-visible {
  @if $manufactum {
    border-color: transparent;
    border-radius: var(--focus-border-radius);
    color: var(--focus-text-color-inverted);
    outline: var(--focus-width) solid var(--focus-color-inverted);
    outline-offset: var(--focus-offset);
    text-decoration: none;
  }
}
