.root {
  display: block;
}

.large {
  height: 5rem;
  width: 5rem;
}

.small {
  height: 4rem;
  width: 4rem;
}

.innerpath {
  fill: none;
  stroke: #ffffff;
  stroke-width: 10.9579;
}

.outerpath {
  fill: none;
  stroke: #ffffff;
  stroke-width: 13.2947;
}

.teaser {
  height: 12rem;
  width: 12rem;
}
