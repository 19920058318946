.root {
  display: block; /* eliminiert Unterlängen, die bei display: flex/align-items: center stören. */
}

.large {
  height: 6rem;
  width: 6rem;
}

.medium {
  height: 4rem;
  width: 4rem;
}

.small {
  height: 2.2rem;
  width: 2.2rem;
}
