.container {
  position: relative;
}

.normal {
  margin: 0 0.6rem;
  min-height: 3rem;
  width: 3rem;
}

.large {
  margin: 0 0.9rem;
  min-height: 3.5rem;
  width: 3.5rem;
}

.throbber {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center center;
  transition: opacity 0.3s;
  width: 100%;
}

.throbber.visible {
  opacity: 1;
}

.throbber.animate {
  animation: rotate 2s linear infinite;
}

.throbber--circle {
  opacity: 0;
  stroke: var(--grey-75);
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  transition: opacity 0.3s;
}

.throbber--circle.visible {
  opacity: 1;
}

.throbber--circle.animate {
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
