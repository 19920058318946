.root {
  background-color: var(--white-100);
  border: 1px solid transparent;
  border-radius: 1.8rem;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.4);
  color: var(--darkGrey-105);
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: var(--font-weight-copy-semibold);
  height: 3.6rem;
  line-height: 1em;
  margin: 0;
  padding: 0 var(--space-m) 0.2rem;
  text-align: center;
  transition: box-shadow 0.2s;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}

.root:hover {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.1s;
}

.root:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.content {
  align-items: center;
  display: flex;
  height: 100%;
}

.text {
  flex-grow: 1;
  margin-right: 1rem;
  text-align: left;
}

.icon {
  text-align: right;
}
