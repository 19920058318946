.compact {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

.selection {
  padding: 1rem 2.5rem 1rem 1.5rem;
}

.countryContainer {
  background-color: var(--grey-35);
}
