.root {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
  width: 5.4rem;
}

.root:focus {
  outline: 0;
}

.root:focus::after {
  border: 2px solid var(--grey-100);
  border-radius: 50%;
  bottom: -1px;
  content: "";
  display: block;
  left: -1px;
  position: absolute;
  right: -1px;
  top: -1px;
}

.root::-moz-focus-inner {
  border-width: 0;
  margin: 0;
  padding: 0;
}

.root:focus-visible::after {
  border-color: var(--focus-color);
  outline: var(--focus-width) solid var(--white-100);
}

.background {
  background-color: var(--white-100);
  border-radius: 50%;
  color: var(--grey-200);
  display: block;
  padding-top: 100%;
  position: relative;
  transition: background-color 0.2s;
  width: 100%;
}

.root:hover .background,
.root:active .background {
  color: var(--main-75);
}

:global(.mf-ie11) .root .background::after {
  display: none;
}

.root .background::after {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: inherit;
  content: "";
  display: block;
  filter: blur(3px);
  height: 100%;
  opacity: 0.1;
  position: absolute;
  top: 0;
  transform: translateY(2px);
  transition:
    opacity 0.2s,
    transform 0.2s;
  width: 100%;
  z-index: -1;
}

.root:hover .background::after,
.root:active .background::after {
  opacity: 0.9;
  transform: translateY(7px);
}

:global(.slick-disabled) .background,
:global(.slick-disabled) .root:hover .background,
:global(.slick-disabled) .root:active .background {
  box-shadow: none;
  color: var(--grey-75);
  cursor: arrow;
}

.iconWrapper {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.screenreaderOnly {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
