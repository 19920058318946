.root {
  display: block;
}

.large {
  height: 5rem;
  width: 5rem;
}

.small {
  height: 4rem;
  width: 4rem;
}

.innerpath {
  fill: #2b2b2a;
}

.innerrect {
  fill: none;
  stroke: #2b2b2a;
  stroke-miterlimit: 10;
  stroke-width: 4.9;
}

.innereclipse {
  fill: none;
  stroke: #2b2b2a;
  stroke-width: 11.406;
}

.outerpath {
  fill: none;
  stroke: #ffffff;
  stroke-width: 13.2947;
}
