.root {
  border: thin solid var(--grey-75);
  display: inline-block;
  font-size: var(--font-size-input);
  height: 3.8rem;
  min-width: 3rem;
  padding-left: 1.5rem;
  width: 100%;
}

.root.city {
  width: 65%;
}

.root.dateDay {
  width: 32%;
}

.root.dateMonth {
  width: 29%;
}

.root.dateYear {
  width: 32%;
}

.root.houseNumber {
  width: 32%;
}

.root.street {
  width: 65%;
}

.root.zip {
  width: 32%;
}

.root:disabled {
  background-color: var(--grey-50);
  cursor: not-allowed;
}

.root:read-only {
  border-color: transparent;
  cursor: not-allowed;
}

.root.error {
  background-color: var(--error-50);
  border: thin solid var(--error-100);
}

.root.error:focus-visible {
  border-color: transparent;
  outline-color: var(--error-100);
}
