.root {
  border-bottom: thin solid var(--color-divider-prio1);
}

.root a:link,
.root a:visited {
  text-decoration: none;
}

.limiter {
  @if $magazin {
    margin: 0 auto;
    max-width: var(--app-width);
  }

  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--grid-outer-space)
    calc(var(--grid-outer-space) + var(--grid-gutter));
}

.logoContainer {
  @media (--up_to_M) {
    /* IE 11 fix */
    flex: 0 !important;
    flex-basis: 100% !important;

    margin: 0 auto;
  }

  @media (--up_to_L) {
    width: 48%;
  }

  @media (--L_and_up) {
    width: 33%;
  }

  max-width: 24rem;
  order: 1;
  padding-bottom: 0.3rem;
}

.logoContainer:focus-within {
  border-color: transparent;
  border-radius: var(--focus-border-radius);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
}

.logo {
  height: 4rem;
  max-width: 100%;
  width: 24rem;
}

.telNumberContainer {
  @media (--up_to_M) {
    display: none;
  }

  @media (--up_to_L) {
    width: 48%;
  }

  @media (--L_and_up) {
    width: 66%;
  }

  order: 2;
  text-align: right;
  white-space: nowrap;
}

.telNumberIcon {
  @media (--L_and_up) {
    left: -999em;
    position: absolute;
  }

  color: var(--main-100);
  display: inline-block;
}

@media (--up_to_M) {
  .telNumberContainer * {
    font-size: 1.6rem;
  }

  .telNumberIcon :global .mf-Icon__small {
    height: 1.3rem;
    width: 1.3rem;
  }
}

.telNumberKey {
  @media (--up_to_L) {
    left: -999em;
    position: absolute;
  }
}
