.root {
  text-align: center;
  text-transform: uppercase;
}

.prio0,
.prio1,
.prio2 {
  color: var(--color-copy-strong);
  font-size: var(--font-size-copy-strong);
  font-weight: var(--font-weight-copy-normal);
  line-height: var(--line-height-copy-strong);
  text-align: center;
  text-transform: uppercase;
}

.prio3,
.prio4 {
  color: var(--color-copy-small);
  font-size: var(--font-size-copy-small);
  font-weight: var(--font-weight-copy-small);
  line-height: var(--line-height-copy-small);
}

.skeleton {
  color: transparent !important;
  height: auto;
  overflow: hidden;
}

.skeleton span {
  background-color: var(--grey-75);
  line-height: 1em;
}
