.root {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.teaser {
  @media (--up_to_M) {
    flex-basis: 100%;
    max-width: 100%;
  }

  align-items: stretch;
  display: flex;
}

.teasers-one .teaser {
  @media (--M_and_up) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.teasers-two .teaser {
  @media (--M_and_up) {
    flex-basis: 50%;
    max-width: 50%;
  }

  margin-bottom: var(--space-xxxl);
  padding-bottom: var(--space-s);
}

.teasers-three .teaser {
  @media (--up_to_M) {
    margin-bottom: var(--space-m);
  }

  @media (--M_and_up) {
    flex-basis: 33.3%;
    margin-bottom: var(--space-xxxl);
    max-width: 33.3%;
  }

  padding-bottom: var(--space-s);
}

.teasers-four .teaser {
  @media (--up_to_M) {
    margin-bottom: 0;
  }

  @media (--M_and_up) {
    margin-bottom: var(--space-xxxl);
  }

  @media (--up_to_L) {
    flex-basis: 50%;
    max-width: 50%;
  }

  @media (--XL_and_up) {
    flex-basis: 25%;
    max-width: 25%;
  }

  padding-bottom: var(--space-s);
}

@media (--L_to_XL) {
  .teasers-four.fullWidth .teaser {
    flex-basis: 25%;
    max-width: 25%;
  }

  .teasers-four.indented .teaser {
    flex-basis: 50%;
    max-width: 50%;
  }
}
