.root {
  @media (--M_and_up) {
    margin: 0 var(--grid-outer-space);
  }

  @media (--up_to_M) {
    background-color: var(--grey-35);
    padding: 0 var(--grid-outer-space);
  }
}

.inner {
  @media (--up_to_L) {
    margin-top: var(--space-l);
    padding-bottom: 6rem;
  }

  @media (--L_to_XL) {
    margin: var(--space-l) var(--grid-1-12) 0;
    padding-bottom: 6rem;
    width: var(--grid-10-12);
  }

  @media (--XL_to_XXXL) {
    margin-top: var(--space-l);
    padding: 0 var(--grid-gutter) 6rem;
  }

  @media (--XXXL_and_up) {
    margin: var(--space-l) var(--grid-1-12) 0;
    padding-bottom: 6rem;
    width: var(--grid-10-12);
  }

  /* opacity: 0.7; */
  display: flex;
}

.textWrapper {
  @media (--up_to_XL) {
    flex-basis: var(--grid-12-12);
  }

  @media (--M_to_L) {
    flex-basis: var(--grid-12-12);
  }

  @media (--L_to_XL) {
    flex-basis: var(--grid-12-12);
  }

  @media (--XL_to_XXL) {
    flex-basis: var(--grid-7-12);
  }

  @media (--XXL_to_XXXL) {
    flex-basis: var(--grid-6-12);
  }

  @media (--XXXL_and_up) {
    flex-basis: var(--grid-6-12);
  }
}

.textPadding {
  @media (--up_to_M) {
    padding: var(--space-m) var(--grid-gutter) var(--space-l);
  }

  @media (--M_to_L) {
    margin: 0 var(--grid-gutter);
    padding: 4.5rem calc(var(--grid-1-12)) 6rem;
  }

  @media (--L_to_XL) {
    padding: 4.5rem calc(10% + var(--grid-gutter)) 6rem;
  }

  @media (--XL_to_XXL) {
    padding: 4.5rem 11% 6rem 14.29%;
  }

  @media (--XXL_to_XXXL) {
    padding: 4.5rem 13.5% 6rem 16.66%;
  }

  @media (--XXXL_and_up) {
    padding: 4.5rem 12% 6rem 20%;
  }

  background-color: var(--grey-35);
  height: 100%;
}

.listTitle {
  margin-bottom: var(--space-l);
}

.newsletterWrapper {
  margin: var(--space-xxl) 0 0;
  min-height: 28rem;
}

.imageWrapper {
  @media (--up_to_XL) {
    display: none;
  }

  @media (--XL_to_XXL) {
    flex-basis: var(--grid-5-12);
  }

  @media (--XXL_and_up) {
    flex-basis: var(--grid-6-12);
  }

  background-color: var(--grey-50);
  overflow: hidden;
  position: relative;
}

.image,
.highResImage,
.imagePreview {
  background-position: center;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.highResImage {
  display: none;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .highResImage {
    display: block;
  }

  .image {
    display: none;
  }
}

.imagePreview {
  filter: blur(20px);
}

.imagePreviewSrc {
  left: 9999rem;
  position: absolute;
}
