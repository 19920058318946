.root.compact {
  display: block;
}

.root.fluid {
  @media (--up_to_L) {
    display: block;
  }

  @media (--L_and_up) {
    display: table-row;
  }

  width: 100%;
}

.root.standard {
  @media (--up_to_L) {
    display: block;
  }

  @media (--L_and_up) {
    display: flex;
  }
}

.root.fixed {
  display: flex;
}

.root input[type="email"],
.root input[type="number"],
.root input[type="password"],
.root input[type="tel"],
.root input[type="text"],
.root select,
.root textarea {
  margin-top: -1rem;
}

.root.compact input[type="email"],
.root.compact input[type="number"],
.root.compact input[type="password"],
.root.compact input[type="tel"],
.root.compact input[type="text"],
.root.compact select,
.root.compact textarea {
  margin-top: 0;
}

.error,
.error.light {
  color: var(--error-100);
}

.light {
  color: var(--color-copy-light);
}

.grey-200 {
  color: var(--grey-200);
}

.errorMessage {
  @media (--up_to_L) {
    margin-top: 0.8rem;
  }

  @media (--L_and_up) {
    margin-top: var(--space-xs);
  }

  color: var(--error-100);
  font-size: var(--font-size-copy-small);
  white-space: normal;
}

.inverted {
  color: var(--color-copy-normal-inverted);
}

.inverted .light {
  color: var(--color-copy-light-inverted);
}

.inverted .error,
.inverted .error.light {
  color: var(--error-50);
}

.inverted .errorMessage {
  background-color: var(--error-50);
  padding: 0.3rem 0.5rem 0.3rem 1.6rem;
  width: 100%;
}

.formCell {
  padding-bottom: 2rem;
  padding-top: 1rem;
}

.compact .formCell {
  display: block;
  padding-top: 0;
}

.formCellLabels {
  overflow: hidden;
  text-overflow: ellipsis;
}

.justified {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.compact .formCellInputs {
  min-width: 0;
}

.compact .formCellLabels {
  padding-bottom: 0;
  text-align: left;
  white-space: nowrap;
}

.compact .formCellLabels label {
  line-height: 3rem;
}

.compact .formCellLabels label.wrap {
  line-height: 2rem;
  white-space: normal;
}

.standard .formCellInputs {
  @media (--up_to_L) {
    width: auto;
  }

  @media (--L_to_XL) {
    width: 50%;
  }

  @media (--XL_and_up) {
    width: 33%;
  }
}

.standard .formCellLabels {
  @media (--up_to_L) {
    padding-bottom: 0.8rem;
    width: auto;
  }

  @media (--L_to_XL) {
    width: 25%;
  }

  @media (--XL_and_up) {
    width: 33%;
  }
}

.fluid .formCellLabels {
  @media (--up_to_L) {
    padding-bottom: 0.8rem;
  }

  @media (--L_and_up) {
    width: 0;
  }

  white-space: nowrap;
}

.standard .formCellLabels,
.fluid .formCellLabels {
  @media (--up_to_L) {
    padding-right: 0;
    text-align: left;
  }

  @media (--L_and_up) {
    padding-right: 3rem;
    text-align: right;
  }
}

.fluid .formCell {
  @media (--up_to_L) {
    display: block;
    white-space: normal;
  }

  @media (--L_and_up) {
    display: table-cell;
    vertical-align: top;
  }
}

.fluid .formCellInputs {
  min-width: 14em;
  width: 100%;
}

.fixed .formCellLabels {
  flex-basis: 8rem;
  margin-right: var(--space-xl);
  max-width: 8rem;
  text-align: right;
}

.fixed .formCellInputs {
  align-items: center;
  display: flex;
  flex: 1;
}

.root.inverted input:focus-visible,
.root.inverted textarea:focus-visible,
.root.inverted select:focus-visible {
  border-color: var(--white-100);
  border-radius: var(--focus-border-radius);
  outline: var(--focus-width) solid var(--focus-color-inverted);
  outline-offset: calc(0.1rem + var(--focus-offset));
}
