.root {
  flex: 1;
  margin-bottom: var(--space-xl);
  position: relative;
  text-align: center;
}

.root.separated {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 0;
}

.link {
  color: black;
  cursor: pointer;
  display: block;
  height: 100%;
  max-width: 100%;
  text-decoration: none;
  width: 100%;
}

.link:focus {
  text-decoration: underline;
}

.link:focus::after {
  background-color: rgba(150, 150, 150, 0.05);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

.link:focus:active {
  text-decoration: none;
}

.link:focus:active::after {
  display: none;
}

.link.link:focus-visible {
  border-radius: 0;
}

.separated .link {
  display: flex;
  flex: 1 1 auto;
  height: unset;
  margin: calc(var(--focus-width) + var(--focus-offset)) 0;
}

.box {
  @if $magazin {
    overflow: auto;
  }

  max-width: 100%;
  width: 100%;
}

.separated .box {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.separated .box > div + div {
  flex: 1;
}

:global(.mf-ie11) .separated .box > div + div {
  flex: none;
}

.imagebox {
  @if $magazin {
    @media (--up_to_M) {
      margin: var(--space-m);
    }

    @media (--M_and_up) {
      margin: var(--space-xxl);
    }
  }

  position: relative;
}

.separated .imagebox {
  @if $magazin {
    margin: var(--space-s);
  }

  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}

.separated .textbox {
  padding: 0 var(--space-xs);
  position: relative;
}

.flags .flag {
  margin-bottom: calc(var(--space-xs) - 0.1rem);
}

.wrapper {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.eelContainer {
  margin-top: 6px;
}
