.root {
  max-width: 90rem;
  min-width: 24rem;
}

.gutter {
  margin: var(--space-l) 0;
}

.center {
  display: flex;
  justify-content: center;
  text-align: center;
}

.imageAndForm {
  @media (--up_to_M) {
    grid-template-columns: 1fr;
  }

  @media (--M_and_up) {
    grid-template-columns: 1fr 1fr;
  }

  column-gap: var(--space-l);
  display: grid;
}

.info {
  border-top: thin solid var(--grey-75);
  margin-top: var(--space-l);
  padding: var(--space-xl) var(--space-l) 0 var(--space-l);
}
