.root {
  line-height: 2rem;
  padding: var(--space-l) calc(var(--grid-gutter) + var(--grid-outer-space))
    var(--space-xl) calc(var(--grid-gutter) + var(--grid-outer-space));
  text-align: center;
}

.root a[href^="tel"] {
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}

/* Stelar Text Overwrite */
.text.text.text {
  @if $manufactum {
    color: var(--color-copy-normal-inverted);
  }
}
