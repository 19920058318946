.button {
  background: none;
  border: 0;
  color: var(--grey-150);
  cursor: pointer;
  display: flex;
  font-size: inherit;
  padding: 1.2rem 0 1rem;
  text-align: inherit;
  width: 100%;
}

.button.icon-right {
  flex-direction: row-reverse;
}

.button:not(.small):focus-visible {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-2 * var(--focus-width));
}

.button:not(.small).open:focus-visible {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color-inverted);
  outline-offset: calc(-2 * var(--focus-width));
}

.button.light:focus-visible,
.button.light.open:focus-visible {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: 0;
}

.button.light {
  background-color: var(--white-100);
  color: var(--grey-150);
  padding: calc(var(--space-xl) - 0.8rem) 0;
}

.button.light.open {
  padding: calc(var(--space-xl) - 0.8rem) 0 calc(var(--space-xl) - 1.2rem) 0;
}

.button.small {
  @media (--XL_and_up) {
    display: block;
    pointer-events: none;
  }

  padding-bottom: 0;
}

.button.disabled,
.button.open.disabled {
  background-color: var(--grey-50);
  color: var(--grey-100);
}

.button.light.disabled,
.button.light.open.disabled {
  background-color: var(--white-100);
}

.icon {
  margin-right: var(--space-l);
  margin-top: var(--space-xs);
}

.icon-right .icon {
  min-width: 5rem;
}

.icon-right.light .icon {
  min-width: 2rem;
}

.icon-right .title {
  flex: 1;
}
