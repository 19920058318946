.root {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* COMPACT */

.compact.root {
  display: block;
}

/* FLYOUT */

.flyout.root {
  @if $manufactum {
    justify-content: center;
    padding: 0.6rem 0;
  }

  @if $magazin {
    @media (--XXL_to_XXXL) {
      padding: 0 calc((100vw - 123.8rem) / 2);
    }

    @media (--XXXL_and_up) {
      padding: 0 13.1rem;
    }

    justify-content: space-between;
  }

  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 9;
}
