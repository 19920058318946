.root {
  @media (--M_and_up) {
    display: flex;
  }
}

.image {
  @media (--up_to_M) {
    flex-basis: var(--grid-12-12);
    margin-bottom: var(--space-l);
  }

  @media (--M_to_XL) {
    flex-basis: var(--grid-5-12);
  }

  @media (--XL_and_up) {
    flex-basis: 50%;
  }

  padding: 0 var(--grid-gutter);
}

.text {
  @media (--up_to_M) {
    flex-basis: var(--grid-12-12);
  }

  @media (--M_to_XL) {
    flex-basis: var(--grid-7-12);
  }

  @media (--XL_and_up) {
    flex-basis: 50%;
  }

  padding: 0 calc(var(--grid-gutter) + var(--space-l));
  text-align: center;
}
