.flex {
  display: flex;
  flex-wrap: wrap;
}

.gutters {
  padding: 0 var(--grid-gutter);
}

/* variant: none */

.none {
  margin-left: 0;
  margin-right: 0;
}

/* variant: text */

.text.fullWidth {
  @media (--M_to_L) {
    margin-left: var(--grid-1-12);
    margin-right: var(--grid-1-12);
  }

  @media (--L_and_up) {
    margin-left: var(--grid-2-12);
    margin-right: var(--grid-2-12);
  }
}

.text.indented {
  @media (--M_to_L) {
    margin-left: var(--grid-1-12);
    margin-right: var(--grid-1-12);
  }

  @media (--L_to_XXL) {
    margin-left: calc(100% / 9);
    margin-right: calc(100% / 9);
  }

  @media (--XXL_and_up) {
    margin-left: calc(100% / 10);
    margin-right: calc(100% / 10);
  }
}

/* variant: columns */

.columns.fullWidth {
  @media (--M_to_XL) {
    margin-left: var(--grid-1-12);
    margin-right: var(--grid-1-12);
  }

  @media (--XL_and_up) {
    margin-left: var(--grid-2-12);
    margin-right: var(--grid-2-12);
  }
}

.columns.indented {
  @media (--XL_to_XXL) {
    margin-left: calc(100% / 9);
    margin-right: calc(100% / 9);
  }

  @media (--XXL_and_up) {
    margin-left: calc(100% / 10);
    margin-right: calc(100% / 10);
  }
}

/* variant: narrowcolumns */

.narrowcolumns.fullWidth {
  @media (--L_and_up) {
    margin-left: var(--grid-2-12);
    margin-right: var(--grid-2-12);
  }
}

.narrowcolumns.indented {
  @media (--XL_to_XXL) {
    margin-left: calc(100% / 9);
    margin-right: calc(100% / 9);
  }

  @media (--XXL_and_up) {
    margin-left: calc(100% / 10);
    margin-right: calc(100% / 10);
  }
}

/* variant: imagetext */

.imagetext.fullWidth {
  @media (--M_to_XL) {
    margin-left: var(--grid-1-12);
    margin-right: var(--grid-1-12);
  }

  @media (--XL_and_up) {
    margin-left: var(--grid-2-12);
    margin-right: var(--grid-2-12);
  }
}

.imagetext.indented {
  @media (--M_to_L) {
    margin-left: var(--grid-1-12);
    margin-right: var(--grid-1-12);
  }

  @media (--XL_to_XXL) {
    margin-left: calc(100% / 9);
    margin-right: calc(100% / 9);
  }

  @media (--XXL_and_up) {
    margin-left: calc(100% / 10);
    margin-right: calc(100% / 10);
  }
}
