.root {
  align-content: stretch;
  color: var(--grey-150);
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-copy-small);
  font-weight: var(--font-weight-copy-normal);
  line-height: var(--line-height-copy-normal);
  width: calc(100% * 8 / 12);
}

.withoutImage {
  width: 100%;
}

.description {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.group1 {
  display: inline-block;
  vertical-align: top;
  width: calc(100% * 8 / 12);
}

.group1 > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.group1 > div:first-child {
  width: 100%;
}

.group1 > div:nth-child(n + 2) {
  width: 150%;
}

.group2 {
  @if $magazin {
    font-weight: var(--font-weight-copy-normal);
  }

  @if $manufactum {
    font-weight: var(--font-weight-copy-em);
  }

  border-collapse: collapse;
  color: var(--black-100);
  display: inline-block;
  line-height: var(--line-height-copy-strong);
  margin-bottom: var(--space-xs);
  text-align: right;
  vertical-align: top;
  width: calc(100% * 4 / 12);
}

.key::after {
  content: ":";
}

.val {
  color: var(--black-100);
}

.name {
  align-items: flex-start;
  color: var(--black-100);
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-copy-em);
  line-height: var(--line-height-copy-strong);
}

:global(a):hover .name,
:global(a):active .name {
  text-decoration: underline;
}

.manufacturer {
  text-transform: uppercase;
}

.ageRelated .icon {
  display: inline-block;
  height: 2.8rem;
  margin: 0 0.5rem 0.5rem 0;
  vertical-align: middle;
  width: 2.8rem;
}

.ageRelated button {
  text-decoration: underline;
}

.ageRelatedDrawerClosed {
  display: none;
}

.ageRelatedDrawerOpen {
  display: block;
}

@media (--up_to_L) {
  .lineItemButtons {
    margin: var(--space-m) 0 var(--space-xs) 0;
    text-align: right;
  }

  .buttons {
    text-align: right;
  }

  .buttons button {
    margin: 0;
  }

  .buttons button:first-child {
    float: left;
  }
}
