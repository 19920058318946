.root {
  @if $magazin {
    background-color: var(--grey-35);
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: var(--app-width);
  padding: 0 var(--grid-outer-space);
}

.linkList {
  list-style: none;
  margin: 0;
  padding: 0 0 0 calc(var(--grid-gutter) - var(--focus-width));
}

.listElement {
  @media (--up_to_L) {
    margin: 0 0.75rem 0 0;
    padding: 0 0.75rem 0 0;
  }

  @media (--L_to_XL) {
    margin: 0 0.5rem 0 0;
    padding: 0 0.5rem 0 0;
  }

  @media (--XL_and_up) {
    margin: 0 0.75rem 0 0;
    padding: 0 0.75rem 0 0;
  }

  display: inline-block;
  position: relative;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.listElement:last-child::after {
  @if $manufactum {
    border-right: none;
  }

  @if $magazin {
    border-right: none;
  }
}

.listElement::after {
  @media (--XL_and_up) {
    border-right: 1px solid var(--color-divider-prio1);
    content: "";
    display: block;
    height: 1.8rem;
    position: absolute;
    right: 0;
    top: 1.2rem;
    width: 0;
  }
}

.listLink {
  display: block;
  padding: 0.65em 0;
}

.phone {
  display: inline-block;
  font-weight: var(--font-weight-copy-bold);
  padding-left: 0.75em;
}

.linkText {
  color: var(--color-copy-light);
  font-size: var(--font-size-copy-small);
  font-weight: var(--font-weight-copy-normal);
  line-height: var(--line-height-copy-normal);
}

.linkText a {
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
}

.linkText a[data-prio2] {
  text-decoration: none;
}

.linkText a:hover,
.linkText button[data-pure-button]:hover {
  color: #005232;
  text-decoration: underline;
}

.linkText a:active,
.linkText button[data-pure-button]:active {
  color: #017447;
}

.linkLink {
  display: block;
  padding: 0.65em var(--focus-width);
}

.linkLink.linkLink:focus-visible {
  outline-offset: calc(var(--focus-width) * -1);
}

.serviceContainer {
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0 0 0 1rem;
}

.countryFlagSize {
  height: 17px;
  margin-right: 0.75rem;
  vertical-align: sub;
}

.countryFlagButton {
  @media (--up_to_XL) {
    display: none;
  }
  @media (--XL_and_up) {
    background-color: transparent;
    border: none;
    color: #787b7d;
    cursor: pointer;
    display: block;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.2rem;
    padding: 0;
  }
}

.countryFlagButton:hover {
  color: #005232;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-decoration: underline;
}

.countryFlagButton:focus-visible {
  border-color: var(--white-100);
  border-radius: var(--focus-border-radius);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
}

.countryContainer {
  display: flex;
  position: relative;
}

.countrySelectionContainer {
  @media (--up_to_XL) {
    display: none;
  }

  @media (--XL_and_up) {
    right: 0.5rem;
  }

  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 1rem;
  z-index: 99;
}

.header {
  align-items: flex-start;
  background: var(--grey-35);
  border-top: thin solid silver;
  flex-wrap: wrap;
  height: 5rem;
  justify-content: space-between;
  padding: var(--space-s) 3.5rem 0 2rem;
  position: relative;
  text-align: center;
}

.selectionContainer {
  margin-bottom: 2vh;
  margin-left: 2rem;
}

.title {
  @if $magazin {
    text-transform: uppercase;
  }

  color: var(--color-headline-prio2);
  display: inline-block;
  font-family: var(--font-family-headline);
  font-size: var(--font-size-headline-prio2);
  line-height: var(--line-height-headline-prio2);
  overflow: hidden;
  padding-right: 2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.buttonX {
  position: absolute;
  right: var(--space-s);
  top: var(--space-s);
}

.optionLinkContainer {
  margin: 1rem 0;
}

.optionLink {
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: none;
}

.optionLink.active {
  color: #005232;
  cursor: pointer;
  font-size: inherit;
  font-weight: 600;
  text-decoration: none;
}

.optionLink:hover {
  color: #005232;
  text-decoration: underline;
}
