.root {
  border-collapse: collapse;
}

.root.flexible {
  width: 100%;
}

.root small {
  text-transform: none;
}

.root th {
  padding: 0 var(--space-s) var(--space-s) 0;
  text-align: left;
}

.root td {
  padding: 0 0 var(--space-s) 0;
  text-align: right;
  vertical-align: top;
}

.root.normal .grandTotalHeader {
  padding-top: 1.5rem;
}

.root.mini .grandTotalHeader {
  padding-top: 0.7rem;
}

.root .grandTotalData {
  padding-top: var(--space-xs);
  vertical-align: top;
}

.root.normal .grandTotalData,
.root.normal .grandTotalHeader {
  border-top: thin solid silver;
}

.root.mini {
  width: 100%;
}

.root.mini th {
  white-space: normal;
}

.root td.shippingChargeAdvice {
  text-align: left;
}

.root td.basketDiscountAdvice {
  text-align: left;
}
