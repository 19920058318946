.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: var(--grid-gutter);
  text-align: center;
}

.logo {
  margin-bottom: 3rem;
  width: 30rem;
}
