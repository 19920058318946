/* .text.text to override small styling */
.text.text {
  font-weight: var(--font-weight-copy-em);
}

.item {
  margin-top: var(--space-m);
}

.text.available {
  color: var(--success-100);
}

.text.deliverable {
  color: var(--attention-100);
}

.text.tempUnavailable {
  color: var(--attention-100);
}

.text.soldOut {
  color: var(--error-100);
}
