.root {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
}

:global(.mf-ie11) .root {
  display: block;
}

.image {
  display: block;
  margin-bottom: var(--space-m);
  padding: 0 var(--grid-gutter);
  width: 100%;
}

.title {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-m);
  padding: 0 var(--grid-gutter);
  text-align: center;
}

.text {
  padding: 0 var(--grid-gutter);
}

.wrapper {
  margin-bottom: var(--space-l);
}

.button {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 var(--grid-gutter);
}

.eelContainer {
  margin-top: 6px;
}
