.root {
  @if $magazin {
    text-transform: uppercase;
  }

  display: inline-table;
  padding: 0 0.5rem;
  text-align: center;
}

/* variants */

.root.label {
  @if $manufactum {
    background-color: var(--main-100);
  }

  @if $magazin {
    background-color: var(--main-50);
  }
}

.root.new {
  @if $manufactum {
    background-color: var(--attention-100);
  }

  @if $magazin {
    background-color: var(--main-50);
  }
}

.root.exclusive {
  background-color: var(--main-100);
}

/* sizes */

.root.small {
  @if $manufactum {
    width: 4rem;
  }

  @if $magazin {
    padding: 0.2rem 0.7rem 0.2rem;
    width: 4rem;
  }
}

.root.large {
  width: 5rem;
}

.root.long {
  padding: 0.4rem 1rem 0.4rem;
}

.root.xx-large {
  width: 8rem;
}

.root.x-large {
  width: 7rem;
}

.root.x-large-pad {
  padding: 0.1rem 1rem 0.1rem;
  width: 7rem;
}

/* Font Loading */

@if $manufactum {
  :global(.mf-fonts-not-loaded) .root.large {
    padding: 0.4rem 0.7rem 0.4rem;
  }

  :global(.mf-fonts-not-loaded) .root.small {
    padding: 0.15rem 0.7rem 0.2rem;
  }
}

@if $magazin {
  :global(.mf-fonts-critical) .root {
    letter-spacing: -0.2rem;
  }
}
