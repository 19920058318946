.root {
  @media (--L_and_up) {
    margin-bottom: calc(var(--space-xxxl) + var(--space-xxxl));
  }

  @media (--up_to_L) {
    margin-bottom: var(--space-xl);
  }
}

.accordion {
  @media (--L_to_XL) {
    padding-top: var(--space-xl);
  }

  @media (--XL_and_up) {
    padding-top: var(--space-xxl);
  }

  padding-left: var(--grid-gutter);
  padding-right: var(--grid-gutter);
  width: 100%;
}

.accordionContent {
  margin-bottom: var(--space-xl);
  padding-left: 3rem;
}

.accordionContent::after {
  clear: both;
  content: "";
  display: block;
}

.image {
  @media (--S_and_up) {
    float: right;
    margin-left: var(--grid-gutter);
    margin-top: var(--space-xs);
  }

  @media (--XXL_and_up) {
    width: 25.5%;
  }

  @media (--L_to_XXL) {
    width: 30%;
  }

  @media (--M_to_L) {
    width: 32%;
  }

  @media (--S_to_M) {
    width: 36%;
  }
}

.text {
  @media (--S_and_up) {
    float: left;
  }
}

.text.withImage {
  @media (--S_to_M) {
    width: 59%;
  }

  @media (--M_to_L) {
    width: 65%;
  }

  @media (--L_to_XXL) {
    width: 67%;
  }

  @media (--XXL_and_up) {
    width: 73%;
  }
}

div[role="tab"] > button:focus {
  outline: none !important;
}

.accordionItemHeading {
  font-size: 1.5rem;
}
