:global(html) {
  font-size: 10px;
}

:global(body) {
  background-color: var(--white-100);
  font-size: var(--font-size-copy-normal);
  margin: 0;
  overflow-anchor: none;
}

:global(input[type="number"]) {
  -moz-appearance: textfield;
}

:global(input[type="number"]::-webkit-inner-spin-button),
:global(input[type="number"]::-webkit-outer-spin-button) {
  -webkit-appearance: none;
  margin: 0;
}

/* stylelint-disable-next-line */
:global(ins) {
  background: none;
  text-decoration: none;
}

.root {
  @if $manufactum {
    margin: 0 auto;
    max-width: var(--app-width);
  }

  overflow: hidden;
}

.root * {
  box-sizing: border-box;
  font-family: var(--font-family-copy);

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

:global(.mf-fonts-critical) .root * {
  font-family: var(--font-family-copy-critical);
}

:global(.mf-fonts-not-loaded) .root * {
  @if $manufactum {
    letter-spacing: 0.024em;
    word-spacing: 0.045em;
  }

  @if $magazin {
    letter-spacing: -0.016em;
    word-spacing: 0.045em;
  }

  font-family: var(--font-family-copy-not-loaded);
}

.root a {
  &:active,
  &:hover {
    outline-width: 0;
  }
}

a:focus-visible,
a:hover:focus-visible {
  border-color: transparent;
  border-radius: var(--focus-border-radius);
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
  text-decoration: none;
}

/* safari doesn't display a custom outline for links containing svg's, so we use the native outline */
:global(html.mf-mac_os.mf-safari) a:focus-visible,
:global(html.mf-mac_os.mf-safari) a:hover:focus-visible {
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: initial !important;
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  border-color: var(--white-100);
  border-radius: var(--focus-border-radius);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
}

h1,
h2,
h3,
h4,
h5,
strong,
b,
i,
small,
em,
p {
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

:global(.offCanvasActive) {
  height: 100%;
  height: 100dvh;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

:global(.visuallyHidden) {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
