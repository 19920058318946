.root {
  border-top: thin solid silver;
  list-style: none;
  margin: 0;
  padding: 0;
}

.li {
  border-bottom: thin solid silver;
  padding: var(--space-l) 0;
}
