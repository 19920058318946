.root {
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 720px;
  min-height: calc(100vh - 2 * var(--space-m));
}

.overlay {
  @media (--up_to_M) {
    min-width: 290px;
  }

  @media (--M_and_up) {
    min-width: 350px;
  }

  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  min-height: 270px;
}

.overlay.fullscreen {
  @media (--up_to_L) {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

.overlay.fullscreen.scrollContent {
  @media (--L_and_up) {
    max-height: 80vh;
  }

  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.header {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  min-height: 3.6rem;
  width: calc(100% - 2 * var(--space-l));
}

.header.titled {
  border-bottom: 1px solid var(--color-divider-prio2);
  margin: var(--space-l);
}

.header.untitled {
  margin: var(--space-l) var(--space-l) var(--space-s);
}

.headline.headline.headline {
  @if $magazin {
    border: none;
    display: flex;
    padding: 0;
  }

  border: none;
  flex: 1;
  margin: 0 0 var(--space-s);
}

.headline.headline.headline.stickyCloseButton {
  padding-right: 2.1rem;
}

.icon {
  display: inline-block;
  margin-right: 1.2rem;
  vertical-align: bottom;
}

.closeButton {
  z-index: 10;
}

.closeButton.withoutHeader {
  margin: calc(2 * var(--focus-width));
  padding: 0.5rem;
  position: absolute;
}

.titled .closeButton {
  margin: -1.2rem -1.5rem -1.2rem -1.2rem;
  padding: 0.6rem;
}

.closeButton > span {
  align-items: center;
  background-color: transparent;
  border-radius: 3.6rem;
  display: flex;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  transition: background-color 0.3s;
}

.closeButton.stickyCloseButton {
  position: absolute;
  top: 1.5rem;
}

.closeButton.stickyCloseButton > span {
  background-color: rgba(0, 0, 0, 0.54);
  color: #ffffff;
}

.closeButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.content {
  overflow-wrap: break-word;
  padding: 0 2rem 3rem;
  position: relative;
}

.content.withoutHeader {
  margin-top: 5rem;
}

.content.scrollContent {
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
}

.actions {
  border-top: 1px solid var(--grey-75);
  margin: 0 2rem;
  padding: 1.5rem 0;
}
