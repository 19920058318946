@if $magazin {
  .heading.heading.heading {
    font-size: 1.7rem;
  }
}

/* Stelar Text Overwrite */
@if $manufactum {
  .heading.heading.heading {
    color: var(--color-headline-prio1-inverted);
  }

  .text.text.text.notContentRowLayout {
    color: var(--color-copy-normal-inverted);
  }
}
