.root {
  background-color: var(--grey-35);
  margin-bottom: var(--space-m);
  padding: var(--space-l);
}

.root label[for="freeText"] {
  white-space: normal;
}

.freeTextFormRow {
  padding-top: var(--space-xs);
}

.freeText {
  margin: var(--space-s) 0 var(--space-l);
}
