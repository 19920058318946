.appContent.fixed {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.smokedGlass[aria-hidden="true"] {
  opacity: 0;
}

.smokedGlass[aria-hidden="false"] {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.4s ease-out;
  z-index: 2000;
}

.overlayContent {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.overlayContent[aria-hidden="true"] {
  visibility: hidden;
}

.overlayContent[aria-hidden="false"] {
  visibility: visible;
  z-index: 2001;
}

.overlayWrapper[aria-hidden="true"] {
  transform: translateY(100vh);
}

.overlayWrapper[aria-hidden="false"] {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: contain;
  padding: var(--space-m);
  transition: transform 0.4s ease-out;
  width: 100%;
}
