.strong {
  color: var(--color-copy-strong);
  font-size: var(--font-size-copy-strong);
  font-weight: var(--font-weight-copy-strong);
  line-height: var(--line-height-copy-strong);
}

.strong.inverted {
  color: var(--color-copy-strong-inverted);
}

.outline {
  border: 1px solid var(--grey-50);
  padding: 5rem;
}

.em {
  color: var(--color-copy-em);
  font-size: var(--font-size-copy-em);
  font-weight: var(--font-weight-copy-em);
  line-height: var(--line-height-copy-em);
}

.em.inverted {
  color: var(--color-copy-em-inverted);
}

.normal {
  color: var(--color-copy-normal);
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-normal);
  line-height: var(--line-height-copy-normal);
}

.normal.inverted {
  color: var(--color-copy-normal-inverted);
}

.light {
  color: var(--color-copy-light);
  font-size: var(--font-size-copy-light);
  font-weight: var(--font-weight-copy-light);
  line-height: var(--line-height-copy-light);
}

.light.inverted {
  color: var(--color-copy-light-inverted);
}

.light.highContrast {
  color: var(--color-copy-light-highcontrast);
}

.small {
  color: var(--color-copy-small);
  font-size: var(--font-size-copy-small);
  font-weight: var(--font-weight-copy-small);
  line-height: var(--line-height-copy-small);
}

.small.inverted {
  color: var(--color-copy-small-inverted);
}

.small.highContrast {
  color: var(--color-copy-small-highcontrast);
}

.smallbold {
  color: var(--color-copy-small);
  font-size: var(--font-size-copy-small);
  font-weight: var(--font-weight-copy-em);
  line-height: var(--line-height-copy-small);
}

.smallbold.inverted {
  color: var(--color-copy-small-inverted);
}

.root a {
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
}

.root button[data-pure-button][data-prio1="true"] {
  text-decoration: underline;
}

.root a[data-prio2] {
  text-decoration: none;
}

.root a:hover,
.root button[data-pure-button]:hover {
  color: var(--main-100);
  text-decoration: underline;
}

.root a:active,
.root button[data-pure-button]:active {
  color: var(--main-75);
}

.root.inverted a:hover,
.root.inverted button[data-pure-button]:hover {
  color: var(--grey-50);
  text-decoration: underline;
}

.root.inverted a:active,
.root.inverted button[data-pure-button]:active {
  color: var(--secondary-50);
}

.skeleton {
  animation: loading 1.5s 3s infinite ease-in-out;
  background-position:
    -100% 0.1em,
    0 0.1em;
  background-repeat: no-repeat;
  background-size:
    50% 0.9em,
    100% 0.9em;
  color: transparent !important;
  display: inline-block;
  line-height: 1;
}

.skeleton.strong,
.skeleton.em {
  background-image:
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(var(--grey-75) 0.9em, transparent 0);
}

.skeleton.normal {
  background-image:
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(var(--grey-60) 0.9em, transparent 0);
}

.skeleton.light,
.skeleton.small {
  background-image:
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(var(--grey-50) 0.9em, transparent 0);
  margin: 0.5rem 0 0;
}

@keyframes loading {
  to {
    background-position:
      200% 0.1em,
      0 0.1em;
  }
}
