:global .miniBasketTrans-enter {
  max-height: 0;
  opacity: 0.01;
}

:global .miniBasketTrans-enter.miniBasketTrans-enter-active {
  max-height: calc(100vh - 13rem);
  opacity: 1;
  transition: all 300ms ease-in;
}

:global .miniBasketTrans-exit {
  max-height: calc(100vh - 13rem);
  opacity: 1;
}

:global .miniBasketTrans-exit.miniBasketTrans-exit-active {
  max-height: 0;
  opacity: 0.01;
  transition: all 200ms ease-in;
}

.root {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
  display: block;
  margin-top: 3px;
  overflow: hidden;
  position: absolute;
  right: 0;
  width: 35rem;
  z-index: 10;
}

@media (--up_to_XL) {
  .root {
    display: none;
  }
}

.content {
  padding: var(--space-l);
}

.cta {
  padding: var(--space-m) var(--space-s);
}

.SummaryTableContainer {
  background: var(--grey-35);
  display: flex;
  justify-content: flex-end;
  padding: var(--space-s);
  position: relative;
}

.SummaryTableContainer::before {
  background: linear-gradient(to top, rgba(150, 150, 150, 0.3), transparent);
  content: "";
  display: block;
  height: 0.7rem;
  left: 0;
  position: absolute;
  right: 0;
  top: -0.7rem;
}
