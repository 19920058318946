.spacing {
  @media (--L_to_XXL) {
    margin-bottom: calc(var(--space-xxxl) - 0.7rem);
    margin-top: -0.7rem;
  }

  @media (--XXL_and_up) {
    margin-bottom: calc(var(--space-xxxxl) - 0.7rem);
    margin-top: -0.7rem;
  }
}
