.selected {
  background: none;
  border: 0;
  padding: 1rem;
  text-align: left;
  white-space: nowrap;
  width: 100%;
}

.selected:focus-visible {
  border-color: var(--white-100);
  border-radius: var(--focus-border-radius);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
}

.flagIconContainer {
  display: inline-block;
  height: 2.2rem;
  margin-right: 1.5rem;
  vertical-align: middle;
  width: 2.7rem;
}

.selectedLink {
  display: inline-block;
  line-height: 2.2rem;
  user-select: none;
  vertical-align: middle;
}

.arrowIconContainer {
  display: inline-block;
  margin-left: 1rem;
  vertical-align: bottom;
}

/* Stelar Text Overwrite */
.text.text.text {
  @if $manufactum {
    color: var(--color-copy-normal-inverted);
  }
}
