.loading.noPadding {
  padding: 0;
}

.padNormal {
  width: 4.2rem;
}

.padLarge {
  width: 5.3rem;
}

.icon {
  padding-right: 2rem;
  text-align: right;
}

.icon.normal {
  margin-left: 1rem;
}

.icon.large {
  margin-left: 1.4rem;
}
