.root {
  @if $manufactum {
    padding-top: 17.037%; /* ratio 270:46 */
  }

  @if $magazin {
    padding-top: 16.3%;
  }

  position: relative;
  width: 100%;
}

.root svg {
  left: 0;
  position: absolute;
  top: 0;
}
