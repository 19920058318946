.list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.root:first-child .list {
  @media (--L_and_up) {
    border-width: 0;
    padding-top: 0;
  }
}
