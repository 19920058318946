.button {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button:hover {
  cursor: pointer;
}

.button.noOverlay:hover {
  cursor: default;
}

.overlay {
  text-align: center;

  /* margin: 0 var(--space-l); */

  /* min-height: 400px; */
}

.overlayImage {
  max-width: 100%;

  /* width: 300px; */
}

.energyContainer {
  margin: 0 auto;
}

.normal {
  height: 25px;
  width: 45px;
}

.large {
  height: 51px;
  width: 92px;
}

.energyContainer .cls-2,
.energyContainer .cls-6,
.energyContainer .cls-8 {
  fill: none;
}

.energyContainer .cls-2,
.energyContainer .cls-8 {
  stroke: #231f20;
  stroke-width: 0.82px;
}

.energyContainer .cls-2,
.energyContainer .cls-6 {
  stroke-linejoin: round;
}

.energyContainer .cls-3 {
  fill: #ffffff;
  font-size: 43.7px;
  stroke: #000000;
  stroke-width: 0.53px;
}

.energyContainer .cls-3.blackAndWhite {
  fill: #000000;
  stroke: none;
}

.energyContainer .cls-3,
.energyContainer .cls-5,
.energyContainer .cls-7 {
  font-family: Calibri-Bold, Calibri, sans-serif;
  font-weight: 700;
}

.energyContainer .cls-5 {
  fill: #231c1e;
  font-size: 22.16px;
}

.energyContainer .cls-6 {
  stroke: #1c1a00;
  stroke-linecap: round;
  stroke-width: 2.22px;
}

.energyContainer .cls-7 {
  font-size: 21.85px;
}
