.root {
  @if $manufactum {
    border-radius: 2.4rem;
    font-weight: var(--font-weight-copy-strong);
  }

  @if $magazin {
    border-radius: 0.5rem;
    font-weight: var(--font-weight-copy-em);
    text-transform: uppercase;
  }

  border: 0 none;
  cursor: pointer;
  display: inline-block;
  font-size: var(--font-size-copy-normal);
  margin: 0;
  text-align: center;
  vertical-align: middle;
}

@if $magazin {
  :global(.mf-fonts-not-loaded) .root,
  :global(.mf-fonts-critical) .root {
    font-weight: var(--font-weight-copy-bold);
  }
}

.root.large {
  height: 4.8rem;
}

a.order1:link,
a.order1:visited {
  color: var(--white-100);
}

a.root:link,
a.root:visited {
  text-decoration: none;
}

.center {
  flex-grow: 1;
  justify-content: center;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.prio3 .center {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  align-items: center;
  display: flex;
  height: 100%;
}

.disabled {
  cursor: not-allowed;
}

.prio1,
.prio2,
.prio4,
.prio5 {
  line-height: 1;
}

.prio1,
.prio2,
.prio3,
.prio4 {
  height: 3.8rem;
  padding: 0 var(--space-l);
}

.prio1,
.prio2,
.prio4 {
  width: 100%;
}

.prio3 {
  max-width: 100%;
}

.prio1,
a.prio1:link {
  background-color: var(--main-100);
  color: var(--white-100);
}

.prio1:hover,
a.prio1:hover {
  background-color: var(--main-75);
}

.prio1:active {
  background-color: var(--main-75);
}

.prio1.disabled {
  background-color: var(--grey-35);
  color: var(--grey-75);
}

.prio2.regular,
a.prio2.regular:link {
  background-color: var(--white-100);
  border: 1px solid var(--grey-75);
  color: var(--darkGrey-105);
}

.prio2.regular:hover,
a.prio2.regular:hover {
  background-color: var(--grey-75);
}

.prio2.regular:active {
  background-color: var(--grey-75);
}

.prio2.regular.disabled {
  background-color: var(--grey-35);
  border: none;
  color: var(--grey-75);
}

.prio2.inverted,
a.prio2.inverted:link {
  background-color: var(--main-100);
  border: 1px solid var(--white-100);
  color: var(--white-100);
}

.prio2.inverted:hover,
a.prio2.inverted:hover {
  background-color: var(--main-75);
}

.prio2.inverted:active {
  background-color: var(--main-75);
}

.prio2.inverted.disabled {
  border: 1px solid var(--grey-100);
  color: var(--grey-100);
}

.prio2.inverted.disabled:hover {
  background-color: var(--main-100);
}

.prio3.regular,
a.prio3.regular:link {
  background-color: var(--white-100);
  border: 1px solid var(--grey-75);
  color: var(--darkGrey-105);
}

.prio3.regular:hover,
a.prio3.regular:hover {
  background-color: var(--grey-75);
}

.prio3.regular:active {
  background-color: var(--grey-75);
}

.prio3.regular.disabled {
  background-color: var(--grey-35);
  border: none;
  color: var(--grey-75);
}

.prio3.inverted,
a.prio3.inverted:link {
  background-color: var(--main-100);
  border: 1px solid var(--white-100);
  color: var(--white-100);
}

.prio3.inverted:hover,
a.prio3.inverted:hover {
  background-color: var(--main-75);
}

.prio3.inverted:active {
  background-color: var(--main-75);
}

.prio3.inverted.disabled {
  border: 1px solid var(--grey-100);
  color: var(--grey-100);
}

.prio3.inverted.disabled:hover {
  background-color: var(--main-100);
}

.prio4,
a.prio4:link {
  background-color: var(--white-100);
  color: var(--black-100);
}

.prio4:hover,
a.prio4:hover {
  background-color: var(--grey-75);
}

.prio4:active {
  background-color: var(--grey-75);
}

.prio4.disabled {
  background-color: var(--grey-35);
  color: var(--grey-75);
}

.prio5 {
  border-radius: 0;
}

.prio5,
a.prio5:link {
  background-color: transparent;
  color: var(--grey-150);
  line-height: 1.25;
  padding: 0.2rem 0;
}

.prio5:hover:not(:focus-visible) > span > span,
a.prio5:hover:not(:focus-visible) > span > span {
  text-decoration: underline;
}

.prio5.disabled {
  color: var(--grey-50);
}

.prio5.disabled:hover > span > span {
  text-decoration: none;
}

.prio5.highContrast {
  color: var(--grey-200);
}

.skeleton {
  background-color: var(--grey-100);
  color: transparent;
  display: inline-block;
  font-family: inherit;
  max-width: 100%;
  white-space: nowrap;
}

.prio1.skeleton,
a.prio1.skeleton:link,
.prio2.skeleton,
a.prio2.skeleton:link,
.prio3.skeleton,
a.prio3.skeleton:link,
.prio4.skeleton,
a.prio4.skeleton:link,
.prio5.skeleton,
a.prio5.skeleton:link,
.prio1.regular.skeleton,
a.prio1.regular.skeleton:link,
.prio2.regular.skeleton,
a.prio2.regular.skeleton:link,
.prio3.regular.skeleton,
a.prio3.regular.skeleton:link,
.prio4.regular.skeleton,
a.prio4.regular.skeleton:link,
.prio5.regular.skeleton,
a.prio5.regular.skeleton:link {
  background-color: var(--grey-100);
}

button.prio2:focus-visible,
button.prio3:focus-visible,
button.prio4:focus-visible,
a.root.prio2:focus-visible,
a.root.prio3:focus-visible,
a.root.prio4:focus-visible {
  border-color: transparent;
  outline-offset: 0;
}

button.prio1:focus-visible,
button.prio2:focus-visible,
button.prio3:focus-visible,
button.prio4:focus-visible,
a.root.prio1:focus-visible,
a.root.prio2:focus-visible,
a.root.prio3:focus-visible,
a.root.prio4:focus-visible,
button.prio2.inverted:focus-visible,
button.prio3.inverted:focus-visible,
a.root.prio2.inverted:focus-visible,
a.root.prio3.inverted:focus-visible {
  @if $manufactum {
    border-radius: 2.4rem;
  }

  @if $magazin {
    border-radius: 0.5rem;
  }

  border-color: transparent;
  outline: var(--focus-width) solid var(--focus-color);
}

button.prio1:focus-visible,
a.root.prio1:focus-visible,
button.prio2.inverted:focus-visible,
a.root.prio2.inverted:focus-visible,
button.prio3.inverted:focus-visible,
a.root.prio3.inverted:focus-visible {
  background-color: var(--focus-background);
  color: var(--focus-text-color);
}

button.prio2.inverted:focus-visible,
button.prio3.inverted:focus-visible,
a.root.prio2.inverted:focus-visible,
a.root.prio3.inverted:focus-visible {
  outline-offset: calc(-2 * var(--focus-width));
}

button.prio5:focus-visible,
a.root.prio5:focus-visible {
  border-color: transparent;
  border-radius: var(--focus-border-radius);
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
  text-decoration: none;
}
