.root {
  border: thin solid var(--grey-75);
  display: inline-block;
  font-size: var(--font-size-input);
  padding: 1rem 0.7rem 0.7rem 1.5rem;
  width: 100%;
}

.root:disabled {
  background-color: var(--grey-50);
}
