.root {
  @media (--up_to_L) {
    padding: 2.5rem 1rem 1rem 1rem;
  }

  @media (--L_and_up) {
    padding: 2.5rem 1rem;
  }

  background-color: var(--grey-35);
  box-shadow: 0 -12px 10px -10px rgba(0, 0, 0, 0.4);
}

.cookie {
  @media (--up_to_M) {
    transform: translateX(-50%) translateY(-116%);
  }

  @media (--M_and_up) {
    transform: translateX(-50%) translateY(-95%);
  }

  left: 50%;
  position: absolute;
}

.root::before {
  @media (--up_to_M) {
    border-radius: 28px;
    clip: rect(-10px, 66px, 28px, -10px);
    height: 56px;
    margin-left: -28px;
    top: -28px;
    width: 56px;
  }

  @media (--M_and_up) {
    border-radius: 47px;
    clip: rect(-10px, 104px, 47px, -10px);
    height: 94px;
    margin-left: -47px;
    top: -47px;
    width: 94px;
  }

  background-color: inherit;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  content: "";
  display: block;
  left: 50%;
  position: absolute;
}

.text {
  @media (max-width: 767px) {
    height: 210px;
    margin: 0 auto 2.5rem;
  }

  @media (min-width: 768px) {
    @if $magazin {
      height: 160px;
    }

    @if $manufactum {
      height: 170px;
    }

    margin: 1rem auto 1.5rem;
    width: var(--grid-10-12);
  }

  max-width: 150rem;
  overflow-y: auto;
  padding: 0 var(--grid-gutter);
  text-align: center;
}

.text::before {
  @media (max-width: 767px) {
    background: -webkit-linear-gradient(
      rgba(255, 255, 255, 0) 200px,
      var(--grey-35)
    );
    background: linear-gradient(rgba(255, 255, 255, 0) 200px, var(--grey-35));
    max-height: 210px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    background: -webkit-linear-gradient(
      rgba(255, 255, 255, 0) 140px,
      var(--grey-35)
    );
    background: linear-gradient(rgba(255, 255, 255, 0) 140px, var(--grey-35));
    max-height: 170px;
  }

  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.paragraph {
  /* ----------- iPhone 4 and 4S ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 1.2rem !important;
    font-weight: lighter !important;
    line-height: 2.4rem !important;
  }

  /* Portrait */
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    font-size: 1.2rem !important;
    font-weight: normal !important;
    line-height: 2.4rem !important;
  }

  /* Landscape */
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    font-size: 1.2rem !important;
    font-weight: lighter !important;
    line-height: 2.4rem !important;
  }

  /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 1.2rem !important;
    font-weight: lighter !important;
    line-height: 2.4rem !important;
  }

  /* Portrait */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    font-size: 1.2rem !important;
    font-weight: lighter !important;
    line-height: 2.4rem !important;
  }

  /* Landscape */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    font-size: 1.2rem !important;
    font-weight: lighter !important;
    line-height: 2.4rem !important;
  }

  /* ----------- iPhone 6, 6S, 7 and 8 ----------- */

  /* Portrait and Landscape */
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 1.2rem !important;
    font-weight: lighter !important;
    line-height: 2.4rem !important;
  }

  /* Portrait */
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    font-size: 1.2rem !important;
    font-weight: lighter !important;
    line-height: 2.4rem !important;
  }

  /* Landscape */
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    font-size: 1.2rem !important;
    font-weight: lighter !important;
    line-height: 2.4rem !important;
  }

  /* ----------- iPhone 6+, 7+ and 8+ ----------- */

  /* Portrait */
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    font-size: 1.2rem !important;
    font-weight: bold !important;
    line-height: 2.4rem !important;
  }

  /* Landscape */
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    font-size: 1.2rem !important;
    font-weight: lighter !important;
    line-height: 2.4rem !important;
  }

  /* ----------- iPhone X ----------- */

  /* Portrait */
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    font-size: 1.2rem !important;
    font-weight: bold !important;
    line-height: 2.4rem !important;
  }

  /* Landscape */
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    font-size: 1.2rem !important;
    font-weight: normal !important;
    line-height: 2.4rem !important;
  }

  /* ----------- Non-Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    font-size: 1.2rem !important;
    font-weight: bold !important;
    line-height: 2.4rem !important;
  }

  /* ----------- Retina Screens ----------- */
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    font-size: 1.2rem !important;
    font-weight: bold !important;
    line-height: 2.4rem !important;
  }

  @media (min-width: 768px) {
    font-size: 1.8rem !important;
    font-weight: bold !important;
    line-height: 2.4rem !important;
  }

  color: var(--black-100) !important;
  text-align: center;
}

.cookieFooter {
  @media (--up_to_M) {
    padding: 0 var(--grid-gutter);
    width: var(--grid-12-12);
  }

  @media (--M_and_up) {
    width: 350px;
  }

  margin: 0 auto;
}

.withSettings {
  @media (--up_to_L) {
    flex-direction: column;
    gap: var(--space-xs);
  }

  @media (--L_and_up) {
    gap: var(--space-xl);
  }

  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.withSettings > * {
  @media (--up_to_L) {
    width: 100%;
  }
  @media (--L_and_up) {
    width: 28rem;
  }
}

.equalButtons {
  @media (--up_to_L) {
    margin: 0 auto var(--space-s);
    max-width: 150rem;
  }

  @media (--L_and_up) {
    align-items: center;
    display: flex;
    gap: var(--space-s);
    justify-content: space-evenly;
    margin: var(--space-l) auto 0;
    max-width: 150rem;
    width: var(--grid-10-12);
  }
}

.equalButtons > * {
  @media (--up_to_L) {
    width: 100%;
  }
  @media (--L_and_up) {
    width: 28rem;
  }
}

.headline {
  margin-bottom: 1rem !important;
}

.defaultButton {
  font-size: 1.5rem !important;
  margin: 0.2em 0.1em;
}

.confirmButtonContainer {
  margin: 1rem auto;
}

.confirmButton {
  font-size: 2rem !important;
}

.settingsButton {
  color: var(--black-100);
  font-size: 1.5rem;
  font-weight: 400;
}

button.settingsButton:focus-visible {
  @if $manufactum {
    border-radius: 2.4rem;
  }
  @if $magazin {
    border-radius: 0.5rem;
  }
}

.rejectButton {
  font-size: 1.8rem !important;
  font-weight: normal;
}

.buttonLinkContainer {
  display: flex;
  justify-content: space-between;
}

.link {
  @media (--up_to_L) {
    font-size: 1.2rem;
  }

  @media (--L_and_up) {
    font-size: 1.5rem;
  }

  background: transparent;
  border: 0;
  color: var(--black-100);
  cursor: pointer;
  font-weight: 400;
  line-height: 2rem;
  padding: 0;
  text-decoration: underline;
}

.link:focus-visible {
  border-color: transparent;
  border-radius: var(--focus-border-radius);
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
}

.bumper:focus {
  outline: none;
}
