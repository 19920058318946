.content {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* COMPACT */

.compact.root {
  background-color: var(--grey-35);
  border-bottom: 1px solid var(--color-divider-prio1);
  padding: 0 0 0 var(--space-xl);
}

.content li:last-child > a {
  border-bottom: none;
}

.compact .footer {
  display: none;
}

/* FLYOUT */

.flyout.root {
  @if $manufactum {
    background-color: var(--grey-35);
  }

  @if $magazin {
    background-color: var(--white-100);
  }

  min-width: var(--grid-8-12);
  position: relative;
  z-index: 11;
}

.flyout .content {
  @if $manufactum {
    display: flex;
    min-height: 23rem;
    padding: 0 var(--grid-gutter) var(--space-m);
  }

  @if $magazin {
    padding: 1.7rem 3.2rem 0.7rem 2.8rem;
  }

  animation: appear 0.3s;
}

@keyframes appear {
  from {
    opacity: 0.7;
  }

  to {
    opacity: 1;
  }
}

@if $manufactum {
  .flyout .footer {
    margin-left: var(--grid-10-12);
    padding: 0 var(--grid-outer-space) 0 var(--grid-gutter);
    width: var(--grid-2-12);
  }

  .flyout .footerInner {
    border-top: 1px solid var(--color-divider-prio1);
    padding: var(--space-s) 0;
    text-align: right;
  }
}

@if $magazin {
  .flyout .footer {
    display: none;
  }
}
