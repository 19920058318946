.root {
  align-items: center;
  display: flex;
  margin-bottom: var(--space-s);
  margin-top: var(--space-s);
}

.image {
  margin-right: var(--grid-gutter);
  min-width: calc(33% - var(--grid-gutter));
}

.text {
  margin-left: var(--grid-gutter);
  overflow: hidden;
}
