.root {
  @media (--up_to_L) {
    padding: 0.3rem 0;
  }

  @media (--L_and_up) {
    padding: 1rem 0;
  }

  background-color: var(--grey-50);
  color: var(--main-100);
  display: block;
  margin-bottom: 4rem;
}

.root:hover,
.root:active {
  cursor: pointer;
}

.root:hover span,
.root:active span {
  text-decoration: underline;
}

.root.root:focus-visible {
  border-radius: 0;
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-3 * var(--focus-offset));
}

.list {
  @media (--up_to_L) {
    display: block;
    padding: 0 0 0 1.5rem;
    text-align: left;
  }

  @media (--L_and_up) {
    display: flex;
    padding: 0;
  }

  justify-content: space-between;
  list-style: none;
  margin: 0;
}

.list::after,
.list::before {
  content: "";
  display: inline-block;
}

.item {
  white-space: nowrap;
}

@media (--up_to_L) {
  .item {
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
  }
}

.icon {
  display: inline-block;
  height: 2.8rem;
  margin-right: 1.5rem;
  vertical-align: top;
  width: 2.8rem;
}

.text {
  display: inline-block;
  line-height: 2.8rem;
}
