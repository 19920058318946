.root {
  margin: 0 auto var(--space-xs);
  max-width: var(--app-width);
}

.headline {
  margin-left: calc(var(--grid-gutter) + var(--grid-outer-space));
  margin-right: calc(var(--grid-gutter) + var(--grid-outer-space));
}

.columns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 var(--grid-outer-space);
}

.column {
  @media (--XL_and_up) {
    flex-basis: var(--grid-3-12);
    max-width: var(--grid-3-12);
  }

  @media (--L_to_XL) {
    flex-basis: var(--grid-6-12);
    max-width: var(--grid-6-12);
  }

  @media (--up_to_L) {
    flex-basis: var(--grid-12-12);
    max-width: var(--grid-12-12);
  }

  background-clip: padding-box;
  background-color: var(--grey-35);
  border-color: transparent;
  border-style: solid;
  border-width: 0 var(--grid-gutter);
  margin-bottom: var(--space-xl);
  padding: var(--space-xxl) var(--space-xxl) var(--space-s);
}
