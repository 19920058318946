.address {
  line-height: 1.5;
}

@media (--M_and_up) {
  .summary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .catalogs {
    padding-right: 2rem;
    width: 50%;
  }

  .address {
    width: 50%;
  }
}
