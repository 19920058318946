.root {
  @media (--up_to_XXXL) {
    padding: 0 2rem;
  }

  @media (--XXXL_and_up) {
    padding: 0;
  }

  color: var(--color-copy-small);
  display: block;
  font-size: var(--font-size-copy-small);
  line-height: var(--line-height-copy-small);
  list-style: none;
  margin: var(--space-m) auto;
  max-width: var(--app-width);
}

.bannerElement,
.element {
  display: flex;
}

.bannerElement::before,
.element::before {
  display: inline-block;
  padding-right: 0.5rem;
  text-align: right;
  width: 2rem;
}

.element:nth-child(1)::before {
  content: "*";
}

.element:nth-child(2)::before {
  content: "**";
}

.element:nth-child(3)::before {
  content: "***";
}

.bannerElement:nth-child(1)::before {
  content: "**";
}

.bannerElement:nth-child(2)::before {
  content: "***";
}

.bannerElement:nth-child(3)::before {
  content: "****";
}

.text {
  text-align: justify;
}
