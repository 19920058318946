.link {
  @if $magazin {
    text-transform: uppercase;
  }

  align-items: center;
  border-bottom: 1px solid var(--color-divider-prio2);
  color: var(--color-navigation-toplevel);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-navigation-toplevel);
  font-weight: var(--font-weight-navigation-toplevel);
  line-height: 1.3em;
  padding: var(--space-s) var(--space-xl) var(--space-s) var(--space-m);
  position: relative;
  text-decoration: none;
}

.link:focus-visible {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * calc(0.1rem + var(--focus-width)));
}

.highlighted .link {
  color: var(--color-active-navigation-toplevel);
}

.iconWrap {
  @if $magazin {
    border-radius: 0.5rem;
  }

  @if $manufactum {
    border-radius: 50%;
  }

  align-items: center;
  color: var(--main-100);
  display: flex;
  height: 3.2rem;
  justify-content: center;
  margin-right: var(--space-s);
  width: 3.2rem;
}

.root:hover .iconWrap {
  background-color: var(--grey-35);
}

.root:active .iconWrap {
  background-color: var(--main-100);
}

.root.highlighted .iconWrap {
  background-color: var(--main-100);
  color: var(--white-100);
}

.root.highlighted:hover .iconWrap {
  background-color: var(--main-75);
}

.root.highlighted:active .iconWrap {
  background-color: var(--grey-75);
}

.iconWrap path,
.iconWrap polygon,
.iconWrap ellipse {
  fill: currentColor; /* entspricht color: inherit */
}
