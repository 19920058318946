.large {
  height: 2.8rem;
}

.medium {
  height: 2.2rem;
}

.small {
  height: 1.8rem;
}

.long {
  width: 10.7rem !important;
}
