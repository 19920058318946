.root {
  display: flex;
  flex-wrap: wrap;
}

.teaser {
  @media (--up_to_M) {
    flex-basis: 100%;
    max-width: 100%;
  }

  align-items: stretch;
  display: flex;
  margin-bottom: var(--space-xxxl);
  padding-bottom: var(--space-s);
}

.teasers-one .teaser {
  @media (--M_and_up) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.teasers-two .teaser {
  @media (--M_and_up) {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.teasers-three .teaser {
  @media (--M_and_up) {
    flex-basis: 33.3%;
    max-width: 33.3%;
  }
}
