.item {
  position: relative;
}

.item,
.item span {
  line-height: var(--line-height-copy-normal);
}

.item span {
  display: block;
}
