.fullWidth {
  @media (--up_to_XXXL) {
    margin: 0 calc(var(--grid-outer-space) * -1) var(--space-xxxl);
  }

  @media (--XXXL_and_up) {
    margin-bottom: var(--space-xxxl);
    padding: 0 var(--grid-gutter);
  }
}

.indented {
  @media (--up_to_L) {
    margin: 0 calc(var(--grid-outer-space) * -1) var(--space-xxxl);
  }

  @media (--L_and_up) {
    margin-bottom: var(--space-xxxl);
    padding: 0 var(--grid-gutter);
  }
}
