.root {
  max-width: 100%;
  width: 680px;
}

.recommendations {
  margin-top: var(--space-xxxl);
}

.basketButtonIcon {
  @if $magazin {
    display: none;

    & + span {
      margin-left: 1rem;
    }
  }
}
