.root {
  max-width: 100%;
  min-width: 24rem;
}

.center {
  text-align: center;
}

.formCellOverride div div {
  @media (--L_and_up) {
    justify-content: flex-end;
  }
  align-items: center;
  display: flex !important;
}
.formCellOverride div div:first-of-type {
  @media (--up_to_L) {
    padding-bottom: 0.3rem;
  }
  padding-top: 0;
}
.formCellOverride div div button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
  padding: 0.5rem;
}
.modalOverride section {
  @media (--up_to_M) {
    width: 100%;
  }
  min-height: unset;
}
