.root {
  @media (--XL_and_up) {
    padding: 0 var(--grid-outer-space);
  }

  display: flex;
  flex-wrap: wrap;
}

.element {
  flex: 1;
}

.borders {
  @media (--XL_to_XXXL) {
    border-bottom: 1px solid var(--color-divider-prio2);
    margin-bottom: var(--space-xxl);
  }

  &::after {
    @media (--XXXL_and_up) {
      border-top: 1px solid var(--color-divider-prio2);
      content: "";
      display: block;
      flex-basis: 100%;
      margin: 0 var(--grid-gutter) var(--space-xxl);
      position: relative;
    }
  }
}

.borders.lastNoBorders {
  @media (--XL_and_up) {
    border-bottom: none;
  }

  &::after {
    border: none;
  }
}

.borders .element {
  @if $manufactum {
    @media (--up_to_XL) {
      border-bottom: 1px solid var(--color-divider-prio2);
      margin-bottom: var(--space-xxl);
      padding: 0 var(--grid-outer-space) var(--space-l);
    }

    @media (--XL_and_up) {
      padding-bottom: var(--space-xl);
    }
  }

  @if $magazin {
    margin-bottom: var(--space-xxl);
  }
}

.borders.lastNoBorders .element:last-child {
  @media (--up_to_XL) {
    border-bottom: none;
  }
}

.noBorders .element {
  margin-bottom: var(--space-xxl);
}

.elements_1 {
  flex-basis: var(--grid-12-12);
  max-width: var(--grid-12-12);
}

.elements_2,
.elements_1.forcedHalved {
  @media (--up_to_XL) {
    flex-basis: var(--grid-12-12);
    max-width: var(--grid-12-12);
  }

  @media (--XL_and_up) {
    flex-basis: var(--grid-6-12);
    max-width: var(--grid-6-12);
  }
}

.elements_1.forcedOneThirdTwoThird,
.elements_2.forcedOneThirdTwoThird {
  @media (--up_to_L) {
    flex-basis: var(--grid-12-12);
    max-width: var(--grid-12-12);

    &:nth-child(0n + 1) {
      border: none;
    }
  }

  @media (--L_to_XL) {
    flex-basis: var(--grid-6-12);
    max-width: var(--grid-6-12);
  }

  @media (--XL_and_up) {
    &:nth-child(0n + 1) {
      flex-basis: var(--grid-4-12);
      max-width: var(--grid-4-12);
    }

    &:nth-child(0n + 2) {
      flex-basis: var(--grid-8-12);
      max-width: var(--grid-8-12);
    }
  }
}
