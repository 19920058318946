.root {
  backface-visibility: hidden;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 5;
}

.root.mainImageDetail {
  @media (--up_to_L) {
    left: 0.5rem;
  }

  @media (--L_to_XXL) {
    left: calc(10% + 1.3rem);
  }

  @media (--XXL_to_XXXL) {
    left: calc(10% + 1.3rem);
  }

  @media (--XXXL_and_up) {
    left: calc(10% + 1rem);
  }
}

.root.commonInfo {
  position: relative;
}

.flag {
  display: flex;
  margin-bottom: var(--space-xs);
}

.bottom {
  bottom: calc(var(--space-s) + 3%);
  position: absolute;
  right: 8%;
}
