/* repetition to increase specificity against copy text definition of `.AppRoot_root *` */
.root {
  text-align: center;
}

.margin {
  @media (--up_to_L) {
    margin-bottom: calc(var(--space-xxl) - 0.8rem);
  }

  @media (--L_and_up) {
    margin-bottom: calc(var(--space-xxxl) - 0.8rem);
  }
}
