.root {
  display: flex;
  font-size: 1.6rem;
  position: relative;
}

.input {
  @if $manufactum {
    border-radius: 2.4rem;
  }

  @if $magazin {
    border-radius: 0.5rem;
  }

  -webkit-appearance: none;
  border: 1px solid var(--grey-75);
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.07);
  color: var(--black-100);
  display: inline-block;
  flex: 1;
  font-size: inherit;
  height: 4.8rem;
  margin-right: 0;
  min-width: 0;
  overflow: hidden;
  padding: 0 5rem 0 var(--space-l);
  text-overflow: ellipsis;
  vertical-align: top;
}

input.input:focus-visible {
  @if $manufactum {
    border-radius: 2.4rem;
  }

  @if $magazin {
    border-radius: 0.5rem;
  }
  border-color: var(--grey-75);
  outline: var(--focus-width) solid var(--main-100);
  outline-offset: 0;
}

.input::placeholder {
  color: var(--grey-150);
  overflow: hidden;
  text-overflow: ellipsis;
}

.input::-ms-clear {
  display: none;
}

.label {
  left: -9999em;
  position: absolute;
}

.submitButton {
  @if $manufactum {
    border-radius: 2.3rem;
  }

  @if $magazin {
    border-radius: 0.3rem;
  }

  background: none;
  border: 0 none;
  color: var(--grey-150);
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: var(--font-weight-copy-normal);
  height: 4.4rem;
  margin: 0.2rem;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 4.4rem;
}

.input:focus + .submitButton {
  background-color: var(--grey-75);
  color: var(--black-100);
}

.submitButton.calltoaction,
.input + .submitButton:hover,
.input:focus + .submitButton.calltoaction {
  background-color: var(--main-100);
  color: var(--white-100);
  transition: background-color 0.3s;
}

.input + .submitButton:active {
  background-color: var(--main-200);
}

.icon {
  display: block;
  margin: 0 auto;
  padding-left: 0.2rem;
  width: 2rem;
}

.clearButton {
  background: none;
  border: none;
  color: var(--grey-150);
  cursor: pointer;
  display: inline-block;
  height: 4.8rem;
  padding: 0;
  position: absolute;
  right: 5rem;
  width: 4.8rem;
}

.submitButton:focus-visible,
.clearButton:focus-visible {
  @if $manufactum {
    border-radius: 2.4rem;
  }

  @if $magazin {
    border-radius: 0.5rem;
  }
  border-color: var(--white-100);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-offset));
}

.clearButton:focus-visible {
  outline-offset: calc(-3 * var(--focus-offset));
}
