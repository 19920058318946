.root {
  position: relative;
  right: 0;
  top: 0;
}

.input,
.screenreaderOnly {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.labelText {
  margin-right: var(--space-s);
}

.label {
  align-items: center;
  display: inline-flex;
  padding: 0.6rem 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.input:disabled + .label {
  cursor: default;
}

.label:before {
  background-color: var(--white-100);
  border-radius: 50%;
  content: "";
  cursor: pointer;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 10px;
  transition:
    background-color 0.1s ease-out,
    transform 0.1s ease-out;
  width: 24px;
}

.input:checked + .label:before {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36"><path fill="%23007f46" d="M30.8 8.2c-.3.4-.3.3-.6.8-3.2 4.1-6.3 8.2-9.5 12.2-2.1 2.6-4.1 5.3-6.2 7.9-.2.3-.6.6-1 .6s-.9-.4-1.2-.8c-2.3-2.7-4.6-5.5-7-8.2-.4-.5-.5-1-.2-1.5.4-.5.9-.7 1.5-.5 2.1.8 4.2 1.5 6.3 2.3.3.1.4 0 .6-.1 3.5-3.3 7.1-6.5 10.6-9.8 1.6-1.5 3.3-3 4.9-4.6.7-.7 1.5-.5 1.9-.2.3.6.4 1.2-.1 1.9z"></path></svg>');
  background-position-x: center;
  background-position-y: 3px;
  background-repeat: no-repeat;
  transform: translate(16px);
}

.input:disabled + .label:before {
  cursor: default;
}

.input:checked:disabled + .label:before {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36"><path fill="%2359c092" d="M30.8 8.2c-.3.4-.3.3-.6.8-3.2 4.1-6.3 8.2-9.5 12.2-2.1 2.6-4.1 5.3-6.2 7.9-.2.3-.6.6-1 .6s-.9-.4-1.2-.8c-2.3-2.7-4.6-5.5-7-8.2-.4-.5-.5-1-.2-1.5.4-.5.9-.7 1.5-.5 2.1.8 4.2 1.5 6.3 2.3.3.1.4 0 .6-.1 3.5-3.3 7.1-6.5 10.6-9.8 1.6-1.5 3.3-3 4.9-4.6.7-.7 1.5-.5 1.9-.2.3.6.4 1.2-.1 1.9z"></path></svg>');
}

.label:after {
  background-color: var(--grey-100);
  border-radius: 16px;
  content: "";
  cursor: pointer;
  height: 32px;
  min-width: 48px;
  transition: background-color 0.1s ease-out;
}

.input:checked + .label:after {
  background-color: #39a75c;
}

.input:focus-visible + .label:after {
  border-radius: 16px;
  outline: var(--focus-width) auto var(--focus-color);
  outline-offset: var(--focus-offset);
}

.input:disabled + .label:after {
  background-color: var(--grey-50);
  cursor: default;
}

.input:checked:disabled + .label:after {
  background-color: #b4e6c4;
}

.input:not(:checked):not(:disabled) + .label:hover:after {
  background-color: var(--grey-150);
}

.input:checked:not(:disabled) + .label:hover:after {
  background-color: var(--success-100);
}
