.root {
  margin-bottom: 0.2rem;
  position: relative;
}

.visuallyHidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.root.light {
  border-top: thin solid var(--color-divider-prio2);
  margin-bottom: var(--focus-width);
}

.header {
  background-color: var(--grey-50);
  color: var(--grey-150);
  cursor: pointer;
  display: flex;
  padding: 1.2rem 0 1rem;
  user-select: none;
  width: 100%;
}

.light .header {
  padding: calc(var(--space-xl) - 0.8rem) 0;
}

.light.open .header {
  padding: calc(var(--space-xl) - 0.8rem) 0 calc(var(--space-xl) - 1.2rem) 0;
}

.header h3 {
  flex: 1;
}

.header span + h3 {
  margin-left: 4.5rem;
}

.title {
  color: var(--color-headline-prio2-highcontrast);
  display: inline-block;
  font-family: var(--font-family-headline);
  font-size: var(--font-size-headline-prio2);
  line-height: var(--line-height-headline-prio2);
}

.skeleton {
  animation: loading 1.5s 3s infinite ease-in-out;
  background-image:
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(var(--grey-75) 0.9em, transparent 0);
  background-position:
    -100% 0.1em,
    0 0.1em;
  background-repeat: no-repeat;
  background-size:
    50% 0.9em,
    100% 0.9em;
  color: transparent !important;
  display: inline-block;
  line-height: 1;
}

@keyframes loading {
  to {
    background-position:
      200% 0.1em,
      0 0.1em;
  }
}

.icon-right .title {
  margin-left: var(--space-m);
}

.light .title {
  color: var(--grey-150);
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-normal);
  line-height: var(--line-height-copy-normal);
}

.icon {
  align-self: flex-start;
  margin-top: var(--space-xs);
  vertical-align: text-bottom;
}

.icon-right .icon {
  min-width: 5rem;
}

.icon-right.light .icon {
  min-width: 2rem;
}

.icon-right .title {
  flex: 1;
}

:global(.mf-fonts-not-loaded) .title {
  font-family: var(--font-family-headline-not-loaded);
}

.header.open .title {
  color: var(--color-headline-prio2-inverted);
}

.subtitle {
  color: var(--color-copy-normal);
  display: block;
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-normal);
  line-height: var(--line-height-copy-normal);
  margin: 0.2rem 0 0 0;
}

.subtitle.open {
  color: var(--white-100);
}

.light .subtitle.open {
  color: var(--color-copy-normal);
}

.header.open {
  background-color: var(--main-100);
}

.header.disabled,
.header.open.disabled {
  background-color: var(--grey-50);
  color: var(--grey-100);
}

.label {
  cursor: pointer;
  display: block;
  padding: 1.2rem 0 1.2rem 2.6rem;
  width: 100%;
}

.iconContainer {
  color: var(--main-100);
  margin: 0 var(--space-m);
  position: absolute;
}

.iconContainer.disabled {
  color: transparent;
}

.open .iconContainer {
  color: var(--white-100);
}

:global(.mf-noscript) .iconContainer {
  display: none;
}

.slot {
  align-items: center;
  align-self: center;
  display: flex;
  height: 0;
  margin-left: 1rem;
  z-index: 1;
}

.content,
.content.open.disabled {
  display: none;
  padding: var(--space-xl) var(--grid-1-12);
  position: relative;
  z-index: 1;
}

.light .content,
.light .content.open.disabled {
  padding: 0;
}

.content.open {
  display: block;
}

:global(.mf-noscript) .content,
:global(.mf-noscript) .content.open.disabled {
  animation: avoidFlash;
  animation-duration: 1s;
  display: block;
  overflow: hidden;
}

:global(.mf-noscript) .content.open {
  animation: none;
}

@media (--XL_and_up) {
  :global(.mf-noscript) .smallAccordionItem .content {
    animation: none;
  }
}

@keyframes avoidFlash {
  from {
    height: 0;
    padding: 0;
  }

  to {
    height: 0;
    padding: 0;
  }
}

.root:last-child .content.open {
  border-bottom: 1px solid var(--color-divider-prio2);
}

.root.light:last-child .content.open {
  border-bottom: none;
}

.root.light:last-child {
  border-bottom: thin solid var(--color-divider-prio2);
}

@media (--XL_and_up) {
  .root.smallAccordionItem {
    background-color: var(--grey-50);
    flex: 1;
    margin: 0 var(--grid-gutter) 0.2rem var(--grid-gutter);
  }

  .root.smallAccordionItem:first-child {
    margin-left: 0;
  }

  .root.smallAccordionItem:last-child {
    margin-right: 0;
  }

  .smallAccordionItem .header,
  .smallAccordionItem .header.open {
    background-color: var(--grey-50);
    cursor: default;
    margin-bottom: 0;
    margin-top: 3rem;
    padding: var(--space-l) var(--space-xxl);
  }

  .smallAccordionItem .header h3 {
    margin-left: 0;
  }

  .smallAccordionItem .title {
    display: block;
    margin: 0 0 var(--space-xl) 0;
    text-align: center;
  }

  .smallAccordionItem .header.open .title {
    color: var(--main-100);
  }

  .smallAccordionItem .subtitle {
    border-bottom: 1px solid var(--color-divider-prio2);
    display: block;
    margin: 0;
    padding-bottom: var(--space-l);
  }

  .smallAccordionItem .subtitle.open {
    color: var(--black-100);
  }

  .smallAccordionItem .content,
  :global(.mf-noscript) .smallAccordionItem .content {
    display: block;
    margin-bottom: var(--space-m);
    padding: 0 var(--space-xxl) var(--space-xl);
  }

  .smallAccordionItem .iconContainer {
    display: none;
  }

  .root.smallAccordionItem:last-child .content.open {
    border-bottom: none;
  }
}

.light .header.disabled,
.light .header.open.disabled {
  background-color: var(--white-100);
}

.light .header {
  background-color: var(--white-100);
  color: var(--grey-150);
}

/* stylelint-disable-next-line */
.light .title,
.light .open .title {
  color: var(--grey-150);
  margin-left: var(--space-xs);
}

.icon-right.light .title,
.icon-right.light .open .title {
  margin-left: 0;
}

.light .iconContainer {
  color: var(--darkGrey-105);
  margin: 0 var(--space-m) 0 0;
}

.root input[type="radio"] + h3 .label {
  padding: 1.2rem 0 1.2rem 5.1rem;
}

.root input[type="radio"] + h3 .icon {
  margin-left: var(--space-m);
}

.root input[type="radio"]:checked + h3,
.root input[type="radio"]:not(:checked) + h3 {
  cursor: pointer;
  display: inline-block;
  line-height: 2rem;
  padding: 0;
  position: relative;
}

.root input[type="radio"]:checked + h3::before,
.root input[type="radio"]:not(:checked) + h3::before {
  background: #ffffff;
  border: 1px solid var(--black-100);
  border-radius: 100%;
  content: "";
  height: 16px;
  margin: 1.6rem 2rem 0;
  pointer-events: none;
  position: absolute;
  width: 16px;
}

.root input[type="radio"]:checked + h3::after,
.root input[type="radio"]:not(:checked) + h3::after {
  background: var(--black-100);
  border-radius: 100%;
  content: "";
  height: 8px;
  margin-left: 2.4rem;
  margin-top: 2rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 8px;
}

.root input[type="radio"]:not(:checked) + h3::after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.root input[type="radio"]:checked + h3::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.headerButton:not(.small):focus-visible + .header {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-2 * var(--focus-width));
}

.headerButton:not(.small).open:focus-visible + .header {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color-inverted);
  outline-offset: calc(-2 * var(--focus-width));
}

.headerButton.light:focus-visible + .header,
.headerButton.light.open:focus-visible + .header {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: 0;
}
