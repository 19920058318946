.root {
  color: var(--color-copy-normal);
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-normal);
  line-height: var(--line-height-copy-normal);
  margin: 0 0 var(--space-l);
}

.root.inverted {
  color: var(--color-copy-normal-inverted);
}

.root a {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.root a[data-prio2] {
  text-decoration: none;
}

.root a:hover {
  color: var(--main-100);
  text-decoration: underline;
}

.root a:active {
  color: var(--main-75);
}

.root.inverted a:hover {
  color: var(--grey-60);
  text-decoration: underline;
}

.root.inverted a:active {
  color: var(--secondary-50);
}

.root strong,
.root b {
  font-weight: var(--font-weight-copy-semibold);
}

.root.light {
  color: var(--color-copy-light);
  font-size: var(--font-size-copy-light);
  font-weight: var(--font-weight-copy-light);
  line-height: var(--line-height-copy-light);
}

.root.light.inverted {
  color: var(--color-copy-light-inverted);
}

.root.small {
  color: var(--color-copy-small);
  font-size: var(--font-size-copy-small);
  font-weight: var(--font-weight-copy-small);
  line-height: var(--line-height-copy-small);
}

.root.small.inverted {
  color: var(--color-copy-small-inverted);
}

.root.skeleton small,
.root.skeleton span {
  background-image: linear-gradient(
    var(--white-100) 12%,
    var(--grey-75) 12%,
    var(--grey-75) 50%,
    var(--white-100) 50%
  );
  background-size: 100% 31px;
  color: transparent;
}

.root.collapseCheckbox {
  display: none;
}

p.root.collapsibleText {
  margin-bottom: 0;
  max-height: 500px;
  overflow: hidden;
  transition: max-height 1.5s ease-in;
}

p.root.collapsibleText + label {
  display: block;
  text-align: right;
}

p.root.collapsibleText + label > span {
  font-size: var(--font-size-copy-small);
  font-weight: var(--font-weight-copy-small);
  line-height: var(--line-height-copy-small);
}

.root.expandText {
  @if $manufactum {
    color: var(--grey-150);
  }

  display: none;
}

.root.collapseText {
  @if $manufactum {
    color: var(--grey-150);
  }
}

.root.collapseCheckbox:checked ~ label > .root.expandText {
  display: inline;
}

.root.collapseCheckbox:checked ~ label > .root.collapseText {
  display: none;
}

.root.collapseCheckbox:checked ~ p.root.collapsibleText {
  max-height: var(--line-height-copy-small);
  transition: max-height 0.5s ease-out;
}
