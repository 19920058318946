.compact {
  width: 100%;
}

.fluid {
  @media (--up_to_L) {
    display: block;
  }

  @media (--L_and_up) {
    display: table;
  }

  width: 100%;
}
