.root {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--space-xxxl);
  width: 100%;
}

.imageSection {
  display: block;
  margin-bottom: var(--space-m);
  padding: 0 var(--grid-gutter);
}

.fullWidth .imageSection {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_to_L) {
    margin-left: 10%;
    width: 80%;
  }

  @media (--L_to_XXL) {
    margin-left: calc(100% / 8);
    width: calc(100% / 8 * 6);
  }

  @media (--XXL_and_up) {
    width: 50%;
  }
}

.indented .imageSection {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_to_L) {
    margin-left: 10%;
    width: 80%;
  }

  @media (--L_to_XXL) {
    margin-left: calc(100% / 7);
    width: calc(100% / 7 * 5);
  }

  @media (--XXL_and_up) {
    width: 50%;
  }
}

.right .imageSection {
  @media (--XXL_and_up) {
    order: 2;
  }
}

.textSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: var(--space-m);
  width: 50%;
}

.fullWidth .textSection {
  @media (--up_to_XXL) {
    width: 100%;
  }

  @media (--XXL_and_up) {
    width: 50%;
  }
}

.indented .textSection {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_to_XXL) {
    width: 100%;
  }

  @media (--XXL_and_up) {
    width: 50%;
  }
}

.right .textSection {
  @media (--XXL_and_up) {
    order: 1;
  }
}

.title {
  margin-bottom: var(--space-m);
  padding: 0 var(--grid-gutter);
  text-align: center;
}

.text {
  padding: 0 var(--grid-gutter);
}

.button {
  padding: 0 var(--grid-gutter);
}

.fullWidth .button {
  @media (--up_to_M) {
    margin: 0 var(--grid-2-12);
  }

  @media (--M_to_L) {
    margin: 0 20%;
  }

  @media (--L_to_XL) {
    margin: 0 calc(100% / 8 * 2);
  }

  @media (--XL_and_up) {
    margin: 0 25%;
  }
}

.indented .button {
  @media (--up_to_M) {
    margin: 0 var(--grid-2-12);
  }

  @media (--M_to_L) {
    margin: 0 20%;
  }

  @media (--L_to_XXL) {
    margin: 0 calc(100% / 7 * 2);
  }

  @media (--XXL_and_up) {
    margin: 0 25%;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-l);
}

.eelContainer {
  margin-top: 6px;
}
