.root {
  align-items: first baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  width: 100%;
}

.showScrollbars {
  overflow-y: scroll;
}

.label.collapsible {
  @media (--up_to_L) {
    max-height: calc(2 * var(--line-height-copy-normal));
  }
  @media (--L_and_up) {
    max-height: var(--line-height-copy-normal);
  }

  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.collapseCheckbox {
  display: none;
}

.collapseCheckbox ~ .collapseText,
.collapseCheckbox ~ .expandText {
  align-self: self-end;
  cursor: pointer;
  display: flex;
  font-size: 90%;
  margin-left: auto;
  text-decoration: underline;
  width: auto;
}

.flexBreak {
  flex-basis: 100%;
  height: 0;
  margin: 0;
}

.collapseCheckbox:checked ~ label.collapsible {
  max-height: 500px;
  transition: max-height 1.5s ease-in;
}

div.collapsible.autoexpand input.input:checked ~ label.collapseText,
div.collapsible.autoexpand input.input:checked ~ label.expandText {
  display: none;
}

div.collapsible.autoexpand .input:checked ~ label.collapsible {
  display: block;
  max-height: 500px;
  transition: max-height 1.5s ease-in;
}

.collapseCheckbox ~ .collapseText {
  display: none;
}

.collapseCheckbox:checked ~ .collapseText {
  display: flex;
}

.collapseCheckbox:checked ~ .expandText {
  display: none;
}

.input {
  height: 1.5rem;
  position: relative;
  top: 0.1rem;
  width: 1.5rem;
}

.input.errorneous {
  box-shadow: 0 0 0.5rem 0.1rem var(--error-100);
}

.input.errorneous:focus-visible {
  outline-color: var(--error-100);
}

.label {
  display: inline-block;
  margin-left: 0.4rem;
  max-width: calc(100% - 3rem);
  padding: var(--focus-width);
}

.label a,
.label a:link,
.label a:visited {
  color: inherit;
  cursor: pointer;
}
