.identifier {
  display: block;
  height: 2.2rem;
}

.identifier.myAccount {
  @media (--up_to_M) {
    display: block;
  }

  @media (--M_and_up) {
    display: none;
  }
}

.identifier.wishlist {
  @media (--up_to_M) {
    display: block;
  }

  @media (--M_and_up) {
    display: none;
  }
}
