.root {
  list-style: none;
  margin: 0;
  padding: 0;
}

@if $manufactum {
  .compact.root {
    border-bottom: 1px solid var(--color-divider-prio1);
    padding: 0 0 0 var(--space-s);
  }

  .compact.root > :last-child a {
    border-bottom: none;
  }

  .flyout.root {
    display: block;
  }
}

@if $magazin {
  .root {
    display: none;
  }
}
