.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: var(--white-100) url("./select_arrow.svg") no-repeat
    calc(100% - 1.2rem) center;
  background-size: 1.5rem;
  border: thin solid var(--grey-75);
  border-radius: 0;
  color: var(--black-100);
  display: inline-block;
  font-size: var(--font-size-input);
  font-weight: var(--font-weight-copy-normal);
  height: 3.8rem;
  padding: 0 3rem 0 1.5rem;
  width: 100%;
}

.select.error {
  background-color: var(--error-50);
  border: thin solid var(--error-100);
}

.select.error:focus-visible {
  border-color: transparent;
  outline-color: var(--error-100);
}

.textContainer {
  padding-left: var(--space-m);
}

.text.text {
  line-height: normal;
}

.root:disabled {
  background-color: var(--grey-50);
}

.dateMonth {
  width: 29%;
}

.skeleton {
  background-color: white;
  background-image:
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 25pt,
      transparent 25pt,
      transparent 80%,
      rgba(255, 255, 255, 1) 80%
    ),
    linear-gradient(var(--grey-75) 100%, var(--grey-75) 100%);
  background-repeat-y: no-repeat;
  background-size: 100% 15px;
  color: transparent;
}
