.root {
  @media (--up_to_XL) {
    padding: var(--space-xs) var(--space-l) 0 var(--space-l);
  }

  @media (--XL_and_up) {
    padding: var(--space-l) var(--space-xxxl) 0 var(--space-xxxl);
  }

  background-color: var(--grey-35);
  width: 100%;
}

.spacing {
  margin-bottom: var(--space-xxl);
}
