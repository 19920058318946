.root.orderform {
  border-bottom: 1px solid var(--color-divider-prio2);
  color: inherit;
  font-size: inherit;
  margin-bottom: var(--space-m);
  text-decoration: none;
}

.root.compact {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: var(--space-xs);
}

.image {
  align-items: center;
  display: flex;
}

.image.orderform {
  margin-bottom: var(--space-m);
}

.label {
  width: 12.5rem;
}

.pdfLink.orderform {
  margin-bottom: var(--space-m);
}

.pdfLink.compact {
  margin-left: var(--space-xs);
}

.pdfHref {
  color: var(--grey-150);
}
