.root {
  @media (--up_to_M) {
    width: 100%;
  }

  @media (--M_and_up) {
    width: 50%;
  }

  display: inline-block;
  margin-bottom: var(--space-l);
}
