.wrap {
  color: transparent;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.preview,
.image {
  display: block;
  max-width: 100%;
  width: 100%;
}

.hasLoaded .preview {
  visibility: hidden;
}

.withRatio .preview,
.image {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* Hide large ratio when small ratio is present */
@media (--up_to_M) {
  .artDirected .ratio.small ~ .ratio.large {
    display: none;
  }
}

/* Hide large ratio when medium ratio is present */
@media (--M_to_L) {
  .artDirected .ratio.medium ~ .ratio.large {
    display: none;
  }
}

.ratio.small {
  display: none;
}

@media (--up_to_M) {
  .artDirected .ratio.small {
    display: block;
  }
}

.ratio.medium {
  display: none;
}

@media (--M_to_L) {
  .artDirected .ratio.medium {
    display: block;
  }
}

:not(.hasLoaded) .preview {
  filter: blur(15px);
}

.image {
  color: transparent !important; /* hide alt text */
  text-decoration: none;
}

.skeleton {
  background-color: var(--grey-35);
}

.skeleton img {
  display: none;
}
