.root {
  @media (min-width: 651px) {
    overflow-x: visible;
  }

  margin-bottom: var(--space-xxxl);
}

.cta {
  padding-bottom: var(--space-xl);
  text-align: center;
}
