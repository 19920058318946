.buttonWrapper {
  position: absolute;
  transform: translateY(-50%);
  z-index: 2;
}

.buttonWrapper.centerForSquareItems {
  @media (--up_to_M) {
    top: calc(25vw - 0.5rem);
  }

  @media (--M_to_L) {
    top: calc(16.5vw - 0.5rem);
  }

  @media (--L_to_XL) {
    top: calc(12.5vw - 0.5rem);
  }

  @media (--XL_to_XXL) {
    top: calc(10vw - 0.5rem);
  }

  @media (--XXL_to_XXXL) {
    top: calc(8.3333vw - 0.5rem);
  }

  @media (--XXXL_and_up) {
    top: calc(var(--app-width) / 12 - 0.5rem);
  }
}

.buttonWrapper.centerForSquareItemsOverlay {
  @media (--up_to_M) {
    top: calc(25vw - 1.5rem);
  }

  @media (--M_to_L) {
    top: calc(12.5vw - 1rem);
  }

  @media (--L_and_up) {
    top: calc(75rem / 8 - 1rem);
  }
}

.buttonWrapper.centerAbsolute {
  top: 50%;
}

.buttonWrapper.prev {
  left: calc((1rem - 1px) * -1);
}

.buttonWrapper.next {
  right: calc((1rem - 1px) * -1);
}
