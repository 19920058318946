.root {
  @if $manufactum {
    border-top: 1px solid var(--main-75);
  }

  @if $magazin {
    border-top: 3px solid var(--main-100);
  }

  background-color: var(--grey-35);
  color: var(--black-100);
  padding-top: var(--space-m);
}

.appLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0 0 var(--space-m) 0;
}

.appLinks li {
  display: inline-block;
  padding: 0 var(--space-s);
}

.appLinks li:not(:last-child) {
  border-right: 1px solid black;
}

.company {
  @media (--up_to_L) {
    flex-basis: var(--grid-12-12);
    order: 6;
  }

  @media (--L_to_XL) {
    break-inside: avoid;
    width: 100%;
  }

  @media (--XL_and_up) {
    flex-basis: var(--grid-3-12);
    max-width: var(--grid-3-12);
    order: 6;
  }

  padding: 0 var(--grid-gutter) 2rem var(--grid-gutter);
}

:global .footer {
  -webkit-transition: margin-bottom 0.3s linear;
  -moz-transition: margin-bottom 0.3s linear;
  -o-transition: margin-bottom 0.3s linear;
  transition: margin-bottom 0.3s linear;
}

:global .cookieBannerMargin {
  margin-bottom: 420px;
}
