:global .smv-arrow-control .smv-arrow .smv-icon {
  @if $manufactum {
    background-color: #017447;
  }

  @if $magazin {
    background-color: #333333;
  }

  max-height: 75px;
}

:global .smv-thumbnails.smv-v .smv-item.smv-active .smv-selector {
  @if $manufactum {
    border-right: 2px solid #017447;
  }

  @if $magazin {
    border-right: 2px solid #333333;
  }

  padding: 5px;
}

:global .smv-thumbnails.smv-h .smv-item.smv-active .smv-selector {
  @if $manufactum {
    border-top: 2px solid #017447;
  }

  @if $magazin {
    border-top: 2px solid #333333;
  }

  padding: 5px;
}

:global .smv-selectors-box {
  @media (--M_to_L) {
    width: 350px;
  }

  @media (--L_to_XL) {
    width: 450px;
  }

  @media (--XL_to_XXL) {
    width: 650px;
  }

  z-index: 6;
}

:global .smv-selectors-box.smv-thumbnails.smv-h .smv-item {
  padding: 10px 0 10px 0;
}

:global .smv-component-loader {
  display: none;
}

:global .smv-selectors-box.smv-thumbnails {
  @media (--XXL_and_up) {
    border-right: 1px solid var(--grey-60);
  }
}

:global .smv-item {
  cursor: pointer;
  opacity: 0.4;
}

:global .smv-item:hover {
  -moz-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -webkit-transition: 0.5s;
}

:global .smv-item.smv-active {
  opacity: 1;
}

:global .smv-controls > .smv-button.smv-button-fullscreen {
  height: 48px;
  padding: 10px;
  width: 48px;
}

/* ie11 settings for sirv viewer */

:global .mf-ie11 .smv-button-fullscreen {
  /* display: none; */
}

:global .mf-ie11 .smv-arrow-control .smv-arrow .smv-icon {
  background-color: #ffffff;
}

/* mobile settings for sirv viewer */

:global .smv-mobile .smv-selectors-box {
  position: none !important;
}

/* :global .smv-mobile .smv-slides-box {
  position: static !important;
} */

:global .smv-mobile > .smv-selectors-box.smv-thumbnails {
  background-color: #ffffff;
  z-index: 99;
}

:global .smv-mobile .smv-thumbnails .smv-item.smv-active .smv-selector {
  @if $manufactum {
    border-top: 2px solid #017447;
  }

  @if $magazin {
    border-top: 2px solid #333333;
  }

  border-right: initial;
  padding: 5px;
}

:global .smv div.smv-button {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: none;
}

:global
  .Sirv.mainimage
  > .smv-mobile
  > .smv-slides-box
  > .smv-slides
  > .smv-shown
  > .smv-content.smv-content-video,
:global
  .Sirv.mainimage
  > .smv-mobile
  > .smv-slides-box
  > .smv-slides
  > .smv-shown
  > .smv-content.smv-content-zoom.smv-can-zoom {
  @media (--XXL_and_up) {
    box-sizing: border-box;
    height: auto;
    padding-right: calc((100% - 700px) / 2);
  }
}

:global .smv-thumbnails.smv-v .smv-item.smv-active smv-thumbnail {
  @if $manufactum {
    border-right: 2px solid var(--main-75) !important;
  }
  @if $magazin {
    border-right: 2px solid var(--main-100) !important;
  }
  border-bottom: 0;
  border-left: 0;
  border-top: 0;
  padding: 5px !important;
}

:global .smv-thumbnails.smv-h .smv-item.smv-active smv-thumbnail {
  @if $manufactum {
    border-top: 2px solid var(--main-75);
  }
  @if $magazin {
    border-top: 2px solid var(--main-100);
  }
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  padding: 5px;
}

@media (--XXL_and_up) {
  /* fix thumbnail flickering */
  :global .smv-selectors-box.smv-thumbnails.smv-v {
    min-width: 107.4px;
  }
}

:global .Sirv .smv-slides-box .smv-slides .smv-slide .smv-selection-area {
  height: calc(100% - var(--focus-width) * 4);
  width: calc(100% - var(--focus-width) * 4);
}

:global
  .Sirv
  .smv-slides-box
  .smv-slides
  .smv-slide:focus-visible
  .smv-selection-area {
  border-color: var(--focus-color);
  outline: var(--focus-width) solid var(--focus-color-inverted);
  padding: var(--focus-width);
}

:global .smv-item:focus-within {
  opacity: 1;
}
