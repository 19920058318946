.paymentIcon img {
  @if $manufactum {
    background-color: var(--white-100);
    height: 2.8rem;
    padding: 0 0.7rem;
    vertical-align: bottom;
    width: 5rem;
  }

  @if $magazin {
    height: 1.9rem;
    padding: 0.4rem 0.2rem;
  }

  margin: 0 0.3rem 0 0;
}

@if $magazin {
  .paymentIcon img:nth-child(2) {
    padding: 0;
  }
}
