@if $manufactum {
  .root {
    display: block;
    font-size: 1.5rem;
    font-weight: normal;
    text-decoration: none;
  }

  .root:hover .name {
    text-decoration: underline;
  }
}

/* COMPACT */
.compact.root {
  &:hover {
    color: var(--main-75);
    text-decoration: underline;
  }

  &:active {
    color: var(--main-75);
  }
}

.compact.root {
  border-bottom: 1px solid var(--color-divider-prio2);
  color: var(--black-100);
  display: block;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.47;
  padding: var(--space-s) 0;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.compact.root:focus-visible {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}

.compact .arrow,
.compact .name {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.compact .name {
  padding-right: var(--space-m);
}

.compact .arrow {
  margin-right: var(--space-xs);
  margin-top: var(--space-xs);
}

.compact.open {
  color: var(--main-75);
}

.compact.open .name {
  text-decoration: none;
}

/* FLYOUT */
@if $manufactum {
  .flyout.root,
  .compact.root {
    &:hover {
      color: var(--main-75);
      text-decoration: underline;
    }

    &:active {
      color: var(--main-75);
    }
  }

  .flyout.root {
    break-inside: avoid-column;
    color: var(--grey-200);
    line-height: 1;
    overflow: hidden;
    padding: var(--space-xs) 0;
    text-decoration: none;
    text-overflow: ellipsis;
  }

  .flyout .name {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }

  .flyout .arrow {
    display: none;
  }

  .flyout.active {
    color: var(--main-75);
  }

  .flyout.active .name {
    text-decoration: underline;
  }
}
