.root {
  @media (--up_to_XL) {
    flex-direction: column;
  }
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem 0 0;
  text-align: center;
}

.icons {
  @if $manufactum {
    @media (--XL_and_up) {
      width: 50%;
    }
  }
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.icon {
  & a {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0.8rem;
  }

  & svg {
    @if $magazin {
      color: var(--main-100);
    }

    @if $manufactum {
      color: var(--color-copy-normal-inverted);
    }
  }
}

.icon:first-child {
  margin-left: -0.8rem;
}

.label {
  @media (--XL_and_up) {
    width: 50%;
  }
  display: inline-block;
  height: 2.8rem;
  margin-right: var(--space-l);
  text-align: right;
}

/* Stelar Text Overwrite */
.label.label {
  @if $manufactum {
    color: var(--color-copy-normal-inverted);
  }
}
