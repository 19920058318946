/* square wrap-element to force the browser to reserve space before the image is loaded */
.wrap {
  color: transparent;
  display: block;
  margin-bottom: var(--space-s);
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  width: 100%;
}

.image,
.imagePreview,
.imageSkeleton {
  bottom: 0;
  display: block;
  left: 0;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.videoPlayButton {
  background: rgba(0, 0, 0, 0.1)
    "url(https://media.manufactum.de/s7sdk/3.9/images/sdk/videoplayicon.png)"
    no-repeat scroll center center / 50px 50px;
  bottom: 0;
  display: block;
  height: 100px;
  left: 0;
  max-width: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  width: 192px;
  z-index: 1;
}

.imageSkeleton {
  background: radial-gradient(ellipse at center, #eeeeee 35%, #ffffff 37%);
}

.imagePreview {
  filter: blur(8px);
}

.image {
  color: transparent !important; /* hide alt text */
}
