.button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: left;
}

.button:focus-visible {
  border-color: transparent;
  border-radius: var(--focus-border-radius);
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: var(--focus-offset);
  text-decoration: none;
}

.button.inverted:focus-visible {
  color: var(--focus-text-color-inverted);
  outline: var(--focus-width) solid var(--focus-color-inverted);
}

.button.disabled {
  cursor: not-allowed;
  filter: grayscale(100%);
  opacity: 0.5;
}

.buttonContent {
  align-items: center;
  display: flex;
}

.icon.left {
  margin: 0.2rem var(--space-xs) 0 0;
}

.icon.right {
  margin: 0.2rem 0 0 var(--space-xs);
}
