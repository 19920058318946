.root {
  line-height: 1.5;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.button {
  margin: var(--space-l) 0;
}

.form {
  display: flex;
  justify-content: space-between;
}
