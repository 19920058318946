.root {
  display: flex;
}

.root a {
  text-decoration: none;
}

.colImage {
  @media (--up_to_S) {
    width: 100px;
  }
  @media (--S_and_up) {
    width: 120px;
  }
  margin-right: var(--grid-gutter);
  min-width: 100px;
}
