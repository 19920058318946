.root {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.slide {
  padding: 0 calc(var(--focus-width) + var(--focus-offset));
}

.full .slide {
  @media (--XXL_and_up) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2),
    &:nth-child(0n + 3),
    &:nth-child(0n + 4),
    &:nth-child(0n + 5),
    &:nth-child(0n + 6) {
      display: list-item;
      flex: 0 0 var(--grid-2-12);
    }
  }

  @media (--XL_to_XXL) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2),
    &:nth-child(0n + 3),
    &:nth-child(0n + 4),
    &:nth-child(0n + 5) {
      display: list-item;
      flex: 0 0 41.666%;
    }
  }

  @media (--L_to_XL) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2),
    &:nth-child(0n + 3),
    &:nth-child(0n + 4) {
      display: list-item;
      flex: 0 0 var(--grid-3-12);
    }
  }

  @media (--M_to_L) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2),
    &:nth-child(0n + 3) {
      display: list-item;
      flex: 0 0 var(--grid-4-12);
    }
  }

  @media (--up_to_M) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2) {
      display: list-item;
      flex: 0 0 var(--grid-6-12);
    }
  }

  display: none;
}

.half .slide {
  @media (--XL_and_up) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2),
    &:nth-child(0n + 3) {
      display: list-item;
      flex: 0 0 33.333%;
    }
  }

  @media (--L_to_XL) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2),
    &:nth-child(0n + 3),
    &:nth-child(0n + 4) {
      display: list-item;
      flex: 0 0 25%;
    }
  }

  @media (--M_to_L) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2),
    &:nth-child(0n + 3) {
      display: list-item;
      flex: 0 0 33%;
    }
  }

  @media (--up_to_M) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2) {
      display: list-item;
      flex: 0 0 50%;
    }
  }

  display: none;
  flex: 1;
}

.four .slide {
  @media (--XL_and_up) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2),
    &:nth-child(0n + 3),
    &:nth-child(0n + 4) {
      display: list-item;
      flex: 0 0 25%;
    }
  }

  @media (--L_to_XL) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2) {
      display: list-item;
      flex: 0 0 50%;
    }
  }

  @media (--M_to_L) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2),
    &:nth-child(0n + 3) {
      display: list-item;
      flex: 0 0 33.333%;
    }
  }

  @media (--up_to_M) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2) {
      display: list-item;
      flex: 0 0 50%;
    }
  }

  display: none;
  flex: 1;
}

.overlay .slide {
  @media (--M_and_up) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2),
    &:nth-child(0n + 3),
    &:nth-child(0n + 4) {
      display: list-item;
      flex: 0 0 25%;
    }
  }

  @media (--up_to_M) {
    &:nth-child(0n + 1),
    &:nth-child(0n + 2) {
      display: list-item;
      flex: 0 0 50%;
    }
  }

  display: none;
  flex: 1;
}
