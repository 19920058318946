.root {
  display: flex;
  flex-direction: column;
  min-width: 120px;
  padding: 0 var(--focus-width);
  width: 100%;
}

.name {
  color: var(--black-100);
}

.name:hover {
  color: var(--black-100);
  text-decoration: underline var(--black-100);
}

:global(a):hover .name,
:global(a):active .name {
  color: var(--black-100);
  text-decoration: underline var(--black-100);
}

.dimension {
  display: table-row;
}

.key {
  display: table-cell;
  padding-right: var(--space-m);
  vertical-align: baseline;
}

.val {
  color: var(--black-100);
  display: table-cell;
  vertical-align: baseline;
  word-break: break-all;
}

.manufacturer {
  text-transform: uppercase;
}

.eelContainer {
  margin-top: 6px;
}

.textileLabeling {
  margin-bottom: var(--space-l);
}
