.root {
  background-color: var(--grey-35);
  margin-left: var(--space-s);
  padding: var(--space-l);
  position: absolute;
  top: var(--space-s);
  z-index: 10;
}

a.root:focus-visible {
  border-radius: var(--focus-border-radius);
  box-shadow: 0 0 0 calc(var(--focus-width)) var(--focus-color-inverted);
  outline-offset: calc(-1 * var(--focus-width));
}

.root:not(:active):not(:focus) {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 1px;
}
