/* Stelar Text Overwrite */
.text.text.text {
  @if $manufactum {
    & a,
    & a:link,
    & a:visited {
      color: var(--color-copy-normal-inverted);
    }
  }

  button {
    @if $manufactum {
      color: var(--color-copy-normal-inverted);
    }
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: inherit;
    padding: inherit;
  }
  button:hover {
    text-decoration: underline;
  }
}

:global(.render-app) .text.text.text {
  @if $manufactum {
    & a,
    & a:link,
    & a:visited {
      color: inherit;
    }
  }

  button {
    background-color: transparent;
    border: none;
    color: var(--color-copy-normal-inverted);
    cursor: pointer;
    font-size: inherit;
    padding: inherit;
  }
  button:hover {
    text-decoration: underline;
  }
}
