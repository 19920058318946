.root {
  @media (--up_to_L) {
    padding: 0.3rem;
  }

  @media (--L_and_up) {
    padding: 0;
  }

  background: none;
  border: none;
  cursor: pointer;
  display: block;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.root:focus-visible,
.root:hover:focus-visible {
  outline: 0;
}

.text {
  display: none;
}

.active.strongText .text {
  @media (--XL_and_up) {
    color: var(--color-copy-em);
    display: block;
    font-size: var(--font-size-copy-em);
    line-height: var(--line-height-copy-em);
    margin-top: var(--space-xs);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  font-weight: var(--font-weight-copy-bold);
}

.icon {
  @media (--up_to_XL) {
    height: 4.4rem;
    padding: 0.5rem 0;
    width: 4.4rem;
  }

  @media (--L_and_up) {
    height: 4.8rem;
    padding: 0.7rem 0;
    width: 4.8rem;
  }

  @media (--XL_and_up) {
    height: 4.8rem;
    min-width: 4.8rem;
    padding: 0.7rem 0;
    width: 100%;
  }

  background-color: var(--white-100);
  border-radius: 0.5rem;
  color: var(--main-100);
  display: block; /* eliminiert Unterlängen, die bei display: flex/align-items: center stören. */
  margin: 0 auto;
}

.root:hover .icon {
  background-color: var(--grey-35);
}

.root:active .icon {
  background-color: var(--main-100);
  color: var(--white-100);
}

.root.active .icon {
  background-color: var(--main-100);
  color: var(--white-100);
}

.root.active:hover .icon {
  background-color: var(--main-75);
}

.root.active:active .icon {
  background-color: var(--grey-75);
}

.root:focus-visible .icon,
.root.active:focus-visible .icon {
  background-color: var(--focus-background);
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
}

.root.active:focus-visible .icon {
  stroke: var(--focus-color);
  stroke-width: 1px;
}

.icon path,
.icon polygon,
.icon ellipse {
  fill: currentColor; /* entspricht color: inherit */
}
