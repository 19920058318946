.topbannerRoot {
  transition: top 0.5s ease;
  width: 100%;
  z-index: 98;
}

.topbannerContainer {
  position: relative;
}

.topbannerRoot.open {
  @if $manufactum {
    background-color: #005232;
  }

  @if $magazin {
    background-color: #009fe2;
  }
}

.topbannerRoot.open.default {
  background-color: var(--grey-75);
}

.topbannerRoot.close {
  top: -40vw;
}

.topbannerRoot.default,
.topbannerRoot.default .tbButton {
  color: var(--black-100);
}

.topbannerRoot.attention,
.topbannerRoot.attention .tbButton {
  color: var(--white-100);
}

.tbButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: var(--space-xs);
  position: absolute;
  right: 0;
  top: 0;
}

.tbListContainer {
  display: flex;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.2rem;
  margin: 0 2.6rem;
}

.tbListContainer > a,
.tbListContainer > p {
  @media (--up_to_L) {
    overflow: hidden;
    padding: 0.8rem 2rem;
  }

  @media (--L_and_up) {
    padding: 0.5rem;
    width: 33%;
  }

  line-height: 1.5;
}

.tbListItem.default {
  @media (--up_to_L) {
    padding: 0.8rem 0;
  }

  @media (--L_and_up) {
    padding: 0.5rem 1.5rem;
  }

  color: var(--grey-200);
}

.tbListItem.attention {
  @media (--up_to_L) {
    padding: 0.8rem 0;
  }

  @media (--L_and_up) {
    padding: 0.5rem 1.5rem;
  }

  color: var(--white-100);
}

.tbListItemLink.default {
  @media (--up_to_L) {
    padding: 0.8rem 4rem;
  }
  @media (--L_and_up) {
    padding: 0.4rem 4rem;
  }

  color: var(--black-100);
  text-decoration: none;
  width: 100% !important;
}

.tbListItemLink.attention {
  @media (--up_to_L) {
    padding: 0.8rem 2rem;
  }
  @media (--L_and_up) {
    padding: 0.4rem 4rem;
  }

  color: white;
  text-decoration: none;
  width: 100% !important;
}

.tbButton.tbButton:focus-visible,
.tbListItemLink:focus-visible {
  background-color: var(--focus-background);
  border-radius: 0;
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}

.element0 {
  @media (--up_to_L) {
    text-align: left;
  }
  @media (--L_and_up) {
    text-align: left;
  }
}

.elementOnly {
  text-align: center;
  width: 100% !important;
}

.element1 {
  @media (--up_to_L) {
    text-align: left;
  }
  @media (--L_and_up) {
    text-align: center;
  }
}

.element2 {
  @media (--up_to_L) {
    text-align: left;
  }
  @media (--L_and_up) {
    text-align: right;
  }
}
