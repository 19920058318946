.awarenessRow {
  display: none;
}

.heading {
  text-align: center;
}

.subHeading {
  margin-top: 3.5rem;
  text-align: center;
}

.catalogContainer {
  @media (--up_to_S) {
    margin: 0;
  }

  @media (--M_and_up) {
    margin: 0 10% 10% 10%;
  }
}

.additionalCatalogs {
  @media (--up_to_S) {
    margin: 0;
    width: 100%;
  }

  @media (--M_and_up) {
    width: 100%;
  }
}
