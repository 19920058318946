@media (--up_to_L) {
  .root {
    width: calc(100vw - 70px);
  }
}

@media (--L_and_up) {
  .root {
    width: 680px;
  }
}

.bill {
  @media (--up_to_M) {
    overflow: auto;
  }

  @media (--M_and_up) {
    display: flex;
    justify-content: flex-end;
  }

  border-top: thin solid silver;
  padding-top: var(--space-m);
}

.products {
  list-style: none;
  margin: 0;
  padding: 0;
}

.product {
  border-top: thin solid var(--grey-75);
  margin: 0 0 var(--space-m) 0;
  padding: var(--space-m) 0 0 0;
}
