.root {
  overflow-x: hidden;
}

.additionalVariations {
  font-size: 1.3rem;
}

.manufacturer {
  @if $magazin {
    min-height: var(--line-height-copy-light);
  }

  @media (--up_to_M) {
    white-space: nowrap;
  }

  color: var(--color-copy-small);
  overflow: hidden;
  text-overflow: ellipsis;
}

.normal {
  @media (--up_to_M) {
    &::after {
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 60%
      );
      bottom: 0;
      content: "";
      height: var(--line-height-copy-light);
      position: absolute;
      right: 0;
      text-align: right;
      top: calc(var(--line-height-copy-light) * 3.5);
      width: 50px;
    }

    display: block;
    max-height: calc(var(--line-height-copy-light) * 5);
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }

  @media (--M_and_up) {
    &::after {
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 60%
      );
      bottom: 0;
      content: "";
      height: var(--line-height-copy-normal);
      position: absolute;
      right: 0;
      text-align: right;
      top: var(--line-height-copy-normal);
      width: 50px;
    }

    display: block;
    max-height: calc(var(--line-height-copy-normal) * 2);
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }
}

.recommendation {
  &::after {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 60%
    );
    bottom: 0;
    content: "";
    height: var(--line-height-copy-normal);
    position: absolute;
    right: 0;
    text-align: right;
    top: var(--line-height-copy-normal);
    width: 50px;
  }

  display: block;
  max-height: calc(var(--line-height-copy-normal) * 2);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.skeleton .name::after {
  display: none;
}
