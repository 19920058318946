.root {
  @media (--XL_and_up) {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  padding: var(--space-xl) calc(var(--grid-gutter) + var(--grid-outer-space))
    var(--space-s) calc(var(--grid-gutter) + var(--grid-outer-space));
  text-align: center;
}

.root a {
  display: inherit;
}

.appStoreImages {
  @media (--up_to_XL) {
    justify-content: center;
  }

  @media (--XL_and_up) {
    justify-content: flex-start;
    width: 50%;
  }

  @media (max-width: 349px) {
    flex-direction: column;
  }

  align-items: center;
  display: flex;
}

.appStoreImages a:nth-child(1) {
  @media (max-width: 349px) {
    margin-bottom: var(--space-l);
  }
  @media (min-width: 350px) {
    margin-right: var(--space-l);
  }
}

.label {
  @media (--up_to_XL) {
    margin-bottom: var(--space-m);
  }

  @media (--XL_and_up) {
    margin-right: var(--space-l);
    text-align: right;
    width: 50%;
  }

  display: block;
}

/* Stelar Text Overwrite */
.label.label {
  @if $manufactum {
    color: var(--color-copy-normal-inverted);
  }
}
