:global(.mf-noscript) .scriptButtons {
  display: none;
}

:global(.mf-script) .noScriptButtons {
  /* display: none; */
}

.buttonStyle {
  @media (--up_to_S) {
    margin: 0;
    width: 100%;
  }

  @media (--M_and_up) {
    float: right;
    margin-left: 75px;
    width: 360px;
  }
}

.hintContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto 3rem;
}
