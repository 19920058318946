.root {
  max-width: 100%;
  width: 680px;
}

.product {
  align-items: center;
  display: flex;
  margin-bottom: var(--space-s);
}

.image {
  margin-right: var(--grid-gutter);
  min-width: calc(33% - var(--grid-gutter));
}

.text {
  margin-left: var(--grid-gutter);
}

.legend {
  @media (--M_and_up) {
    align-items: center;
    display: flex;
  }

  list-style: none;
  margin: 0 0 var(--space-l);
  padding: 0;
}

.legendItem {
  align-items: center;
  display: flex;
  margin-bottom: var(--space-s);
  margin-right: var(--space-m);
}

.icon {
  margin-right: var(--space-s);
}

.icon.available {
  color: var(--success-100);
  transform: translateX(-0.3rem);
}

.icon.scarce {
  color: var(--secondary-50);
  transform: translateX(-0.3rem);
}

.icon.notAvailable {
  color: var(--error-100);
  margin-right: var(--space-l);
}

.availabilities {
  width: 100%;
}

.availabilities .row {
  border-top: 1px solid var(--color-divider-prio2);
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  padding: var(--space-xl) 0 var(--space-m);
  vertical-align: top;
}

.warehouseName {
  flex: 3;
}

.status {
  @media (--up_to_M) {
    display: flex;
    justify-content: flex-end;
  }

  flex: 1;
}

.address {
  @media (--up_to_M) {
    flex-basis: 100%;
  }

  @media (--M_and_up) {
    flex: 4;
  }
}

.heading {
  font-weight: bold;
}

.collectheading {
  padding-top: 22px;
}

.openingtime {
  padding-top: 10px;
}
