.modal > * {
  @media (--L_and_up) {
    width: 550px;
  }
}

.accordion {
  margin-bottom: var(--space-m);
}

.consentButton {
  margin-bottom: var(--space-m);
}

.buttonGroup {
  display: flex;
  gap: var(--space-m);
}
